import * as React from "react";
import isEmpty from "lodash/isEmpty";
import {SsnFormatter} from "@folksam-digital/services";
import {FormattedMessage} from "react-intl";
import {withFormContext} from "../../withFormContext";
import {ButtonResponsive, Spacing} from "@folksam-digital/ui";
import {FormFieldLayout} from "../../../../FormFieldLayout";
import PersonSsnInput from "../../input/PersonSsnInput";
import {ContainerBase, IPersonSsnInputMetadata} from "./ContainerBase";
import {IInputComponentProps} from "../../input/InputComponentBase";
import {RemoveButton} from "./RemoveButton";
import {FetchedContact} from "./FetchedContact";
import get from "lodash/get";
import castArray from "lodash/castArray";
import {getBreakpoints} from "../../input/helpers/breakpoints/getBreakpoints";
import {defaultBreakpoints} from "../../input/helpers/breakpoints/defaultBreakpoints";
import {schemaTitleStyle} from "../../../../common/styles/componentStyles";

class PersonSsnInputSingleContainer extends ContainerBase {

    constructor(props: IInputComponentProps<any, IPersonSsnInputMetadata>) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onFindPersonClick = this.onFindPersonClick.bind(this);
        this.onLoadContactData = this.onLoadContactData.bind(this);
        this.onRemove = this.onRemove.bind(this);

        this.state = {
            ssnInputValue: "",
            error: "",
            isLoading: false
        };
    }

    public componentDidMount(): void {
        if (this.metadata.clearWhenSsnInUse) {
            const pathSsn = get(this.props.formContext?.data, this.metadata.clearWhenSsnInUse);
            if (pathSsn && castArray(pathSsn).some(identifer => identifer === this.props.formData.ssn)) {
                this.onRemove()
                return;
            }
        }
        if (this.props.formData) {
            this.setState({
                ssnInputValue: this.props.formData.ssn,
            })
        }
    }

    public render() {
        const {name, required} = this.props;
        const schemaTitle =
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <FormattedMessage id={this.schema?.title} />
                {!this.metadata.disableTitleSuffix && (
                    <div style={{...schemaTitleStyle}}>
                        <FormattedMessage id={"general.form.personSsnNumber.placeholder"} />
                    </div>
                )}
            </div>

        return (
            <>
                <FormFieldLayout
                    id={name}
                    title={this.props?.formData?.ssn && this.metadata.fetchedPersonLabel ? this.metadata.fetchedPersonLabel : schemaTitle}
                    required={required}
                    error={this.getFieldError(this.state.error) || this.getError()}
                    help={this.metadata.help}
                    breakpoints={getBreakpoints(defaultBreakpoints.ssnInput)}
                    invalid={this.isInvalid() || !!this.getFieldError(this.state.error)}
                >
                    {!this.props?.formData?.ssn
                        ?
                        <PersonSsnInput
                            {...this.props}
                            value={this.state.ssnInputValue}
                            error={this.state.error || this.getError()}
                            onInputChange={this.onInputChange}
                        />
                        :
                        this.renderFetchedPersonSection()
                    }
                </FormFieldLayout>
                {!this.props?.formData?.ssn && this.renderFindPersonButton()}
            </>
        );
    }

    private renderFetchedPersonSection(): React.ReactElement {
        const contact = this.props.formData;

        return (
            <FetchedContact
                key="fetchedPersonKey"
                contact={{
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    companyName: contact.companyName,
                    ssn: SsnFormatter.formatWithDash(contact.ssn) || ""
                }}
                removeButton={this.renderRemoveButton()}
            />
        );
    }

    private renderFindPersonButton(): JSX.Element {
        const buttonTitle = this.metadata?.buttonTitle || "general.personSsnInput.findPerson";
        return (
            <Spacing type={"margin"} top={"md"}>
                <ButtonResponsive
                    id={`${this.props.name}Submit`}
                    type="button"
                    onClick={this.onFindPersonClick}
                    disabled={this.state.isLoading}
                    isBusy={this.state.isLoading}
                    full
                    outline
                >
                    <FormattedMessage id={buttonTitle}/>
                </ButtonResponsive>
            </Spacing>
        );
    };

    private renderRemoveButton(): JSX.Element {
        return (
            <RemoveButton
                onClick={this.onRemove}
                uniqueKey={"removeFetchedPerson"}
                editIcon
            />
        );
    }

    private async onInputChange(ssnInputValue: string): Promise<void> {
        await this.setState({ssnInputValue});

        if (!isEmpty(this.state.error)) {
            const error = this.validateField();
            this.setState({error});
        }
    }

    private onRemove(): void {
        this.setState(
            {ssnInputValue: ""},
            () => this.onChangeWithValidation({})
        );
    }

    private async onFindPersonClick(event: any): Promise<void> {
        const error = this.validateField();
        if (!isEmpty(error)) {
            this.setState({error});
            return;
        }

        this.setState({isLoading: true});

        const contact = await this.onLoadContactData();

        if (contact && contact.ssn) {
            this.setState({
                error: "",
                isLoading: false
            });

            this.onChangeWithValidation(contact);
        } else {
            this.setState({
                error: "general.personSsnInput.error.notFound",
                isLoading: false
            });
        }
    }

    protected getFieldError(error: string | undefined): string | undefined {
        if (!isEmpty(error)) {
            return error;
        }

        if (isEmpty(this.props.rawErrors)) {
            return undefined;
        }

        return this.props.rawErrors[0];
    }
}

export default withFormContext<IInputComponentProps<any, IPersonSsnInputMetadata>>(PersonSsnInputSingleContainer);

import * as React from "react";
import {FormattedMessage} from "react-intl";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import merge from "lodash/merge";
import scrollIntoView from "scroll-into-view-if-needed";
import {ILayoutComponentProps, LayoutComponentBase} from "./LayoutComponentBase";
import {BannerIcon, BannerIconNew, BannerSkeleton, PageHeader, Spacing} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../cms";
import {FormContext, IFormContext} from "../form/FormContext";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../FormattedMarkdown";
import {RouteComponentProps, withRouter} from "react-router";
import {AuthenticationContext, IAuthenticationContext} from "../../../authentication/AuthenticationContext";
import flowRight from "lodash/flowRight";
import withAuthentication from "../../../authentication/withAuthentication";
import {DynamicComponent} from "../../DynamicComponent";
import {logoHref} from "../navigation/common/params";
import {IHeaderLayoutValue} from "@folksam-digital/model";

interface IHeaderLayoutProps extends ILayoutComponentProps {
    themeId?: number;
    bannerImageName: string;
    showLoader?: boolean;
    logOutUrl?: string;
    stepBack?: boolean;
    backUrl?: string;
    returnUrl?: string;
    navButton?: any;
    translations: {
        header: string,
        subheader?: string,
        headerText?: string
    },
    params?: IHeaderLayoutValue
    formData: any,
    doScroll?: boolean,
    centered?: boolean
}

export class HeaderLayoutInternal extends LayoutComponentBase<IHeaderLayoutProps & RouteComponentProps, {}> {
    private readonly containerRef: React.RefObject<any>;

    constructor(props: IHeaderLayoutProps & RouteComponentProps) {
        super(props);
        this.containerRef = React.createRef();
    }

    public componentDidMount(): void {
        if (this.props.doScroll) {
            this.scrollToThisElement();
        }
    }

    public render() {
        const { stepBack, backUrl, logOutUrl, returnUrl, navButton: NavButtonComponent, centered, translations: {headerText} } = this.props;
        const HeaderComponent = centered ? BannerIconNew : BannerIcon;

        return (
            <AuthenticationContext.Consumer>
                {(authenticationContext: IAuthenticationContext) =>
                    <CmsContext.Consumer>
                        {(cmsContext: ICmsContext) => (
                            <FormContext.Consumer>
                                {(context: IFormContext) => {
                                    const imageAttributes = CmsHelper.getImageAttributes(`${this.props.bannerImageName}`, cmsContext.catalog!);
                                    return <div ref={this.containerRef}>
                                        <div style={{position: "relative", zIndex: 1}}>
                                            <PageHeader fullWidth headerText={headerText} logoHref={logoHref}>
                                                {!!NavButtonComponent && <DynamicComponent component={NavButtonComponent} {...{
                                                    isAuthenticated: authenticationContext.isAuthenticated,
                                                    stepBack,
                                                    backUrl,
                                                    logOutUrl,
                                                    returnUrl
                                                }} />}
                                            </PageHeader>
                                        </div>
                                        {
                                            !this.props.showLoader ?
                                                <HeaderComponent
                                                    compact={centered}
                                                    centered={centered}
                                                    theme={this.props.themeId}
                                                    image={{
                                                        style: {maxHeight: "100%"},
                                                        ...imageAttributes
                                                    }}
                                                >
                                                    <Spacing type={"padding"} bottom={"4"}/>
                                                    {this.props.formData && <HeaderComponent.Header>
                                                            <FormattedMessage id={this.props.translations.header}
                                                                              values={this.props.formData.contact}/>
                                                    </HeaderComponent.Header>}
                                                    <Spacing type={"padding"} bottom={"4"}/>
                                                    <HeaderComponent.Subheader>
                                                        {
                                                            this.renderSubHeader(context)
                                                        }
                                                    </HeaderComponent.Subheader>
                                                    { this.props.children && <HeaderComponent.Content>{this.props.children}</HeaderComponent.Content> }
                                                </HeaderComponent>
                                                :
                                                <BannerSkeleton theme={this.props.themeId}/>
                                        }
                                    </div>
                                }}
                            </FormContext.Consumer>
                        )}
                    </CmsContext.Consumer>}
            </AuthenticationContext.Consumer>
        );
    }

    private renderSubHeader(context: IFormContext): React.ReactNode {
        if (context.data.group && context.data.group.name) {
            return <FormattedMessage id={this.props.translations.subheader!}
                                     values={{groupName: context.data.group.name}}/>
        }
        if (this.props.formData.claimant && this.props.formData.claimant?.contact && !this.props.formData.claimant?.isInformant) {
            return <FormattedMessage id={this.props.translations.subheader!}
                                     values={{fullName: `${this.props.formData.claimant.contact.firstName} ${this.props.formData.claimant.contact.lastName}`}}/>
        }
        if (this.props.formData.group && this.props.formData.group.name) {
            return <FormattedMessage id={this.props.translations.subheader!}
                                     values={{groupName: this.props.formData.group.name}}/>
        }
        let values = this.getValues(!isEmpty(context.data) ? context.data : this.props.formData);

        if (!isEmpty(values) || isEmpty(context.data)) {
            values = merge(values, this.getValues(this.props.formData));
        }

        return <FormattedMarkdown
                    messageKey={this.props.translations.subheader!}
                    disallowedTypes={[]}
                    messageValue={values}
                />
    }

    private getValues(data: any) {
        const values = {} as any;
        if (this.props.params) {
            for (const key in this.props.params) {
                if (get(data, this.props.params[key]) !== undefined) {
                    values[key] = get(data, this.props.params[key]);
                }
            }
        }

        return values;
    }

    private scrollToThisElement() {
        scrollIntoView(this.containerRef.current, {block: "start", behavior: "smooth"});
    }
}

const HeaderLayout = flowRight(withRouter, withAuthentication)(HeaderLayoutInternal);
export {HeaderLayout};

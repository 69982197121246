import * as React from "react";
import {FormattedMessage, WrappedComponentProps} from "react-intl";
import {PanelComponentBase} from "./PanelComponentBase";
import {Modal, Grid} from "@folksam-digital/ui";
import {FormContext} from "../FormContext";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IState {
    isModalOpen: boolean;
}

interface IModalPanelInternalProps {

}

interface IModalPanelMetadata {
    modelPath: string;
    addOnId: string;
    prefix?: string;// for translation reuse without prefix
}

export class ModalPanel extends PanelComponentBase<void, IModalPanelMetadata & WrappedComponentProps, IState, IModalPanelInternalProps>{
    public static contextType = FormContext;
    constructor(props: any) {
        super(props);
        this.state = {
            isModalOpen: true
        };
    }

    onCloseModal = () => {
        this.setState({
            isModalOpen: false
        });

        const path = this.metadata.modelPath;
        this.context.onChangeFormData(path, false);
    }

    render() {
        const {addOnId, prefix} = this.metadata;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Modal
                        noOverFlowY={true}
                        showCloseButton={true}
                        shouldCloseOnOverlayClick={true}
                        isOpen={this.state.isModalOpen}
                        onRequestClose={this.onCloseModal}
                    >
                        <Modal.Header
                            showCloseButton={true}
                            onModalClose={this.onCloseModal}>
                            <FormattedMessage
                                id={CmsHelper.withPrefixOrId({cmsContext, id: `${addOnId}.modal.heading`, prefix})}
                            />
                        </Modal.Header>
                        <Modal.Body>
                            <Grid>
                                {this.props.properties?.map((p, index) => {
                                    return (
                                        <Grid.Row key={index}>
                                            <Grid.Col xs={true}>
                                                {p.content}
                                            </Grid.Col>
                                        </Grid.Row>
                                    )
                                })}
                            </Grid>
                        </Modal.Body>
                    </Modal>
                )}
            </CmsContext.Consumer>
        );
    }
}

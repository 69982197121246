import JourneySuccessBase, {IWithLocaleContextSuccessProps} from "./JourneySuccessBase";
import {Constants, HomeBuildingJourney, pathof, PriceBarFrequency, yearMonthDayFormat} from "@folksam-digital/model";
import {CmsContext} from "../../../../cms";
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {FormattedMessage, FormattedNumber} from "react-intl";
import flowRight from "lodash/flowRight";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {withRouter} from "react-router";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {
    B,
    defaultTheme as theme,
    DescriptionList,
    Grid,
    HomeBuildingIcon,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {withLocaleContext} from "../../../../components/journey/form/withLocaleContext";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import SuccessPageHeaderContent from "../../../../components/journey/layout/Success/SuccessPageHeaderContent";
import {getSuccessPageHeaderValues} from "../../../../Helpers/getSuccessPageHeaderValues";
import {ListRowItem, RowAddon, RowDiscounts, RowPrice} from "../common/ListRowItem";
import {BannerWithLink} from "../common/BannerWithLink";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {Template} from "../../../../components/journey/form/output/helpers";
import model from "@folksam-digital/model/lib/model/HomeBuildingJourneyModel";
import {HomeRowScope} from "../common/HomeRowScope";
import {FeatureToggleUtils} from "@folksam-digital/services";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";
import {CoopBannerWithLink} from "../common/CoopBannerWithLink";

class HomeBuildingJourneySuccessInternal extends JourneySuccessBase<HomeBuildingJourney, IWithLocaleContextSuccessProps> {
    public static contextType = CmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.HomeBuilding.Id);
        this.triggerDataLayerEvent(Constants.Journey.HomeBuilding.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }
        data.policy.startDate = new Date(data.policy?.startDate!).toLocaleString(this.props.localeContext.locale, yearMonthDayFormat.reactIntl) as any;
        const {address, city, postalCode, newAddress} = data?.policy?.insuredObject?.address!;

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.HomeBuilding.Id}
                    formData={{
                        contact: data.contact
                    }}
                    backUrl={Constants.Journey.HomeBuilding.backUrl}
                    navButton={CloseLink}
                    translations={getSuccessPageHeaderValues<HomeBuildingJourney>(data, this.context)}
                    doScroll={true}
                >
                    <SuccessPageHeaderContent
                        formData={data}
                        handleDirectDebitRedirect={this.handleDirectDebitRedirect}
                        journeyId={Constants.Journey.HomeBuilding.Id}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            {FeatureToggleUtils.isFeatureEnabled("coop") && data?.policy?.partner === Constants.Partners.Coop &&
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <CoopBannerWithLink />
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>}
                            {data?.policy?.partner !== Constants.Partners.Coop &&
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <BannerWithLink
                                                titleId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.title")}
                                                textId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.text")}
                                                buttonTextId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.buttonText")}
                                                linkId={CmsHelper.withPrefix(this.context, "success.bannerWithLink.link")}
                                            />
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>}
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <SummaryCard theme={this.summaryCardThemeID}>
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<HomeBuildingIcon
                                                        style={{marginRight: '10px'}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id="product.homeBuilding.reviewAndBuy.summaryTable.header"/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList breakpoint={12}>
                                                        <ListRowItem
                                                        id="general.review.insuranceAppliesTo.title">
                                                        {newAddress
                                                            ? <B> {address}, {city ? city + ",": ""} {postalCode} </B>
                                                            : <B> {address} </B>
                                                        }
                                                        </ListRowItem>
                                                        <HomeRowScope
                                                            prefix={CmsHelper.getPrefix(this.context)}
                                                            data={data}
                                                            id={data?.targetEnv === Constants.TargetEnvironment.Legacy ? "product.homeBuilding.reviewAndBuy.scope.title" : undefined}
                                                        />
                                                        {data?.targetEnv === Constants.TargetEnvironment.Idit && data?.policy?.insuredObject?.wantToBuyHomeInsurance === true &&
                                                            <ListRowItem
                                                                id="product.homeBuilding.reviewAndBuy.scopeAddition.title">
                                                                    {<Template
                                                                        template={`{{#translateValue}} product.homeBuilding.coverPlans.{{{${pathof(model.policy.coverPlanAddition)}}}}.title {{/translateValue}}`}
                                                                        data={data}
                                                                    />}
                                                                    <DescriptionList.Price
                                                                        component={<Template
                                                                            template={`{{#extractCoverPlanAddition}} {{${pathof(model.policy.coverPlanAddition)}}} : {{${PriceBarFrequency.Monthly}}} {{/extractCoverPlanAddition}} {{#translate}} {{general.monthlyPremium.suffix}} {{/translate}}`}
                                                                            data={data}
                                                                            style={{whiteSpace: "nowrap"}}/>}
                                                                    />
                                                            </ListRowItem>

                                                        }
                                                        {data?.targetEnv === Constants.TargetEnvironment.Legacy &&
                                                            <ListRowItem
                                                                id="product.homeBuilding.success.deductibles.title">
                                                                <B>
                                                                    {data.policy.insuredObject?.deductible?.label}
                                                                </B>
                                                            </ListRowItem>}
                                                        {data?.targetEnv === Constants.TargetEnvironment.Idit &&
                                                            <ListRowItem
                                                                id={`product.homeBuilding.success.${Constants.TargetEnvironment.Idit}.deductibles.title`}>
                                                                <B>
                                                                    <FormattedNumber value={Number(data.policy.insuredObject?.deductible?.label)} />
                                                                    &nbsp;<FormattedMessage id="general.price.suffix" />
                                                                </B>
                                                            </ListRowItem>}
                                                        <RowAddon
                                                            prefix={""}
                                                            data={data}
                                                        />
                                                        {
                                                            !isEmpty(data?.policy?.premium?.discounts) &&
                                                            <RowDiscounts premium={data.policy.premium!}
                                                                          tooltipId="product.homeBuilding.reviewAndBuy.discountedDiscounts.tooltip"
                                                            />
                                                        }
                                                        <RowPrice premium={data.policy.premium!}/>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const HomeBuildingJourneySuccess = flowRight(
    withCmsProvider("homeBuilding"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint,
    withLocaleContext
)(HomeBuildingJourneySuccessInternal);

export default HomeBuildingJourneySuccess;

import isNil from "lodash/isNil";
import {IInitParams} from "../../services";
import {ConfigHelper} from "@folksam-digital/services";

export class AnalyticsHelper {
    public static shouldLoadScriptForProduct(journeyId?: string, params?: any) {
        if (params.commonGroupId) {
            return !AnalyticsHelper.isGroupExcluded(params);
        }

        return true;
    }

    public static isGroupExcluded(params: Pick<IInitParams, "commonGroupId">) {
        if (!isNil(params.commonGroupId)) {
            const excludedUnionsFromAnalytics = ConfigHelper.getValue("ANALYTICS_EXCLUDED_GROUP_UNIONS").split(',');
            return excludedUnionsFromAnalytics.indexOf(params.commonGroupId) !== -1;
        }

        return true;
    }

    public static getAdobeOfferVariant(journeyId: string): Promise<any> | undefined {
        const mbox = this.getAdobeAnalyticsTargetOffers()
            .find(targetOffer => targetOffer.startsWith(`${journeyId}_`))?.split("_")[1];

        if (!!window.adobe?.target?.VERSION && window.adobe?.target?.getOffer && mbox) {
            return new Promise((resolve, reject) => {
                // @ts-ignore-next-line
                window.adobe.target.getOffer({
                    mbox: mbox,
                    success: (response) => {
                        if (response && response[0]?.content && response[0].content[0]?.variant) {
                            resolve(response[0].content[0].variant);
                        } else {
                            resolve(response);
                        }
                    },
                    error: (status, error) => {
                        reject(`${status}, ${error}`);
                    }
                });
            });
        }

        return undefined;
    }

    public static getAdobeAnalyticsTargetOffers(): string[] {
        return ConfigHelper.getValue("ADOBE_TARGET_OFFERS").split(",");
    }

    public static isAdobeScriptEnabledForJourney(journeyId: string, testVariant?: string): boolean {
        const journeyIdsWithAdobeScriptEnabled = this.getAdobeAnalyticsTargetOffers().map(journeyOffer => {
            return journeyOffer.split('_')[0];
        });

        // Allows to enable feature for specific flow during A/B tests
        if (!!testVariant) {
            return this.getAdobeAnalyticsTargetOffers().some(
                journeyIdWithScriptEnabled => journeyIdWithScriptEnabled.split('_')[0] === journeyId && journeyIdWithScriptEnabled.trim().endsWith(testVariant)
            )
        }

        return journeyIdsWithAdobeScriptEnabled.some(journeyIdWithScriptEnabled => journeyIdWithScriptEnabled === journeyId);
    }
}

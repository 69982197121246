import {useHistory} from "react-router";
import {useCallback} from "react";
import {logoHref} from "../common/params";
import {clearSessionStorage} from "../common/clearSessionStorage";

export interface UseGoBackParams {
    backUrl: string;
    stepBack?: any;
    forceClearSessionStorage?: boolean;
}

const useGoBack = ({ backUrl, stepBack, forceClearSessionStorage }: UseGoBackParams) => {

    const history = useHistory();
    const goBackFn = useCallback((e: any) => {
        e?.preventDefault();
        if (forceClearSessionStorage) {
            clearSessionStorage();
        }
        if (stepBack) {
            history.go(-1);
        } else if (backUrl) {
            window.location.href = backUrl
        } else {
            window.location.href = logoHref;
        }
    }, [history, stepBack, backUrl, forceClearSessionStorage]);

    return goBackFn;

};

export default useGoBack;

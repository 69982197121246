import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import castArray from "lodash/castArray";
import {IUserService} from "../../../../../services";
import {container} from "../../../../../inversify.config";
import {Types} from "../../../../../Types";
import {SsnFormatter} from "@folksam-digital/services";
import {Contact, InputType} from "@folksam-digital/model";
import {IInputComponentProps, InputComponentBase} from "../../input/InputComponentBase";
import {validateComplexSsn} from "../../../../../Helpers/validation/isValidSsn";
import {validateComplexSsnOrOrgNumber} from "../../../../../Helpers/validation/isValidOrgNumberOrSsn";
import {IBreakPoint} from "../../../layout/helpers";

export interface IPersonSsnInputMetadata {
    fetchedPersonLabel?: string;
    dataPath?: string | string[];
    clearWhenSsnInUse?: string;
    placeholder?: string;
    type?: InputType;
    help?: string;
    limit?: number;
    limitPath?: string;
    ssnInUseError?: string;
    buttonTitle?: string;
    includeOrgNumber?: boolean;
    breakpoints?: IBreakPoint;
    disableTitleSuffix?: boolean;
}

export interface IPersonSsnInputContainerState {
    ssnInputValue?: string;
    error?: string;
    isLoading?: boolean;
}

export abstract class ContainerBase extends InputComponentBase<any, IPersonSsnInputMetadata, IPersonSsnInputContainerState> {
    protected userService: IUserService;

    protected constructor(props: IInputComponentProps<any, IPersonSsnInputMetadata>) {
        super(props);

        this.userService = container.get<IUserService>(Types.UserService);
    }

    protected validateField(): string | undefined {
        const {dataPath, ssnInUseError, includeOrgNumber} = this.metadata;
        const ssnInputValue = this.state.ssnInputValue;
        let ssnCompareAgainst;
        // Check weather ssn number from input field is not the same as loaded dataPath ssn number
        if (this.props.formContext && ssnInputValue && dataPath) {
            const ssnOrContacts = castArray(dataPath).filter(path => !!path).map(path => get(this.props.formContext?.data, path)).flat();

            ssnCompareAgainst = ssnOrContacts.filter(contactData => !!contactData).map((contactData: any) => contactData.ssn ? contactData.ssn : contactData);
        }
        return includeOrgNumber ? validateComplexSsnOrOrgNumber(ssnInputValue, ssnCompareAgainst, ssnInUseError) : validateComplexSsn(ssnInputValue, ssnCompareAgainst, ssnInUseError, undefined, this.props.required);
    }

    protected async onLoadContactData(): Promise<Contact | undefined> {
        try {
            const ssnInputValue = this.state.ssnInputValue;
            let contact;
            if (ssnInputValue) {
                let ssn = SsnFormatter.formatWithoutDash(ssnInputValue);
                ssn = SsnFormatter.convertFormat(ssn, true);

                contact = await this.getContactBySsn(ssn);
            }

            return contact;
        } catch (error) {
            // error is not diverted to errorPage to allow message general.personSsnInput.error.notFound display.
        }
    }

    protected getFieldError(error: string | undefined): string | undefined {
        if (!isEmpty(error)) {
            return error;
        }

        if (isEmpty(this.props.rawErrors)) {
            return undefined;
        }

        return this.props.rawErrors[0];
    }

    private async getContactBySsn(ssn: string): Promise<Contact | undefined> {
        return this.userService.getContactBySSN(ssn);
    }
}

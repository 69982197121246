import * as React from "react";
import {SuccessHeaderMessageBox} from "./SuccessHeaderMessageBox";
import {MyPagesButton} from "./MyPagesButton";
import {WorkshopButton} from "./WorkshopButton";
import {SuccessHeaderTable} from "./SuccessHeaderTable";
import {Spacing} from "@folksam-digital/ui";
import {CmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface ISuccessHeaderMotorContentElementsProps {
    claimNumber?: string;
    emailAddress?: string;
    vehicleBrand?: string;
}

export class SuccessHeaderMotorContentElements extends React.Component<ISuccessHeaderMotorContentElementsProps> {
    public static contextType = CmsContext;

    public render() {
        const {claimNumber, emailAddress, vehicleBrand} = this.props;
        return (
            <>
                <SuccessHeaderMessageBox
                    message={claimNumber ? CmsHelper.withPrefix(this.context, `success.client.claim.information`) : CmsHelper.withGeneralPrefix(`motor.claim.success.noPolicy.information`)}/>
                <SuccessHeaderTable email={emailAddress} claimNumber={claimNumber}/>
                {claimNumber ? <Spacing type={"padding"} bottom={'4'}/> : null}
                {claimNumber ? <MyPagesButton/> : <></>}
                <WorkshopButton vehicleBrand={vehicleBrand} claimNumber={claimNumber}/>
            </>
        );
    }
}

import * as React from "react";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {Constants, GroupOptInOptOut, IJourneySchema} from "@folksam-digital/model";
import {
    Grid,
    MaxWidthWrapper,
    Spacing,
    Step,
} from "@folksam-digital/ui";
import {IJourneySuccessBaseProps} from "../../product/success/JourneySuccessBase";
import {CmsContext, ICmsContext} from "../../../../cms"
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {DirectDebitButton} from "../success/DirectDebitButton";
import {
    IOnTriggerDataLayerEvent,
    SatelliteEvents,
    SatelliteEventTransactionTypes
} from "../../../../analytics/new/BaseAnalytics";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {IDraftService, StorageKeys} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {MyPagesButton, ProductContactUsSection} from "../../../../components/journey/layout/Success";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {UrlHelper} from "@folksam-digital/services";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import OptInOptOutProductList from "../../../../components/optInOptOut/form/container/OptInOptOutProductList";
import SuccessPageFailedProductList from "../../../../components/optInOptOut/form/container/SuccessPageFailedProductList";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

interface IOptInOptOutSuccessProps extends IJourneySuccessBaseProps, IOnTriggerDataLayerEvent {

}

interface IOptInOptOutSuccessState {

}

class OptInOptOutSuccessPageInternal extends React.Component<IOptInOptOutSuccessProps, IOptInOptOutSuccessState> {
    public static contextType = CmsContext;
    private draftService: IDraftService;
    protected prefix = "groupInsurance.optInOptOut";

    constructor(props: IOptInOptOutSuccessProps, context: ICmsContext) {
        super(props, context);

        this.draftService = container.get<IDraftService>(Types.DraftService);

        if (!this.data) {
            const schema = {} as IJourneySchema;
            this.draftService.updateDraft({
                journeyId: Constants.Journey.OptInOptOut.Id,
                backUrl: schema?.backUrl
            }, StorageKeys.JOURNEY_ERROR_DATA_KEY);

            this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
            return;
        } else {
            setJourneyCompleted(this.draftService, Constants.Journey.OptInOptOut.Id);
        }
    }

    private get data(): GroupOptInOptOut {
        return this.draftService.getDraft<GroupOptInOptOut>();
    }

    public async componentDidMount(): Promise<void> {
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: this.data,
            journeyId: Constants.Journey.OptInOptOut.Id,
            currentStep: "success",
            transactionType: SatelliteEventTransactionTypes.TransactionPage,
            event: SatelliteEvents.Complete
        });
    }

    public render() {
        const data = this.data;
        const {history} = this.props;

        if (isEmpty(data)) {
            return <></>;
        }

        let headerInfoBoxTranslationId: string | undefined;
        if (!data.uiState?.skipPaymentStep && data.uiState?.allOptInOptOutSelected) {
            headerInfoBoxTranslationId = CmsHelper.withPrefix(this.context, "success.banner.buyAll.information");
        }
        else if (!data.uiState?.skipPaymentStep && !data.uiState?.allOptInOptOutSelected) {
            headerInfoBoxTranslationId = CmsHelper.withPrefix(this.context, "success.banner.buyAndDecline.information");
        }
        else if (data.uiState?.skipPaymentStep) {
            headerInfoBoxTranslationId = CmsHelper.withPrefix(this.context, "success.banner.declineAll.information");
        }

        return (
            <FormContext.Provider value={{data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                        navButton={CloseModalButton}
                        journeyId={Constants.Journey.OptInOptOut.Id}
                        unionGroupName={data.group.name}
                        formData={data}
                        logOutUrl={Constants.Journey.OptInOptOut.backUrl + data.group.name}
                        doScroll={true}
                        noContentPadding={true}
                        headerInfoBoxTranslationId={CmsHelper.withPrefix(this.context, "success.banner.subheading")}
                        translations={{
                            header: CmsHelper.withPrefix(this.context, "success.banner.heading"),
                            subheader: CmsHelper.withPrefix(this.context, "success.banner.subheading"),
                            headerText: <FormattedMessage id={`${CmsHelper.withPrefix(this.context, "pageTitle.headerText")}`}/>,
                            headerInfoBox: headerInfoBoxTranslationId,
                            collaborationBanner:  CmsHelper.withPrefix(this.context, "bannerLogo.inCollaborationWith"),
                        }}
                    >
                        <SuccessHeaderTable
                            email={data.member.contact.emailAddress}
                        />
                    </SuccessHeaderLayout>

                    <ComponentBackgroundWrapper theme={1}>
                        <MaxWidthWrapper compact={true}>
                            <Step.SectionBody>
                                <Spacing type={'padding'} top={'sm'}>
                                    <OptInOptOutProductList data={data}/>
                                </Spacing>

                                {!data.uiState?.skipPaymentStep &&
                                    <SuccessPageFailedProductList data={data}/>
                                }

                                <Spacing type={'padding'} bottom={'sm'}>
                                    <DirectDebitButton
                                        cmsContext={this.context}
                                        journeyData={data}
                                        history={history}
                                        successfulPolicyCreation={true}
                                        displayBankIdIcon
                                        stepBack={true}
                                        noBanner={true}
                                        translations={{
                                            directDebitButtonHeader: CmsHelper.withPrefix(this.context, "success.directDebit.button.header"),
                                            directDebitButtonLabel: CmsHelper.withPrefix(this.context, "success.directDebit.button.title")
                                        }}
                                    />
                                </Spacing>
                                <MyPagesButton
                                    myPagesButtonTitle={CmsHelper.withPrefix(this.context, "success.myPages.button.title")}
                                />
                            </Step.SectionBody>
                        </MaxWidthWrapper>
                        {/*Footer*/}
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            <Spacing type={'padding'} top={'lg'}/>
                                            <ProductContactUsSection/>
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        )
    }
}

const OptInOptOutSuccessPage = flowRight(
    withCmsProvider("optInOptOut"),
    withDataAnalytics(getAnalytics(AnalyticsType.OptInOptOut)),
    withRouter,
    withHistoryBackConstraint
)(OptInOptOutSuccessPageInternal);

export default OptInOptOutSuccessPage;

import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Spacing, defaultTheme, Headings, Grid} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";
import {
    IMetadata as IMessageValues,
    getMessageValues
} from "../../../journey/form/output/helpers/JourneyOutputComponentBaseFunctions";
import {Breakpoints, Constants} from "@folksam-digital/model";
import {FormContext} from "../FormContext";
import {NumberRounder} from "../../../../Helpers/NumberRounder";
import {IBreakPoint} from "../../layout/helpers";

interface IMetadata {
    spaceTop?: boolean;
    spaceBottom?: boolean;
    breakpoints?: IBreakPoint;
    messageValues?: IMessageValues;
    theme?: 0 | 6;
    round?: string[];
}

interface IThemeStyle {
    backgroundColor?: any;
    titleAndContentBorderStyle?: any;
    outsideBorderStyle?: any;
}

interface IThemeStyles {
    [key: number]: IThemeStyle;
}

class ContentPanel extends PanelComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;

    getValues() {
        let values;
        if (this.metadata.messageValues && this.context) {
            values = getMessageValues(this.metadata.messageValues, this.context);
        }

        return values;
    }

    getTheme(theme: number): IThemeStyle | undefined {
        const style = {
            [Constants.Themes.PanelWithBackground]: {
                backgroundColor: defaultTheme.colors.quinary3,
                titleAndContentBorderStyle: {borderTop: `1px solid ${defaultTheme.colors.senary2}`}
            },
            [Constants.Themes.PanelWithOutBackgroundOutsideBorderNoBottom]: {
                outsideBorderStyle: {borderWidth: "1px", borderStyle: "solid solid none solid", borderColor: "#DDDCD5"}
            },
            [Constants.Themes.PanelNoBackgroundNoBorder]: {
                outsideBorderStyle: {borderWidth: "1px", borderStyle: "solid none none none", borderColor: defaultTheme.colors.senary3}
            },
            [Constants.Themes.PanelWithBackgroundOutsideBorderNoTop]: {
                backgroundColor: defaultTheme.colors.quinary3,
                titleAndContentBorderStyle: {borderTop: `1px solid ${defaultTheme.colors.senary2}`},
                outsideBorderStyle: {borderWidth: "1px", borderStyle: "none solid solid solid", borderColor: "#DDDCD5"}
            },
            [Constants.Themes.NoTheme]: {
                backgroundColor: undefined
            },
        } as IThemeStyles;

        return style[theme];
    }

    public render() {
        const {schema} = this.props;
        const title = schema.title;

        const {
            spaceTop = true,
            spaceBottom = true,
            theme = Constants.Themes.PanelWithBackground,
            round
        } = this.metadata;
        let values = this.getValues();
        if (round && values) {
            values = NumberRounder.formatRound(round, values);
        }
        const themeStyles = this.getTheme(theme) || {};

        return (
            <>
                <Grid.Row>
                    <Grid.Col {...this.getBreakpoints()}>
                        {spaceTop && <Spacing type={"padding"} top={"3"}/>}
                        <div style={themeStyles.outsideBorderStyle}>
                            <div style={{backgroundColor: themeStyles.backgroundColor}}>
                                <Spacing type={"padding"} all={"md"}>
                                    {title && <Headings.H2 color={"primary1"}>
                                        <FormattedMessage id={title} values={values}/>
                                    </Headings.H2>
                                    }
                                    {title && <div style={themeStyles.titleAndContentBorderStyle}/>}
                                    {title && themeStyles.titleAndContentBorderStyle &&
                                    <Spacing type={"padding"} top={"md"}/>}
                                    {this.props.properties?.map(p => p.content)}
                                </Spacing>
                            </div>
                        </div>
                        {spaceBottom && <Spacing type={"margin"} bottom={"md"}/>}
                    </Grid.Col>
                </Grid.Row>
            </>
        );
    }

    private getBreakpoints(): IBreakPoint {
        const defaultBreakpoints: IBreakPoint = {
            [Breakpoints.sm]: 12,
            [Breakpoints.md]: 12,
            [Breakpoints.lg]: 12,
        };

        return {
            ...defaultBreakpoints,
            ...this.metadata.breakpoints
        };
    }
}

export {ContentPanel};

import * as React from "react";
import JourneySuccessBase from "../../../Journey/product/success/JourneySuccessBase";
import {CaravanJourney, Constants, pathof} from "@folksam-digital/model";
import {CmsContext} from "../../../../cms";
import {flowRight} from "lodash";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {withRouter} from "react-router";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import isEmpty from "lodash/isEmpty";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {FormattedDate, FormattedMessage} from "react-intl";
import {
    defaultTheme as theme,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard,
    CaravanIconLarge
} from "@folksam-digital/ui";
import SuccessPageHeaderContent from "../../../../components/journey/layout/Success/SuccessPageHeaderContent";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {ListRowItem, RowPrice} from "../common/ListRowItem";
import {Template} from "../../../../components/journey/form/output/helpers";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";
import CaravanJourneyModel from "@folksam-digital/model/lib/model/CaravanJourneyModel";

class CaravanJourneySuccessInternal extends JourneySuccessBase<CaravanJourney> {
    public static contextType = CmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.Caravan.Id);
        this.triggerDataLayerEvent(Constants.Journey.Caravan.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.Caravan.Id}
                    formData={{contact: data.contact}}
                    backUrl={Constants.Journey.Caravan.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: "general.success.banner.heading",
                        subheader: "product.caravan.success.banner.subheading",
                        headerText: <FormattedMessage id={"product.caravan.pageTitle.headerText"}/>
                    }}
                    doScroll={true}
                >
                    <SuccessPageHeaderContent journeyId={Constants.Journey.Caravan.Id}
                                              formData={data}
                                              handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" top={"8"}>
                                            <SummaryCard
                                                theme={this.summaryCardThemeID}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<CaravanIconLarge
                                                        style={{width: 30}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id="product.caravan.success.insurance.name"/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList>
                                                        <ListRowItem
                                                            id="general.review.scope.title">
                                                            <FormattedMessage
                                                                id={`product.caravan.sizeOfInsurance.package.${data.policy.coverPlan}.heading`}/>
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            id="product.caravan.success.carDamage.title">
                                                            {data.policy.coverPlan === Constants.CoverPlan.Caravan.Full ?
                                                                <Template
                                                                template={`
                                                                {{#translate}} {{${CmsHelper.getPrefix(this.context)}.reviewAndSubmit.coverage.yes}} {{/translate}}
                                                                {{#extractDeductible}} {{${pathof(CaravanJourneyModel.policy.insuredObject.deductible)}}} {{/extractDeductible}}`}
                                                            data={data} /> :
                                                                <FormattedMessage id={`general.no`}/>}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            id="product.caravan.success.appliesTo.title">
                                                            {data.policy.insuredObject?.mark}, {data.policy.insuredObject?.yearOfManufacture} - {data.policy.insuredObject?.registrationNumber}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            id="general.startDate.title">
                                                            <FormattedDate value={data.policy?.startDate}/>
                                                        </ListRowItem>
                                                        <RowPrice premium={data.policy.premium!}/>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const CaravanJourneySuccess = flowRight(
    withCmsProvider("caravan"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint
)(CaravanJourneySuccessInternal);

export default CaravanJourneySuccess;

import * as React from "react";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {
    Badge,
    CompactCompareListTable,
    defaultTheme as theme,
    DisplayIcon,
    Grid,
    IconCheckmark,
    IconWinner,
    ListGroup,
    QuestionSkeleton,
    withViewContext
} from "@folksam-digital/ui";
import {injectIntl} from "react-intl";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import castArray from "lodash/castArray";
import {
    Constants,
    ICollectivesInfo,
    ICollectivesInfoAgreementContent,
    ICompactCoverListTableContentItemConfig,
    LegacyCoverPlan,
    pathof
} from "@folksam-digital/model";
import journeyBaseModel from "@folksam-digital/model/lib/model/JourneyBaseModel";
import HomeWithSmallBoatJourneyModel from "@folksam-digital/model/lib/model/HomeWithSmallBoatJourneyModel";
import {FormContext, IFormContext} from "../FormContext";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {ListGroupItems} from "./index";
import {Markdown} from "../../../Markdown";
import {CompactCoverListTableIcons} from "./helpers/compactCoverListTableIconMap";
import {AnalyticsHelper} from "../../../../Helpers/analytic/AnalitycsHelper";
import {withCmsContextConsumer} from "../../../../cms/withCmsContextConsumer";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IPopOverConfig {
    isPopOverDisplayed: boolean;
    id?: string;
    label?: string;
}

interface INode {
    name: string;
    content?: string;

    [key: string]: string | boolean | undefined;
}

interface IMetadata {
    coversList?: string[];
    columnContentItemConfig?: ICompactCoverListTableContentItemConfig | ICompactCoverListTableContentItemConfig[];
    icon: string;
    disableCoverPlans?: string[];
    filteredCoverPlans?: string[]; // Useful for mocking more complex behaviour
    sourcePath?: string;
    selectedCoverPlanSourcePath?: string;
    disabledCoverPlansPath?: string;
}

interface IState {
    selected?: number;
    collectivesInfoContentFlat?: ICollectivesInfoAgreementContent[];
}

class CompactCoverListTableInternal extends InputComponentBase<string, IMetadata, IState, any> {
    public static contextType = FormContext;

    constructor(props: any) {
        super(props);
        this.state = {
            selected: undefined
        };
    }

    getCoverPlansPath() {
        return this.metadata.sourcePath || pathof(journeyBaseModel.policy.premium.coverPlans);
    }

    getSelectedCoverPlanPath() {
        return this.metadata.selectedCoverPlanSourcePath || pathof(journeyBaseModel.policy.coverPlan);
    }

    public componentDidMount(): void {
        this.setSelectedCoverPlanIndex();
        if (this.context?.data?.policy?.insuredObject?.insuranceType === Constants.HomeGroupType.KHome) {
            const {columnContentItemConfig} = this.metadata;
            const columnContentItemConfigList = castArray(columnContentItemConfig) as ICompactCoverListTableContentItemConfig[];
            if (columnContentItemConfig && !isEmpty(columnContentItemConfigList) && columnContentItemConfigList.some(itemConfig => !!itemConfig.rowIdToAppend?.collectivesInfoTarget)) {
                this.setState({collectivesInfoContentFlat: this.getCollectivesInfoScenariosContentFlat()});
            }
        }
    }

    private setSelectedCoverPlanIndex() {
        const sourcePath = this.getCoverPlansPath();
        const selectedCoverPlanPath = this.getSelectedCoverPlanPath();
        const coverPlans = get(this.context?.data, sourcePath, []);
        const selectedCoverPlan = get(this.context?.data, selectedCoverPlanPath, undefined);

        if (selectedCoverPlan) {
            const selected = coverPlans.findIndex((row: LegacyCoverPlan) => row.id === selectedCoverPlan);
            this.setState({selected});
        }
    }

    public componentDidUpdate(prevProps: Readonly<IInputComponentProps<string, IMetadata>>, prevState: Readonly<{ selected: number }>, snapshot?: any) {
        this.setSelectedCoverPlanIndex();
    }

    public onCoverSelectionClick = (event: any, value: any) => {
        this.setState({selected: value});
    };

    public render() {
        const {coversList, icon, filteredCoverPlans} = this.metadata;
        const {viewContext} = this.props;
        const {disableButton} = this.context;
        const sourcePath = this.getCoverPlansPath();
        let coverPlans: LegacyCoverPlan[] = Object.values(get(this.context.data, sourcePath, {}) || {});
        if (!!filteredCoverPlans && !isEmpty(filteredCoverPlans)) {
            coverPlans = coverPlans.filter((coverPlan: LegacyCoverPlan) => !filteredCoverPlans.includes(coverPlan?.id!));
        }

        const isMobile = viewContext.isMobile;
        const popOverConfig = this.getPopOverConfig(coverPlans);

        return (
                    <FormContext.Consumer>
                        {(context: IFormContext) => (
                            context.data.policy?.premium && coverPlans ?
                                <FormFieldLayout {...this.getLayoutProps()}>
                                    <Grid style={{paddingTop: popOverConfig.isPopOverDisplayed ? 31 : 17}}>
                                        <Grid.Row style={{flexDirection: "column", marginLeft: 0, marginRight: 0, gap: theme.margin['5']}}>
                                            <ListGroup key={"sm"} horizontal={"xs"}>
                                                <ListGroupItems
                                                    isUpdating={disableButton}
                                                    spacing={isMobile ? "md" : "lg"}
                                                    items={this.getRenders(coverPlans, popOverConfig)}
                                                    selected={this.state.selected}
                                                    icon={CompactCoverListTableIcons[icon]}
                                                    selectHandler={(event: any, value: number) => {
                                                        event.preventDefault();
                                                        if (!disableButton && this.state.selected !== value) {
                                                            this.onChangeWithValidation(coverPlans[value].id);
                                                            this.onCoverSelectionClick(event, value);
                                                        }
                                                    }}
                                                />
                                            </ListGroup>
                                            <CompactCompareListTable
                                                style={{fontSize: theme.textSizes.base}}
                                                selected={this.state.selected}
                                                columns={this.getColumns(coverPlans, isMobile)}
                                                data={this.getNodes(coversList)}
                                            />
                                        </Grid.Row>
                                    </Grid>
                                </FormFieldLayout>
                                :
                                <QuestionSkeleton/>
                        )}
                    </FormContext.Consumer>
        );
    }

    private createNode(cover: string): INode {
        const singleCover = this.props.intl.formatMessage({id: cover}).split("__");
        const coverName = singleCover[0];
        const coverDescription = singleCover[1];
        let includedIn = singleCover[2];
        const nodeObj: any = {
            listId: cover,
            name: coverName,
            content: coverDescription ? <Markdown source={coverDescription}/> : undefined,
        } as INode;
        if (includedIn) {
            includedIn = includedIn.split("|");
            includedIn.forEach((coverPlan: string) => {
                    nodeObj[coverPlan] = true;
                }
            );
        }

        return nodeObj;
    }

    private getNodes = (coversList: string[] | undefined): INode[] | undefined => {
        if (!coversList) {
            return;
        }

        let coversListTmp = coversList.map(cover => {
            return this.createNode(cover);
        });

        const {columnContentItemConfig} = this.metadata;
        // // @ToDo - can be removed after 5th may. edge case in cat step2 of hiding node, based on criteria
        if (this.context?.data?.journeyId === Constants.Journey.Cat.Id) {
            const columnContentItemConfigObj = !Array.isArray(columnContentItemConfig) ? columnContentItemConfig as ICompactCoverListTableContentItemConfig : undefined;
            if (columnContentItemConfig) {
                const fieldPathValue = (columnContentItemConfigObj?.condition && columnContentItemConfigObj.condition?.fieldPath) && get(this.context?.data, columnContentItemConfigObj.condition.fieldPath);
                if (fieldPathValue?.length <= 2) {
                    coversListTmp = coversListTmp.filter(listObj => listObj?.listId !== columnContentItemConfigObj?.rowId);
                }
            }
        }

        if (this.state?.collectivesInfoContentFlat && !isEmpty(this.state.collectivesInfoContentFlat)) {
            const columnContentItemConfigList = castArray(columnContentItemConfig) as ICompactCoverListTableContentItemConfig[];
            for (const config of columnContentItemConfigList) {
                const {rowIdToAppend} = config;
                const {rowId, collectivesInfoTarget} = rowIdToAppend || {};

                if (rowId && collectivesInfoTarget?.keyName && collectivesInfoTarget?.id
                    && this.state.collectivesInfoContentFlat.find(cco => cco[collectivesInfoTarget.keyName as keyof ICollectivesInfoAgreementContent] === collectivesInfoTarget.id)) {
                    coversListTmp.push(this.createNode(rowId));
                }
            }
        }

        return coversListTmp;
    };

    private getCollectivesInfoScenariosContentFlat(): ICollectivesInfoAgreementContent[] | undefined {
        const collectivesInfoList = get(this.context?.data, pathof(HomeWithSmallBoatJourneyModel.policy.insuredObject.group.collectivesInfo)) || [] as ICollectivesInfo[];

        if (!isEmpty(collectivesInfoList)) {
            let collectivesInfoConcatResult = [] as ICollectivesInfoAgreementContent[];

            for (const collectivesInfoObj of collectivesInfoList) {
                const {alreadySelectedAddonsMandatory} = collectivesInfoObj;
                if (Array.isArray(alreadySelectedAddonsMandatory)) {
                    collectivesInfoConcatResult = collectivesInfoConcatResult.concat(alreadySelectedAddonsMandatory)
                }
                const {alreadySelectedAddonsSubscription} = collectivesInfoObj;
                if (Array.isArray(alreadySelectedAddonsSubscription)) {
                    collectivesInfoConcatResult = collectivesInfoConcatResult.concat(alreadySelectedAddonsSubscription)
                }
            }
            return collectivesInfoConcatResult;
        }
    }

    private getColumnContent(coverPlan: any, index: number, item: any) {
        const {columnContentItemConfig} = this.metadata;

        if (columnContentItemConfig) {
            const columnContentItemConfigObj = !Array.isArray(columnContentItemConfig) ? columnContentItemConfig as ICompactCoverListTableContentItemConfig : undefined;
            const columnContentItemConfigs = Array.isArray(columnContentItemConfig) ? columnContentItemConfig as ICompactCoverListTableContentItemConfig[] : undefined;
            const fieldPathValue = (columnContentItemConfigObj?.condition && columnContentItemConfigObj.condition?.fieldPath) && get(this.context?.data, columnContentItemConfigObj.condition.fieldPath);
            const styleBase = {fontSize: theme.textSizes.xs, fontWeight: theme.fontWeights.regular};

            if (item.listId === columnContentItemConfigObj?.rowId && fieldPathValue && fieldPathValue === columnContentItemConfigObj?.condition?.compareValue && item[coverPlan.id]) {
                return <div style={styleBase}>
                    {this.props.intl.formatMessage({id: columnContentItemConfigObj.messageId})}
                </div>
            } else if (columnContentItemConfigs && item[coverPlan.id]) {
                const config = columnContentItemConfigs.find((itemConfig) => itemConfig.rowId === item.listId && itemConfig.colId === coverPlan.id);
                if (config) {
                    return <div style={{textAlign: "center", ...styleBase}}>
                        <Markdown source={this.props.intl.formatMessage({id: config.messageId})}/>
                    </div>
                }
            }
        }

        if (item[coverPlan.id]) {
            return <IconCheckmark style={{
                width: 20,
                height: 13,
                fontWeight: theme.fontWeights.medium,
                color: index === this.state.selected ? theme.colors.primary2 : theme.colors.senary1
            }}/>
        } else {
            return <></>;
        }
    }

    private getColumns(coverPlans: any, isMobile: boolean) {
        const columnProps = {centered: true, style: {color: theme.colors.senary1}};
        const prefix = CmsHelper.getPrefix(this.props.cmsContext)
        const columns = coverPlans.map((coverPlan: any, index: number) => {
            return {
                key: index,
                name: this.props.intl.formatMessage({id: `${prefix}.coverPlans.${coverPlan.id}.title`}),
                renderCell: (item: any, props: any) => (
                    <Grid.Col key={index} centered={true}
                              style={index === 0 ? {marginLeft: "auto"} : undefined} {...props}>
                        {this.getColumnContent(coverPlan, index, item)}
                    </Grid.Col>
                ),
                props: {
                    ...columnProps,
                    style: index === 0 ? {marginLeft: "auto", ...columnProps.style} : columnProps.style
                }
            };
        });

        return [
            {
                name: this.props.intl.formatMessage({id: `${prefix}.covers.whatIsIncluded.title`}),
                renderCell: (item: any, props: any) => (
                    <Grid.Col
                        vCentered={true}
                        role="button"
                        tabIndex={0}
                        style={{
                            color: theme.colors.primary2,
                            fontWeight: theme.fontWeights.medium,
                            maxWidth: isMobile ? "205px" : "280px",
                            padding: "8px 4px",
                            boxSizing: "border-box"
                        }} {...props}>
                        {item.name}
                    </Grid.Col>
                ),
                props: {vCentered: true, style: {color: "#363636", fontWeight: theme.fontWeights.semiBold}}
            },
            ...columns
        ];
    }

    private getRenders(coverPlans: LegacyCoverPlan[], popOverConfig: IPopOverConfig) {
        const {disableCoverPlans, disabledCoverPlansPath} = this.metadata;
        const {disableButton} = this.context;
        const prefix = CmsHelper.getPrefix(this.props.cmsContext)
        const path = disabledCoverPlansPath || pathof(journeyBaseModel.uiState?.disabledCoverPlanIds);
        const uiStateDisabledCoverPlans = get(this.context.data, path);

        return coverPlans.map((coverPlan: any) => {
            const isDisabled = (disableCoverPlans && disableCoverPlans.includes(coverPlan.id)) || uiStateDisabledCoverPlans?.includes(coverPlan.id);
            return {
                coverPlanId: coverPlan.id,
                iconText: this.props.intl.formatMessage({id: `${prefix}.coverPlans.${coverPlan.id}.title`}),
                currencyText: (
                    <>
                        <div style={{
                            fontSize: theme.textSizes.large,
                            fontFamily: theme.fonts.serif,
                            fontWeight: theme.fontWeights.semiBold
                        }}>
                            {isDisabled ? `-` : Math.round(coverPlan.monthlyPremium)} {isDisabled ? "" : this.props.intl.formatMessage({id: `general.listGroup.currencyText.suffix`})}
                        </div>
                        <div style={{
                            fontSize: theme.textSizes.base,
                            fontFamily: theme.fonts.serif,
                            fontWeight: theme.fontWeights.medium
                        }}>
                            {isDisabled ? this.props.intl.formatMessage({id: `${prefix}.coverPlans.unavailable.title`}) : this.props.intl.formatMessage({id: `general.listGroup.currencyText.frequency`})}
                        </div>
                    </>
                ),
                popover: popOverConfig.isPopOverDisplayed && popOverConfig.id === coverPlan.id && (
                    <Badge
                        bookmark={true}
                        type='offer'
                        icon={<DisplayIcon medium={true} icon={<IconWinner/>}/>}
                        text={popOverConfig.label}
                        disableAnimation={true}
                    />
                ),
                disabled: isDisabled || disableButton ? true : undefined
            };
        });
    }

    private getPopOverConfig(coverPlans: LegacyCoverPlan[]): IPopOverConfig {
        const popOverConfig = {isPopOverDisplayed: false} as IPopOverConfig;
        const prefix = CmsHelper.getPrefix(this.props.cmsContext)
        // when variantB popOver is not displayed
        if (get(this.context.data, pathof(journeyBaseModel.uiState.adobeTargetVariant)) === Constants.Analytics.Target.variantB
            && AnalyticsHelper.isAdobeScriptEnabledForJourney(get(this.context.data, pathof(journeyBaseModel.journeyId)))) {
            return popOverConfig;
        }

        for (const coverPlan of coverPlans) {
            const winnerPopOverText = this.props.intl.messages[`${prefix}.coverPlans.winner.popOver.${coverPlan.id}`];
            if (winnerPopOverText) {
                popOverConfig.isPopOverDisplayed = true;
                popOverConfig.id = coverPlan.id;
                popOverConfig.label = winnerPopOverText;
                break;
            }

        }

        return popOverConfig;
    }

}

const CompactCoverListTable = injectIntl(withViewContext(withCmsContextConsumer(CompactCoverListTableInternal)));
export {CompactCoverListTable};

import * as React from "react";
import get from "lodash/get";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {FormContext} from "../FormContext";
import {injectIntl} from "react-intl";
import {withViewContext} from "@folksam-digital/ui";
import {DownloadLinkOutputBase} from "./DownloadLinkOutputBase";
import {CmsContext, ICmsContext} from "../../../../cms";

class PrePostPurchaseDownloadLinkOutput extends DownloadLinkOutputBase {
    public static contextType = FormContext;

    public render() {
        const {modelPath} = this.metadata;
        const modelValue = get(this.context.data, modelPath!);
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const messageKey = (modelValue && `prePostPurchaseUrl.${modelValue}`) || `prePostPurchaseUrl`;
                    const url = this.props.intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, messageKey)});
                    return <>
                        {this.renderLink(url)}
                    </>
                }}
            </CmsContext.Consumer>
        )
    }
}

const PrePostPurchaseDownloadLink = injectIntl(withViewContext(PrePostPurchaseDownloadLinkOutput));
export {PrePostPurchaseDownloadLink};

import * as React from "react";
import {Modal, Iframe, ButtonResponsive, B, Spacing, withViewContext} from "@folksam-digital/ui";
import {ViewContextProps} from "../../../../../types/@folksam-digital/ui/lib/ViewContextProvider";

interface IModalIframeProps extends ViewContextProps{
    url: string;
    title: React.ReactElement;
    classNames?: string;
    isBold?: boolean;
    asLink?: boolean;
    noPadding?: boolean;
    customStyles?: React.CSSProperties;
}

interface IModalLayoutState {
    isOpen: boolean;
}

class ModalIframeInternal extends React.Component<IModalIframeProps, IModalLayoutState> {
    constructor(props: IModalIframeProps) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleOnToggle = this.handleOnToggle.bind(this);
    }
    public render() {
        const {
            url,
            title,
            isBold,
            asLink,
            noPadding,
            viewContext,
            customStyles
        } = this.props;

        return (
            <>
                {this.wrapInParent(
                    <ButtonResponsive
                        type="button"
                        asLink={asLink === undefined ? true : asLink}
                        onClick={this.handleOnToggle}
                        style={{textAlign: "left", ...customStyles}}
                    >
                        {isBold === false ? title : <B>{title}</B>}
                    </ButtonResponsive>
                )}
                <Modal
                    isOpen={this.state.isOpen}
                    shouldCloseOnOverlayClick={true}
                    onRequestClose={this.handleOnToggle}
                    style={{
                        content: {
                            maxWidth: "815px",
                            width: "100%",
                            height: "100%",
                            padding: noPadding ? 0 : viewContext.isMobile ? "0 0.4rem 0.8rem" : "0.8rem 0.4rem"
                        },
                        overlay: {
                            zIndex: "110000",
                        },
                    }}
                >
                    <Modal.Header showCloseButton onModalClose={() => this.setState({isOpen: false})}/>
                    <Spacing type={"padding"} y={"2"}/>
                    <Iframe
                        src={url}
                        title={title}
                        style={{height: "95%", justifyContent: "top", marginTop: "-1rem"}}
                    />
                </Modal>
            </>
        );
    }

    private wrapInParent(body: React.ReactElement): React.ReactNode {
        if (this.props.classNames) {
            return (
                <div className={this.props.classNames}>
                    {body}
                </div>
            );
        } else {
            return body;
        }
    }

    private handleOnToggle(event: any) {
        this.setState({isOpen: !this.state.isOpen});
    }
}

const ModalIframe = withViewContext(ModalIframeInternal);
export {ModalIframe};

import {withRouter} from "react-router";
import * as React from "react";
import {FormattedDate, FormattedMessage} from "react-intl";
import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";
import flowRight from "lodash/flowRight";
import {AccidentAndIllnessJourney, Constants, Cover, CoverPlan} from "@folksam-digital/model";
import {
    defaultTheme as theme,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {CmsContext} from "../../../../cms";
import JourneySuccessBase from "./JourneySuccessBase";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import SuccessPageHeaderContent from "../../../../components/journey/layout/Success/SuccessPageHeaderContent";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {ListRowItem, RowPrice} from "../common/ListRowItem";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

class AccidentAndIllnessJourneySuccessInternal extends JourneySuccessBase<AccidentAndIllnessJourney> {
    public static contextType = CmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.AccidentAndIllness.Id);
        this.triggerDataLayerEvent(Constants.Journey.AccidentAndIllness.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        const coverPlan = data.policy?.premium?.coverPlans?.find((row: CoverPlan) => row.id === data.policy?.coverPlan);
        const coversById = keyBy(coverPlan?.covers, "id");
        const criticalIllnessInsuranceAmount = (cover: Cover) => cover.id === data.policy?.covers?.criticalIllnessInsuranceAmount.id;

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.AccidentAndIllness.Id}
                    formData={{contact: data.contact}}
                    backUrl={Constants.Journey.AccidentAndIllness.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: "general.success.banner.heading",
                        subheader: data.policy?.policyNumber ? "product.accidentAndIllness.success.banner.subheading.policy" : "product.accidentAndIllness.success.banner.subheading.proposal",
                        headerText: <FormattedMessage id={`product.accidentAndIllness.pageTitle.headerText`}/>
                    }}
                    doScroll={true}
                >
                    <SuccessPageHeaderContent journeyId={Constants.Journey.AccidentAndIllness.Id}
                                              formData={data}
                                              handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" top={"8"}>
                                            <SummaryCard
                                                theme={3}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id={CmsHelper.withPrefix(this.context, "success.insurance.name")}/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList separated={true}>
                                                        <ListRowItem
                                                            id="general.appliesTo.title">
                                                            {data.contact.firstName} {data.contact.lastName}
                                                        </ListRowItem>
                                                        <ListRowItem
                                                            id="general.review.scope.title">
                                                            <FormattedMessage
                                                                id={CmsHelper.withPrefix(this.context, "success.insurance.name")}/>
                                                            <br/>
                                                            <FormattedMessage
                                                                id={CmsHelper.withPrefix(this.context, "success.insurance.amount")}
                                                                values={{premium: coversById[Constants.Product.AccidentAndIllness.mainCoverId]?.insuranceAmount}}/>
                                                        </ListRowItem>

                                                        {data.policy?.covers?.criticalIllness?.selected &&
                                                        <ListRowItem
                                                            termBold
                                                            id={CmsHelper.withPrefix(this.context, "success.insurance.extensions")}>
                                                            <div>
                                                                <FormattedMessage
                                                                    id={CmsHelper.withPrefix(this.context, `cover.${data.policy.covers.criticalIllness.id}`)}/>
                                                                {
                                                                    data.policy?.covers?.criticalIllnessInsuranceAmount?.id &&
                                                                    <>
                                                                        <span> - </span><FormattedMessage
                                                                        id={CmsHelper.withPrefix(this.context, "success.insurance.amount")}
                                                                        values={{premium: data.policy?.premium?.covers?.find(criticalIllnessInsuranceAmount)?.insuranceAmount}}/>
                                                                    </>
                                                                }
                                                            </div>
                                                        </ListRowItem>
                                                        }
                                                        <ListRowItem
                                                            id="general.startDate.title">
                                                            <FormattedDate value={data.policy?.startDate}/>
                                                        </ListRowItem>
                                                        <RowPrice premium={data.policy.premium!}/>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const AccidentAndIllnessJourneySuccess = flowRight(
    withCmsProvider("accidentAndIllness"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint
)(AccidentAndIllnessJourneySuccessInternal);

export default AccidentAndIllnessJourneySuccess;

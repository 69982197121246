import * as React from "react";
import {Step, Spacing} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";

type TSpacingProps = 'sm' | 'md' | 'lg' | '0' | '1' | '2' | '3' | '4' | '5';

export interface IStepMetadata {
    showBorder?: boolean;
    showBorderBottom?: boolean;
    marginTop?: boolean;
    marginBottom?: boolean;
    spacingTop?: TSpacingProps;
    spacingRight?: TSpacingProps;
    spacingBottom?: TSpacingProps;
    spacingLeft?: TSpacingProps;
}

export class StepItemOutput extends PanelComponentBase<string, IStepMetadata, {}> {
    public render() {
        const {
            showBorder = false,
            showBorderBottom = false,
            marginTop = false,
            marginBottom = false,
            spacingTop,
            spacingRight,
            spacingBottom,
            spacingLeft
        } = this.metadata;

        return (
            <>
                {marginTop && <Spacing type={"margin"} bottom={"3"}/>}
                <Step.Item border={showBorder} borderBottom={showBorderBottom}
                           spacingTop={spacingTop} spacingRight={spacingRight}
                           spacingBottom={spacingBottom} spacingLeft={spacingLeft}>
                    {this.props.properties?.map(p => p.content)}
                </Step.Item>
                {marginBottom && <Spacing type={"margin"} bottom={"md"}/>}
            </>
        );
    }
}

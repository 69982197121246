import React, {forwardRef, PropsWithChildren, useMemo} from "react";
import {IBreakPoint} from "../../layout/helpers";
import {defaultTheme, DisplayIcon, FlexRow, Grid, IconTooltip, Spacing} from "@folksam-digital/ui";
import {Breakpoints} from "@folksam-digital/model";
import {createUseStyles} from "react-jss";

export type ITheme = "primary" | "secondary";

interface InformationBoxContainerProps extends PropsWithChildren<any> {
    breakpoints?: IBreakPoint;
    theme?: ITheme;
    classNames?: string;
    withTooltipIcon?: boolean;
}

const colors = {
    primary: {
        border: defaultTheme.colors.primary4,
        backgroundColor: defaultTheme.colors.primary5,
        color: defaultTheme.colors.primaryInteraction2,
        listIconColor: null
    },
    secondary: {
        border: defaultTheme.colors.secondarySuccess2,
        backgroundColor: defaultTheme.colors.secondarySuccess1,
        color: defaultTheme.colors.secondarySuccess4,
        listIconColor: 'inherit'
    }
};

const useStyles = createUseStyles(() => ({
    wrapperStyle: {
        boxShadow: "0px 0px 1px rgba(93, 105, 126, 0.1), 0px 1px 4px rgba(93, 105, 126, 0.2)", // Unable to use value from defaultTheme here since it contains semicolon
        borderRadius: defaultTheme.borderRadius["10"],
        border: ({theme}: { theme: ITheme }) => `${defaultTheme.borderWidths.default} solid ${colors[theme].border}`,
        backgroundColor: ({theme}: { theme: ITheme }) => colors[theme].backgroundColor,
        color: ({theme}: { theme: ITheme }) => colors[theme].color,
    },
    listColor: {
        '& ul > li': {
            '& svg': {
                color: ({theme}: { theme: ITheme }) => colors[theme].listIconColor,
            }
        }
    }
}));

export const InformationBoxContainer = forwardRef<HTMLDivElement, InformationBoxContainerProps>(
    ({theme, breakpoints, classNames, children, withTooltipIcon}, ref) => {
        const classes = useStyles({theme});

        const componentBreakpoints = useMemo(() => {
            const defaultBreakpoints: IBreakPoint = {
                [Breakpoints.md]: 12,
            };

            return {
                ...defaultBreakpoints,
                ...breakpoints
            }

        }, [breakpoints]);

        const tooltip = useMemo(() => (<div>
            <DisplayIcon style={{marginRight: 8}} baseline={true} large={true} icon={<IconTooltip/>}/>
        </div>), []);

        return (
            <Grid>
                <Grid.Row>
                    <Grid.Col {...componentBreakpoints}>
                        <div ref={ref} className={classNames}>
                            <div className={classes.wrapperStyle}>
                                <Spacing type={"padding"} top={"4"} right={"5"} bottom={"4"} left={"5"}>
                                    <FlexRow style={{
                                        alignItems: "flex-start",
                                        fontSize: defaultTheme.textSizes.sm,
                                        justifyContent: "flex-start",
                                        lineHeight: defaultTheme.leading.tight
                                    }}>
                                        {withTooltipIcon && tooltip}
                                        <FlexRow
                                            style={{
                                                fontFamily: defaultTheme.fonts.mono,
                                                alignItems: "flex-start",
                                                justifyContent: "flex-start",
                                                flexDirection: "column"
                                            }}
                                            className={classes.listColor}
                                        >
                                            {children}
                                        </FlexRow>
                                    </FlexRow>
                                </Spacing>
                            </div>
                        </div>
                    </Grid.Col>
                </Grid.Row>
            </Grid>
        );
    });

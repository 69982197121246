import get from "lodash/get";
import castArray from "lodash/castArray";
import {IClassifierFilter, IOption, IOptionSelected} from "@folksam-digital/model";

import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {container} from "../../../../inversify.config";
import {IClassifierFilterExclude, IClassifierService} from "../../../../services";
import {Types} from "../../../../Types";
import {FormContext, IFormContext} from "../FormContext";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {parseLanguageCode} from "../../../../Helpers/locale/Locale";
import {WrappedComponentProps as InjectedIntlProps} from "react-intl";

interface IMessageData {
    [key: string]: string;
}

export interface IChoiceComponentMetadataBase {
    values?: IOption[];
    classifierId?: string;
    classifierFilter?: IClassifierFilter;
    classifierFilterOrder?: boolean;
    classifierFilterExclude?: IClassifierFilterExclude;
    translateClassifierTitles?: boolean;
    valuesModelPath?: string;
}

export interface IChoiceComponentStateBase {
    values?: IOption[],
}

interface IChoiceComponentBaseProps<TValue, TMetadata> extends IInputComponentProps<TValue, TMetadata>, InjectedIntlProps {
    localeContext?: ILocaleContext;
}

export abstract class ChoiceComponentBase<TValue extends string | number | boolean | IOptionSelected | IOptionSelected[], TMetadata extends IChoiceComponentMetadataBase,
    TState extends IChoiceComponentStateBase,
    TProps extends IChoiceComponentBaseProps<TValue, TMetadata> = IInputComponentProps<TValue, TMetadata>> extends InputComponentBase<TValue, TMetadata, TState, TProps> {
    public static contextType = FormContext;
    protected classifierService: IClassifierService;

    constructor(props: TProps, context?: IFormContext) {
        super(props, context);
        this.classifierService = container.get<IClassifierService>(Types.ClassifierService);
    }

    public async componentDidMount() {
        const {classifierId, classifierFilter, values, classifierFilterOrder, classifierFilterExclude} = this.metadata;
        if (classifierId && values) {
            throw new Error(`Component ui-schema contains both "classifierId" and "value", only one can be present.`);
        }

        this.fetchValuesByModelPath();

        if (classifierId) {
            try {
                // Classifier ID passed - load values using service
                let classifierValues = await this.getClassifierValues(
                    classifierId,
                    this.props.localeContext!.locale,
                    classifierFilter,
                    classifierFilterOrder,
                    classifierFilterExclude,
                );

                this.updateFormDataValue(classifierValues);

                classifierValues = this.translateClassifierTitles(classifierValues);

                this.setState({
                    values: classifierValues
                });
            } catch (error) {
                this.context.onError(error);
            }
        } else {
            if (values) {
                // values passed - set values
                const translatedValues = this.translateClassifierTitles(values);

                this.setState({
                    values: translatedValues
                });
            }
        }
    }

    public componentDidUpdate(prevProps: Readonly<TProps>, prevState: Readonly<TState>, snapshot?: any): void {
        this.fetchValuesByModelPath();
    }

    private fetchValuesByModelPath() {
        const {valuesModelPath} = this.metadata;

        if (valuesModelPath) {
            try {
                const valuesFromModel = get(this.context.data, valuesModelPath);

                if (valuesFromModel) {
                    this.setState({values: valuesFromModel});
                }
            }
            catch (error) {
                this.context.onError(error);
            }
        }
    }

    protected updateFormDataValue(classifierValues: IOption[]): void {
        if (this.props.formData) {
            let searchValue: string | boolean | object = "";
            if (typeof this.props.formData === 'number') {
                searchValue = this.props.formData.toString();
            } else {
                searchValue = this.props.formData;
            }


            let valueExists;
            if (castArray(this.schema.type).includes('object') && typeof searchValue === 'object') {
                const searchValueObject = searchValue as { value: string | number, label: string };
                valueExists = classifierValues.some((value) => value.id === searchValueObject.value);
            } else {
                valueExists = classifierValues.some((value) => value.id === searchValue)
            }

            if (!valueExists) {
                this.props.onChange(undefined);
            }
        }
    }

    public async getClassifierValues(classifierId: string, locale: string, classifierFilter?: IClassifierFilter, classifierFilterOrder?: boolean, classifierFilterExclude?: IClassifierFilterExclude): Promise<IOption[]> {
        const languageCode = parseLanguageCode(locale);
        const classifiers = await this.classifierService.getClassifiers(classifierId, languageCode, classifierFilter, classifierFilterOrder, classifierFilterExclude);
        return classifiers.map(x => {
            return {
                id: x.id.toString(),
                title: x.value,
                tooltip: x.tooltip,
                messageData: x.messageData
            }
        });
    };

    private translateClassifierTitles(classifierValues: IOption[]): IOption[] {
        const {translateClassifierTitles} = this.metadata;
        const {intl} = this.props;

        if (translateClassifierTitles) {
            return classifierValues.map((classifierValue: IOption) => {
                const messageData = this.extractMessageData(classifierValue.messageData);

                return {
                    ...classifierValue,
                    id: classifierValue.id.toString(),
                    title: intl.formatMessage({id: classifierValue.title}, messageData),
                    tooltip: {message: classifierValue.tooltip ? intl.formatMessage({id: classifierValue.tooltip?.message}, messageData) : ""}
                } as IOption;
            });
        }

        return classifierValues.map((option) => ({...option, id: option.id.toString()}));
    }

    private extractMessageData(messageData?: IMessageData): IMessageData | undefined {
        if (messageData) {
            const extractedMessageData: IMessageData = {};
            for (const [messageDataKey, messageDataPath] of Object.entries(messageData)) {
                extractedMessageData[messageDataKey] = get(this.context.data, messageDataPath, "");
            }
            return extractedMessageData;
        }
        return messageData;
    }
}

import * as React from "react";
import get from "lodash/get";
import {FormattedMessage} from "react-intl";
import {defaultTheme} from "@folksam-digital/ui";
import { withFormContext } from "../withFormContext";
import { IOutputComponentProps, OutputComponentBase } from "./OutputComponentBase";
import { FormContext } from "../FormContext";

interface IMetadata {
    limit?: number,
    limitPath?: string,
    position?: "left" | "right"
}

class CounterInternal extends OutputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;

    public render() {
        const {position} = this.metadata;

        return (
            <span style={{color: defaultTheme.colors.senary2, float: position}}>
            <FormattedMessage
                id={`general.counter.accordionCardCounter`}
                values={{
                        currentCount: this.getCurrentCollapsedCount(),
                        limit: this.getLimit()
                    }}
            />
        </span>
        );
    }

    private getLimit(): number {
        if (this.metadata.limit) {
            return this.metadata.limit;
        } else if (this.metadata.limitPath) {
            return get(this.context.data, this.metadata.limitPath);
        } else {
            return 0;
        }
    }

    private getCurrentCollapsedCount(): number {
        const expandedCards = this.context?.data?.uiState?.accordion?.otherInformation || {};
        if (expandedCards) {
            let count = 0;
            Object.keys(expandedCards).forEach(key => {
                if (expandedCards[key] === false) {
                    count += 1;
                }
            });
            return count;
        } else {
            return 0;
        }
    }

}
const CollapsedCardCounter = withFormContext<IOutputComponentProps<any, IMetadata>>(CounterInternal);
export {CollapsedCardCounter};

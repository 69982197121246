import * as React from "react";
import {FormattedMessage} from "react-intl";
import {ClaimCompensation} from "@folksam-digital/model";
import {B, defaultTheme as theme, DescriptionList, IconCheckMarkV2, SummaryCard} from "@folksam-digital/ui";
import getCompensationSum from "./helpers/getCompensationSum";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import {Tooltip} from "../../../../components/formFieldLayout/Tooltip";

interface ICompensationsTableProps {
    compensations?: ClaimCompensation[],
    compensationCalcFn?: (compensations?: ClaimCompensation[]) => number;
}

export class CompensationsTable extends React.Component<ICompensationsTableProps, {}> {

    // @ToDo - `CompensationsTable` should be improved with icons
    public render(): React.ReactNode {
        return (
            <SummaryCard theme={2}>
                <SummaryCard.Header>
                    <SummaryCard.HeaderText>
                        <div style={{textAlign: "left"}}><B><FormattedMessage id="general.decision.compensation.title"/></B></div>
                    </SummaryCard.HeaderText>
                    <SummaryCard.HeaderIcon
                        component={<IconCheckMarkV2 color={theme.colors.primary2}/>}/>
                </SummaryCard.Header>
                <SummaryCard.Body>
                    {this.renderCompensationList(this.props.compensations)}
                    {this.renderCompensationTotal(this.props.compensations)}
                    {this.props.children}
                </SummaryCard.Body>
            </SummaryCard>
        );
    }

    private renderCompensationList(compensations?: ClaimCompensation[]): React.ReactNode {
        return compensations?.map((row: ClaimCompensation, index: number) => {
            return (
                Number(row.amount) ?
                    <DescriptionList.Row key={`compensation_${index}`} separated={true}>
                        {this.renderCompensationRow(row)}
                    </DescriptionList.Row>
                    :
                    <></>
            )
        })
    }

    private renderCompensationTotal(compensations?: ClaimCompensation[]): React.ReactNode {
        const {compensationCalcFn} = this.props;
        return (
            <DescriptionList.Row>
                <DescriptionList.Definition>
                    <FormattedMessage id="general.decision.compensation.total.title"/>
                </DescriptionList.Definition>
                <DescriptionList.Definition fontWeight={theme.fontWeights.semiBold}>
                    <DescriptionList.Price
                        background
                        fontWeight={theme.fontWeights.semiBold}
                        component={<FormattedPrice
                            suffixId={"general.price.suffix"}
                            value={Math.round(compensationCalcFn ? compensationCalcFn(compensations) : getCompensationSum(compensations)!)}/>}
                    />
                </DescriptionList.Definition>
            </DescriptionList.Row>
        );
    }

    private renderCompensationRow(compensation: ClaimCompensation): React.ReactNode {
        const defaultColor = '#22252B';
        const color = {
            color: defaultColor // @ToDo - should be added in FUI colors as Fun/Grey 5 and used as `theme.colors.black`, when defined in FUI colors.
        };

        if (compensation.color) {
            color.color = '#003E80'// @ToDo - currently hardcoded and specified compensation.color will be ignored; Should be used as `theme.colors[compensation.color]` as Fun/Primary 4, when defined in FUI colors.
        }

        // @ToDo - `FUI Tooltip component icon` should be improved to color change takes effect
        return (
            <>
                <DescriptionList.Definition style={{color: defaultColor}}
                                            fontWeight={compensation.color ? theme.fontWeights.semiBold : theme.fontWeights.medium}>
                    <FormattedMessage id={compensation.name}/>
                    {<Tooltip tooltip={compensation.tooltipKey} style={{color: defaultColor}}/>}
                </DescriptionList.Definition>
                <DescriptionList.Definition style={{color: color.color}}>
                    <DescriptionList.Price
                        fontWeight={compensation.color ? theme.fontWeights.semiBold : undefined}
                        component={<FormattedPrice
                            suffixId={"general.price.suffix"}
                            value={Math.round(Number(compensation.amount)!)}/>}
                    />
                </DescriptionList.Definition>
            </>
        );
    }
}

import * as React from "react";
import isEmpty from "lodash/isEmpty";
import {
    defaultTheme as theme,
    DescriptionList,
    EnvelopeIcon,
    IconCalendar,
    IconCardV2,
    IconPersonNew,
    Spacing,
    SummaryCard
} from "@folksam-digital/ui"
import {SuccessHeaderTableElement} from "./SuccessHeaderTableElement";
import {CmsContext} from "../../../../cms";
import {injectIntl, WrappedComponentProps} from "react-intl";

interface ISuccessPageHeaderTableProps {
    policyNumber?: string;
    date?: Date | string;
    textDate?: string;
    dateTermId?: string;
    email?: string;
    clearingNumber?: string;
    bankAccountNumber?: string;
    claimNumber?: string;
    groupNumber?: string;
}

class SuccessHeaderTableInternal extends React.Component<ISuccessPageHeaderTableProps & WrappedComponentProps> {
    public static contextType = CmsContext;

    public render() {
        return (
            <React.Fragment>
                <Spacing bottom={"sm"}/>
                <SummaryCard>
                    <DescriptionList>
                        {this.props.policyNumber && <SuccessHeaderTableElement text={this.props.policyNumber}
                                                                               termId={"general.success.successTable.insuranceNumber"}
                                                                               icon={<IconPersonNew/>}/>}
                        {this.props.groupNumber && <SuccessHeaderTableElement text={this.props.groupNumber}
                                                                              termId={"general.success.successTable.groupNumber"}
                                                                              icon={<IconPersonNew/>}/>}
                        {this.props.date && <SuccessHeaderTableElement date={this.props.date}
                                                                       termId={this.props.dateTermId ? this.props.dateTermId : "general.success.successTable.date"}
                                                                       icon={<IconCalendar/>}/>}
                        {this.props.textDate &&
                            <SuccessHeaderTableElement text={this.props.intl.formatMessage({id: this.props.textDate})}
                                                       termId={this.props.dateTermId ? this.props.dateTermId : "general.success.successTable.date"}
                                                       icon={<IconCalendar/>}/>}
                        {!isEmpty(this.props.claimNumber) && <SuccessHeaderTableElement text={this.props.claimNumber}
                                                                                        termId={"general.success.successTable.claimNumber"}
                                                                                        icon={<IconPersonNew/>}/>}
                        {this.props.clearingNumber && <SuccessHeaderTableElement text={this.props.clearingNumber}
                                                                                 termId={"general.success.successTable.clearingNumber"}
                                                                                 icon={<IconPersonNew/>}/>}
                        {this.props.bankAccountNumber && <SuccessHeaderTableElement text={this.props.bankAccountNumber}
                                                                                    termId={"general.success.successTable.bankAccountNumber"}
                                                                                    icon={<IconCardV2
                                                                                        style={{fill: theme.colors.senary21}}/>}/>}
                        {this.props.email && <SuccessHeaderTableElement text={this.props.email}
                                                                        termId={this.context.folderName === "claim" ?
                                                                            "general.success.successTable.claim.email" :
                                                                            "general.success.successTable.email"}
                                                                        icon={<EnvelopeIcon
                                                                            style={{fill: theme.colors.senary21}}/>}/>}
                    </DescriptionList>
                </SummaryCard>
                <Spacing bottom={"sm"}/>
            </React.Fragment>
        );
    }
}

const SuccessHeaderTable = injectIntl(SuccessHeaderTableInternal);

export {SuccessHeaderTable};

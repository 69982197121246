import {
    Breakpoints,
    CallbackNameList,
    ComponentType,
    Constants,
    DeepPartial,
    DependencyRelationTypes,
    Field,
    FieldGroup,
    InputMode,
    pathof,
    Questionnaire,
    ValidationFunctionNameList,
    yearMonthDayFormat,
    yearMonthFormat
} from "@folksam-digital/model";
import {accidentAndIllness} from "../questionSchemaOverride/accidentAndIllness";
import {shoppingCart} from "../questionSchemaOverride/shoppingCart";
import {individualChild} from "../questionSchemaOverride/individualChild";
import IndividualChildJourneyModel from "@folksam-digital/model/lib/model/IndividualChildJourneyModel";
import {ICmsParams} from "../IGenerator";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";

export enum ModelKeys {
    id = "id",
    value = "value",
    contact = "contact",
    option = "option",
    upptecData = "upptecData",
}

export interface IComponentModelMapping {
    [key: string]: ModelKeys;
}

export interface IComponentMapping {
    [key: number]: DeepPartial<Field>;
}

export interface IFieldSections {
    id: number;
    parentId: number | null;
    field?: Field | FieldGroup;
    question: Questionnaire;
}

export interface IQuestionSchemaOverride {
    [questionId: number]: {
        additionalHeaderTitle?: boolean;
        showBackgroundColor?: boolean;
        showBorder?: boolean;
        inputMode?: InputMode;
    };
}

export const questionMapping = (cmsParams: ICmsParams): IComponentMapping => ({
    [Constants.Questionnaire.QuestionTypes.IsHealthDeclarationRequired]: {
        component: ComponentType.Hidden,
    },
    [Constants.Questionnaire.QuestionTypes.AccidentAndIllness.HealthCareProviderQuestionId]: {
        component: ComponentType.TextBox,
        maxLength: 100
    },
    [Constants.Questionnaire.QuestionTypes.AccidentAndIllness.SignedDateQuestionId]: {
        component: ComponentType.Hidden,
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.SignedDateQuestionId]: {
        component: ComponentType.Hidden,
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.ChildDischargedQuestionId]: {
        component: ComponentType.ToggleButton,
        onChange: CallbackNameList.blockQuestionnaire,
        required: true
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateQuestionId]: {
        component: ComponentType.Date,
        componentMetadata: {
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder"),
            format: yearMonthDayFormat
        } as any,
        validation: {
            function: ValidationFunctionNameList.validateStartDateEndDate,
            params: {
                dependencies: [
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateLineId}_policy_questionnaire`,
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateLineId}_policy_questionnaire`
                ],
                fields: {
                    startDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateLineId}`).value),
                    endDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateLineId}`).value)
                },
                errors: {
                    startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `questionnaire.${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateQuestionId}.error.startDateGreaterThanEndDate`)
                }
            },
        } as any
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateQuestionId]: {
        component: ComponentType.Date,
        componentMetadata: {
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder"),
            format: yearMonthDayFormat
        } as any,
        validation: {
            function: ValidationFunctionNameList.validateStartDateEndDate,
            params: {
                dependencies: [
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateLineId}_policy_questionnaire`,
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateLineId}_policy_questionnaire`
                ],
                fields: {
                    startDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareStartDateLineId}`).value),
                    endDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateLineId}`).value)
                },
                errors: {
                    startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `questionnaire.${Constants.Questionnaire.QuestionTypes.IndividualChild.HomeHealthCareEndDateQuestionId}.error.startDateGreaterThanEndDate`)
                }
            },
        } as any
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateQuestionId]: {
        component: ComponentType.Date,
        componentMetadata: {
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder"),
            format: yearMonthDayFormat
        } as any,
        validation: {
            function: ValidationFunctionNameList.validateStartDateEndDate,
            params: {
                dependencies: [
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateLineId}_policy_questionnaire`,
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateLineId}_policy_questionnaire`
                ],
                fields: {
                    startDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateLineId}`).value),
                    endDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateLineId}`).value)
                },
                errors: {
                    startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `questionnaire.${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateQuestionId}.error.startDateGreaterThanEndDate`)
                }
            },
        } as any
    },
    [Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateQuestionId]: {
        component: ComponentType.Date,
        componentMetadata: {
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder"),
            format: yearMonthDayFormat
        } as any,
        validation: {
            function: ValidationFunctionNameList.validateStartDateEndDate,
            params: {
                dependencies: [
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateLineId}_policy_questionnaire`,
                    `questionnaire_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateLineId}_policy_questionnaire`
                ],
                fields: {
                    startDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedStartDateLineId}`).value),
                    endDate: pathof(IndividualChildJourneyModel.policy.questionnaire.prop(`_${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateLineId}`).value)
                },
                errors: {
                    startDateGreaterThanEndDate: CmsHelper.withPrefix(cmsParams, `questionnaire.${Constants.Questionnaire.QuestionTypes.IndividualChild.ApplicationGrantedEndDateQuestionId}.error.startDateGreaterThanEndDate`)
                }
            },
        } as any
    },
});

export const inputTypeMapping: IComponentMapping = {
    [Constants.Questionnaire.InputTypes.TextBox]: {
        component: ComponentType.TextBox,
        maxLength: 100
    },
    [Constants.Questionnaire.InputTypes.ToggleButton]: {
        component: ComponentType.ToggleButton,
        componentMetadata: {} as any
    }
};

export const getInputTypeMapping = (inputTypeId: number, journeyName: string): DeepPartial<Field> => {
    const field = inputTypeMapping[inputTypeId];

    if (inputTypeId === Constants.Questionnaire.InputTypes.ToggleButton && journeyName === Constants.Journey.IndividualChild.Id) {
        field.onChange = CallbackNameList.removeRedundantHealthQuestionAnswers
    }

    return field;
};

export const answerHeaderMapping = (cmsParams: ICmsParams): IComponentMapping => ({
    [Constants.Questionnaire.AnswerHeader.Textarea]: {
        component: ComponentType.Textarea,
        maxLength: 999,
        errorMessages: {
            maxLength: "general.form.error.maxLength999Chars"
        }
    },
    [Constants.Questionnaire.AnswerHeader.TextareaFixedCharLength]: {
        component: ComponentType.Textarea,
        maxLength: 300,
        errorMessages: {
            pattern: "general.form.error.maxLength300Chars"
        }
    },
    [Constants.Questionnaire.AnswerHeader.HeightInput]: {
        component: ComponentType.TextBox,
        maxLength: 9,
        onChange: CallbackNameList.removePriceIncreaseNotification,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.WeightInput]: {
        component: ComponentType.TextBox,
        maxLength: 9,
        onChange: CallbackNameList.removePriceIncreaseNotification,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DatePickerLong]: {
        component: ComponentType.Date,
        errorMessages: {
            pattern: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.error.pattern"),
        },
        componentMetadata: {
            format: yearMonthDayFormat,
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder")
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DateLong]: {
        component: ComponentType.Date,
        errorMessages: {
            pattern: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.error.pattern"),
        },
        componentMetadata: {
            format: yearMonthDayFormat,
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.dayMonthYear.placeholder")
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DateShort]: {
        component: ComponentType.Date,
        errorMessages: {
            pattern: CmsHelper.withPrefix(cmsParams, "questionnaire.date.monthYear.error.pattern"),
        },
        componentMetadata: {
            maxDetail: "year",
            format: yearMonthFormat,
            placeholder: CmsHelper.withPrefix(cmsParams, "questionnaire.date.monthYear.placeholder")
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DropDown]: {
        component: ComponentType.DropDown,
    } as any,
    [Constants.Questionnaire.AnswerHeader.DropDownApgarpoints]: {
        component: ComponentType.DropDown,
        componentMetadata: {
            placeholder: "general.form.dropdown.pleaseSelect.placeholder"
        }
    },
    [Constants.Questionnaire.AnswerHeader.DropDownApgarpointsNew]: {
        component: ComponentType.DropDown,
        componentMetadata: {
            placeholder: "general.form.dropdown.pleaseSelect.placeholder"
        }
    },
    [Constants.Questionnaire.AnswerHeader.NumericTextBoxMax52]: {
        component: ComponentType.TextBox,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.NumericTextBoxMax999]: {
        component: ComponentType.TextBox,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.NumericTextBoxMin100Max999]: {
        component: ComponentType.TextBox,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.DecimalTextBoxMax999]: {
        component: ComponentType.TextBox,
        componentMetadata: {
            inputMode: InputMode.decimal,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    },
    [Constants.Questionnaire.AnswerHeader.NumericTextBoxMax6]: {
        component: ComponentType.TextBox,
        componentMetadata: {
            inputMode: InputMode.numeric,
            breakpoints: {
                [Breakpoints.md]: 11
            }
        } as any
    }
});

export const componentModelMapping: IComponentModelMapping = {
    [ComponentType.ToggleButton]: ModelKeys.id,
    [ComponentType.Hidden]: ModelKeys.id,
    [ComponentType.TextBox]: ModelKeys.value,
    [ComponentType.Textarea]: ModelKeys.value,
    [ComponentType.DropDown]: ModelKeys.id,
    [ComponentType.Date]: ModelKeys.value,
    [ComponentType.NumberInput]: ModelKeys.value,
    [ComponentType.CurrencyInputField]: ModelKeys.value,
    [ComponentType.CheckBox]: ModelKeys.id,
    [ComponentType.DropDownMultiSelect]: ModelKeys.option,
    [ComponentType.UpptecSearchInput]: ModelKeys.upptecData,
    [ComponentType.GooglePlace]: ModelKeys.value,
    [ComponentType.PersonSsnNumberContainerSingle]: ModelKeys.contact,
};

export const conditionOperatorTypes = {
    [DependencyRelationTypes.AND]: "&&",
    [DependencyRelationTypes.OR]: "||"
};

export const questionSchemaOverride: IQuestionSchemaOverride = {
    ...accidentAndIllness,
    ...shoppingCart,
    ...individualChild
};

import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import moment from "moment";
import {IBreakPoint} from "../../layout/helpers";
import get from "lodash/get";
import * as React from "react";
import {formattedPartsToDateLocale} from "../../../../Helpers/formattedPartsToDateLocale";
import {DateActions, IDateFormats} from "@folksam-digital/model";

export interface IDateModify {
    action: DateActions,
    unit: moment.DurationInputArg2,
    value: number
}

interface IRefs {
    refInput: () => HTMLInputElement | undefined;
    refCalendar: () => React.RefObject<HTMLElement>;
}

export interface IRectangleParams {
    tooltipRect?: DOMRect;
    bodyRect?: DOMRect;
    messageRect?: DOMRect;
    bodyWidth?: number;
    offsetTop?: number;
}

export interface IDateTimeComponentMetadataBase {
    disabled?: boolean;
    // DateTime, Date
    maxDetail?: string;
    minDetail?: string;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    maxDateAsActiveStartDate?: boolean;
    classNames?: string;
    showActionTodayButton?: boolean;
    showActionClearButton?: boolean;
    showActionCloseButton?: boolean;
    maxDatePath?: string;
    minDatePath?: string;
    dateModify?: IDateModify;
    disableInputBasedOnMinDate?: boolean;
    disableInputBasedOnMaxDate?: boolean;
    // Date, Time
    format?: IDateFormats;
    placeholder?: string;
    breakpoints?: IBreakPoint;
    inputMode?: string;
}

export interface IDateTimeComponentStateBase {
    date: Date | undefined;
    disabled: boolean;
    // DateTime, Date
    input?: string;
    isCalendarVisible?: boolean;
    minimalDate?: moment.Moment | undefined;
    maximalDate?: moment.Moment | undefined;
    rectangleParams?: IRectangleParams;
}

export interface IDateTimeComponentBaseProps<TValue, TMetadata> extends IInputComponentProps<TValue, TMetadata> {
}

export abstract class DateTimeBase<TValue, TMetadata extends IDateTimeComponentMetadataBase,
    TState extends IDateTimeComponentStateBase,
    TProps extends IDateTimeComponentBaseProps<TValue, TMetadata> = IInputComponentProps<TValue, TMetadata>> extends InputComponentBase<TValue, TMetadata, TState, TProps> {
    protected body: HTMLElement;
    protected readonly refObj: React.RefObject<IRefs>;

    protected constructor(props: TProps) {
        super(props);
        this.body = document.getElementsByTagName('body')[0];
        this.refObj = React.createRef();

        moment.locale(props.intl.locale);
        this.setIsCalendarVisible = this.setIsCalendarVisible.bind(this);
    }

    // DateTime, Date, Time
    protected abstract getDateFormat(): string | undefined;

    // DateTime, Date
    public getRefInputParams(): IRectangleParams {
        let values: IRectangleParams = {};

        if (this.refObj && this.refObj.current && this.refObj.current.refInput()) {
            const refInput = this.refObj.current.refInput() as HTMLElement;
            const tooltipRect = refInput.getBoundingClientRect();
            const scrollY = window.scrollY ? window.scrollY : window.pageYOffset;
            const bodyRect = this.body.getBoundingClientRect();

            values = {
                tooltipRect,
                bodyRect,
                bodyWidth: bodyRect.width,
                offsetTop: scrollY + tooltipRect.top
            };
        }

        return values;
    }

    public setIsCalendarVisible(value: boolean) {
        let data = {};

        if (value) {
            data = this.getRefInputParams();
        }

        this.setState((state) => {
            return {
                isCalendarVisible: value,
                rectangleParams: {
                    ...state.rectangleParams, ...data
                }
            };
        });
    }

    public updateCalendarRectangleParams(prevProps: IDateTimeComponentBaseProps<TValue, TMetadata>, prevState: IDateTimeComponentStateBase): void {
        if (!prevState.isCalendarVisible && this.state.isCalendarVisible) {
            const values: IRectangleParams = {};

            if (this.state.isCalendarVisible && this.refObj?.current?.refCalendar()?.current) {
                const refCalendar = this.refObj.current.refCalendar().current as HTMLElement;
                values.messageRect = refCalendar.getBoundingClientRect();
            }

            this.setState((state) => {
                return {
                    rectangleParams: {...state.rectangleParams, ...values}
                }
            });
        }
    }

    protected updateDateConfig() {
        const {
            minDate,
            maxDate,
            minDatePath,
            maxDatePath,
            dateModify,
            disabled,
            disableInputBasedOnMinDate,
            disableInputBasedOnMaxDate
        } = this.metadata;

        let isDisabled: boolean = false;
        const minimalDate = this.getMinDate(minDate, minDatePath);
        const maximalDate = this.getMaxDate(maxDate, maxDatePath, dateModify);

        if ((!minimalDate && disableInputBasedOnMinDate) || (!maximalDate && disableInputBasedOnMaxDate)) {
            isDisabled = true
        }

        if (disabled) {
            isDisabled = disabled
        }

        this.setState({
            minimalDate: minimalDate,
            maximalDate: maximalDate,
            disabled: isDisabled
        });
    }

    protected getMinDate(sourceDate?: moment.Moment, path?: string) {
        let tempDate = sourceDate;

        if (path) {
            tempDate = get(this.context.data, path);

            // when path has no value and schema had sourceDate(minDate)
            if (!tempDate && sourceDate) {
                tempDate = sourceDate;
            }
        }
        return tempDate;
    }

    protected getMaxDate(sourceDate?: moment.Moment, path?: string, dateModify?: IDateModify) {
        let tempDate = sourceDate;
        const {minDate} = this.metadata;

        if (path) {
            tempDate = get(this.context.data, path);

            if (dateModify) {
                // when path has no value and schema has minDate prop specified
                if (!tempDate && minDate) {
                    tempDate = minDate;
                }
                if (dateModify.action === DateActions.Add) {
                    tempDate = moment.utc(tempDate).add(dateModify.value, dateModify.unit).format(this.getDateFormat()) as any;
                } else if (dateModify.action === DateActions.Subtract) {
                    tempDate = moment.utc(tempDate).subtract(dateModify.value, dateModify.unit).format(this.getDateFormat()) as any;
                }
            }
        }
        return tempDate;
    }

    protected getActiveStartDate(): Date | undefined {
        if (this.state.date) {
            return undefined;
        } else if (this.state.minimalDate) {
            const {maxDate, maxDateAsActiveStartDate} = this.metadata;
            if (maxDate && maxDateAsActiveStartDate && this.state.maximalDate) {
                return this.dateStringOrMomentToDate(this.state.maximalDate)
            }
            return this.dateStringOrMomentToDate(this.state.minimalDate)
        } else if (this.state.maximalDate) {
            return this.dateStringOrMomentToDate(this.state.maximalDate)
        }
    }

    protected dateStringOrMomentToDate(date: moment.Moment | Date | undefined): Date {
        return moment.isMoment(date) ? date.toDate() : moment.utc(date).toDate();
    }

    // DateTime, Time
    protected getFormatMask(reactIntlFormat: Intl.DateTimeFormatOptions): string {
        const formattedParts = this.props.intl.formatters.getDateTimeFormat(this.props.intl.locale, reactIntlFormat).formatToParts();
        return formattedPartsToDateLocale(formattedParts);
    }
}

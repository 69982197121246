import * as React from "react";
import {withRouter} from "react-router-dom";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {Constants} from "@folksam-digital/model";
import {ConstrainWidth, Grid, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import ClaimSuccessBase, {IClaimSuccessBaseProps} from "./ClaimSuccessBase";
import {CatClaimJourney} from "@folksam-digital/model/lib/journey/claim/CatClaimJourney";
import {IOnTriggerDataLayerEvent} from "../../../../analytics/new/BaseAnalytics";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {FormattedMessage} from "react-intl";
import {CompensationsTable} from "../common/CompensationsTable";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

interface CatClaimSuccessProps extends IClaimSuccessBaseProps, IOnTriggerDataLayerEvent {

}

class CatClaimSuccessInternal extends ClaimSuccessBase<CatClaimJourney, CatClaimSuccessProps> {
    public static contextType = CmsContext;
    protected prefix = "claim.cat";

    constructor(props: CatClaimSuccessProps, context?: ICmsContext) {
        super(props, Constants.Journey.CatClaim.Id, context);

        this.getTotalCompensationValue = this.getTotalCompensationValue.bind(this);
    }

    public componentDidMount(): void {
        this.checkData(Constants.Journey.CatClaim.Id);
        this.triggerDataLayerEvent(Constants.Journey.CatClaim.Id);
    }

    public render() {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <FormContext.Provider value={{data: data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Claim}
                        journeyId={Constants.Journey.CatClaim.Id}
                        formData={{contact: data.informant}}
                        logOutUrl={Constants.Journey.CatClaim.backUrl}
                        navButton={CloseModalButton}
                        translations={{
                            header: "general.success.banner.heading",
                            subheader: this.isSTPClaim(data) ? CmsHelper.withPrefix(this.context, "success.stp.banner.subheading") : CmsHelper.withPrefix(this.context, "success.nostp.banner.subheading"),
                            headerText: <FormattedMessage id={`${this.prefix}.pageTitle.headerText`}/>
                        }}
                        doScroll={true}
                    >
                        {this.renderCompensationCard(data)}
                        {!this.isSTPClaim(data) && <SuccessHeaderMessageBox
                            message={`${this.prefix}.success.nostp.text.information`}/>}
                        <SuccessHeaderTable claimNumber={data.claimNumber}
                                            email={this.getContact()?.emailAddress}
                        />
                        {!this.isSTPClaim(data) && <Spacing type={"padding"} bottom={'sm'}/>}
                        {this.isSTPClaim(data) && <SuccessHeaderMessageBox
                            skipSpacingTop={true}
                            message={`${this.prefix}.success.stp.text.information`}/>}
                        {this.renderMyPagesButton()}
                    </SuccessHeaderLayout>
                    <ComponentBackgroundWrapper theme={1}>
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            {this.renderContactUsSection()}
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        )
    }

    private getTotalCompensationValue() {
        return this.data?.compensationTotalAmount ? this.data.compensationTotalAmount : 0;
    }

    private renderCompensationCard(data: CatClaimJourney): React.ReactNode {
        if (this.isSTPClaim(data)) {
            return (
                <ConstrainWidth>
                    <CompensationsTable compensations={data.compensations}
                                        compensationCalcFn={this.getTotalCompensationValue}/>
                </ConstrainWidth>
            );
        }
        return undefined;
    }
}

const CatClaimSuccess = flowRight(
    withCmsProvider("catClaim"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withRouter,
    withHistoryBackConstraint
)(CatClaimSuccessInternal);

export default CatClaimSuccess

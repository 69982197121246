import * as React from "react";
import {injectIntl, WrappedComponentProps} from "react-intl";
import get from "lodash/get";

import {
    withViewContext,
    AddonAccordion as AddonAccordionFUI,
    IconWinner,
    Badge,
    DisplayIcon
} from "@folksam-digital/ui";
import {IPanelComponentBaseProps, PanelComponentBase} from "./PanelComponentBase";
import {FormContext} from "../FormContext";
import {IIdSchema} from "../FormComponentBase";
import {IOnValidationError} from "../../../../../types/@sapiens-digital/comp-library/components/JourneyManager";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsContext, ICmsContext} from "../../../../cms";
import {Markdown} from "../../../Markdown";
import {IBreakPoint} from "../../layout/helpers";
import {defaultTheme} from "@folksam-digital/ui";

interface IAddonCardMetadata {
    properties: [];
    expandedPath?: string;
    keepObject?: { [key: string]: boolean };
    definition?: string;
    showRemoveButton?: boolean;
    showClearButtonWhenFieldWithValue?: string;
    closeText?: string;
    clearFormText?: string;
    editText?: string;
    removeText?: string;
    index?: string;
    pathFilter?: string;
    defaultValue?: any;
    padded?: boolean;
    removeSectionModalTitle?: string;
    fieldId?: string;
    stepName: string;
    schemaBaseName: string;
    deletePathFromStateOnHide?: boolean;
    forceScrollToFieldIdOnRemove?: boolean;
    shouldAddNewEntry?: boolean;
    pathToModel?: string;
    excludeClearButton?: boolean;
    pathToMaxEntries?: string;
    defaultSelected?: boolean;

    // ******- AddonCard specific -*******
    coverId: number;
    coverIdPath: string;// added to use if coverId can change between type of journey, like HomeStudent(IHome, KHome). Only on Idit Schema.
    addOnId: string;
    prefix: string;
    breakpoints?: IBreakPoint;
    simple?: boolean; // to render without evaluate functionality
    withIcon?: boolean;
    expandableList?: any[];
    disabled?: boolean;
    disabledMessage?: string;
    expanded?: boolean;
    selectedPath: string;
    description?: string;
    heading?: string;
    subheading?: string;
}

interface IAccordionPanelInternalProps extends Omit<IPanelComponentBaseProps<void, IAddonCardMetadata & WrappedComponentProps>, "formContext"> {
    formContext: {
        validateField: (fieldIdSchema: { $id: string }, uiSchema: any) => { stack: string }[];
        getCurrentStepErrorList: (idSchema: any, uiSchema: any) => Promise<IOnValidationError[]>;
    };
    idSchema: IIdSchema,
    classes: {
        [key: string]: string;
    },
}

interface IState {
    expanded?: boolean;
}

interface IPopOverConfig {
    isPopOverDisplayed: boolean;
    id?: string;
    text?: string;
}

class AddonCardPanelInternal extends PanelComponentBase<void, IAddonCardMetadata & WrappedComponentProps, IState, IAccordionPanelInternalProps> {
    public static contextType = FormContext;
    private readonly path: string;

    constructor(props: any, context?: any) {
        super(props, context);
        this.path = `expanded_${this.uiSchema.name}`;

        this.state = {
            expanded: false
        };

        this.onExpandedToggle = this.onExpandedToggle.bind(this);
    }

    private getExpandableList(expandableList: any) {
        return expandableList.map((listItem: any) => {
            return {
                name: this.props.intl.formatMessage({id: listItem.title}),
                content: <Markdown source={this.props.intl.formatMessage({id: listItem.content})}/>
            };
        });
    }

    public componentDidMount() {
        const {expanded} = this.metadata;

        this.setState({
            expanded
        })
    }

    private getSelectedState(): boolean {
        return get(this.context.data, this.metadata.selectedPath, false)
    }

    public onExpandedToggle() {
        this.setState({expanded: !this.state.expanded});
    }

    private getPopOverConfig(cmsContext: ICmsContext, addOnId: string): IPopOverConfig {
        const popOverConfig = {isPopOverDisplayed: false} as IPopOverConfig;
        const prefix = CmsHelper.getPrefix(cmsContext)

        const winnerPopOverText = this.props.intl.messages[`${prefix}.coverPlans.winner.popOver.${addOnId}`] as string;
        if (winnerPopOverText) {
            popOverConfig.isPopOverDisplayed = true;
            popOverConfig.id = addOnId;
            popOverConfig.text = winnerPopOverText;
        }

        return popOverConfig;
    }

    render() {
        const {intl} = this.props;
        const {
            coverIdPath,
            coverId,
            addOnId,
            withIcon,
            expandableList,
            disabled,
            description,
            defaultSelected,
            prefix,
            heading,
            subheading
        } = this.metadata;
        const coverIdCalc = coverIdPath ? get(this.context.data, coverIdPath, coverId) : coverId;
        const isSelected = defaultSelected || this.getSelectedState();

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const popoverConfig = this.getPopOverConfig(cmsContext, addOnId);
                    return (
                        <AddonAccordionFUI
                            id={coverIdCalc}
                            withIcon={withIcon}
                            badge={
                                popoverConfig.isPopOverDisplayed && <Badge
                                    bookmark
                                    type="offer"
                                    disableAnimation={true}
                                    icon={<DisplayIcon medium icon={<IconWinner/>}/>}
                                    text={popoverConfig.text}
                                    halfWidth
                                />
                            }
                            heading={intl.formatMessage({
                                id: heading ? heading : CmsHelper.withPrefixOrId({
                                    cmsContext,
                                    id: `${addOnId}.heading`,
                                    prefix
                                })
                            })}
                            subheading={intl.formatMessage({
                                id: subheading ? subheading : CmsHelper.withPrefixOrId({
                                    cmsContext,
                                    id: `${addOnId}.headingHelp`,
                                    prefix
                                })
                            })}
                            description={!!description &&
                                <div style={{fontFamily: defaultTheme.fonts.mono as any}}>
                                    <FormattedMarkdown
                                        messageKey={CmsHelper.withPrefixOrId({cmsContext, id: description, prefix})}
                                    />
                                </div>
                            }
                            expandableList={expandableList ? this.getExpandableList(expandableList) : undefined}
                            disabled={disabled}
                            expanded={this.state.expanded}
                            checked={isSelected}
                            onExpandedToggle={this.onExpandedToggle}
                        >
                            {this.props.properties?.map((p) => p.content)}
                        </AddonAccordionFUI>
                )}}
            </CmsContext.Consumer>
        );
    }
}

const AddonCardPanel = injectIntl(withViewContext(AddonCardPanelInternal));

export {AddonCardPanel};

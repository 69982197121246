import * as React from "react";
import {OutputComponentBase} from "./OutputComponentBase";
import {B, defaultTheme as theme} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {FormContext} from "../FormContext";
import {getHomeMessageValues, getMessageValues} from "./helpers";
import scrollIntoView from "scroll-into-view-if-needed";
import {IBreakPoint} from "../../layout/helpers";
import {InformationBoxContainer, ITheme} from "../containers/InformationBoxContainer";
import {Constants, LegacyCoverPlan, MarkdownListTypes, pathof} from "@folksam-digital/model";
import {injectIntl} from "react-intl";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import journeyBaseModel from "@folksam-digital/model/lib/model/JourneyBaseModel";
import get from "lodash/get";

interface IMetadata {
    classNames?: string;
    messageValues?: { [key: string]: string };
    scrollable?: boolean;
    colorTheme?: ITheme;
    priceNotification?: {
        messageKey: string;
        values?: { monthlyPremium: string };
    },
    withTooltipIcon?: string;
    breakpoints?: IBreakPoint;
    readMoreText?: string;
    modalHeader?: string;
    modalContent?: string;
    listType?: MarkdownListTypes;
    coverPlanIncludedTitleOverwrite?: boolean;// used to enable logic of overwriting title with included coverPlan title;
    sourceCoverPlansPath?: string;
}

export class InformationBoxInternal<T = {}> extends OutputComponentBase<void, IMetadata, T> {
    protected readonly infoBoxRef: React.RefObject<any>;
    public static contextType = FormContext;

    constructor(props: any) {
        super(props);

        this.infoBoxRef = React.createRef();

        this.scrollToThisElementCallback = this.scrollToThisElementCallback.bind(this);
        this.renderPriceNotification = this.renderPriceNotification.bind(this);
        this.extractMessageValues = this.extractMessageValues.bind(this);
    }

    public componentDidMount() {
        const {formContext} = this.props;
        const {scrollable} = this.metadata;
        if (formContext && scrollable) {
            this.context.pushToValidationScrollQueue(0, this.scrollToThisElementCallback);
            this.context.onScrollExecuted();
        }
    }

    private scrollToThisElementCallback() {
        scrollIntoView(this.infoBoxRef.current, {behavior: "smooth", block: "center"});
        this.context.removeFromValidationScrollQueue(0);
    }

    protected renderPriceNotification() {
        const { priceNotification, messageValues } = this.metadata;
        let values;
        let priceNotificationValues;

        if (messageValues && this.context) {
            values = getMessageValues(messageValues, this.context);
        }

        if (priceNotification?.values && this.context) {
            priceNotificationValues = getMessageValues(priceNotification.values, this.context);
            priceNotificationValues.monthlyPremium = Math.round(parseFloat(priceNotificationValues.monthlyPremium)).toString();
        }

        return priceNotification ? <div style={{
            borderRadius: theme.borderRadius.full,
            textAlign: "center",
            padding: `${theme.padding['2']} ${theme.padding['5']}`,
            minWidth: "10%",
            marginBottom: theme.margin['3'],
            backgroundColor: theme.colors.white,
        }}>
            <B style={{
                fontFamily: theme.fonts.serif,
                fontSize: theme.textSizes.base,
                fontWeight: theme.fontWeights.semiBold,
                color: theme.colors.primary1,
                lineHeight: theme.leading.normal
            }}><FormattedMarkdown messageKey={priceNotification.messageKey}
                                  messageValue={priceNotification.values ? priceNotificationValues : values}/></B>
        </div> : null;

    }

    protected extractMessageValues(cmsContext: ICmsContext) {
        const {messageValues, coverPlanIncludedTitleOverwrite} = this.metadata;

        if (messageValues && this.context) {
            if (!coverPlanIncludedTitleOverwrite && this.isCoverPlanIncluded()) {
                const values = getHomeMessageValues(messageValues, this.context, this.getCoverPlansPath());

                if (values["coverPlan"]) {
                    // overwrite with translated value
                    values["coverPlan"] = this.props.intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `coverPlans.${values["coverPlan"]}.title`)});

                    return values;
                }
            } else {
                return getMessageValues(messageValues, this.context);
            }
        }
    }

    getCoverPlansPath() {
        return this.metadata.sourceCoverPlansPath || pathof(journeyBaseModel.policy.premium.coverPlans);
    }

    private isCoverPlanIncluded(): boolean {
        const coverPlans: LegacyCoverPlan[] = get(this.context?.data, this.getCoverPlansPath(), []);

        return coverPlans.some(coverPlan => coverPlan?.included);
    }

    protected getTitle(cmsContext: ICmsContext): string {
        const {coverPlanIncludedTitleOverwrite} = this.metadata;
        if (coverPlanIncludedTitleOverwrite && this.isCoverPlanIncluded()) {
            const coverPlans = get(this.context?.data, this.getCoverPlansPath(), []) as LegacyCoverPlan[];
            const coverPlanId = coverPlans.find(coverPlan => coverPlan?.included)!.id;

            if (this.context.data.targetEnv === Constants.TargetEnvironment.Idit) {
                return CmsHelper.withPrefix(cmsContext, `informationBox.${Constants.TargetEnvironment.Idit}.coverPlan.${coverPlanId}.included.title`);
            } else {
                return CmsHelper.withPrefix(cmsContext, `informationBox.coverPlan.${coverPlanId}.included.title`);
            }
        }

        return this.schema.title;
    }

    public render() {
        const {priceNotification, colorTheme = "primary", withTooltipIcon, breakpoints, listType} = this.metadata;

        return(
            <InformationBoxContainer ref={this.infoBoxRef} breakpoints={breakpoints} classNames={this.metadata.classNames} theme={colorTheme} withTooltipIcon={withTooltipIcon}>
                {priceNotification && this.renderPriceNotification()}
                <div>
                    <CmsContext.Consumer>
                        {(cmsContext: ICmsContext) => (
                            <FormattedMarkdown messageKey={this.getTitle(cmsContext)}
                                               messageValue={this.extractMessageValues(cmsContext)}
                                               listType={listType}
                            />
                        )}
                    </CmsContext.Consumer>
                </div>
            </InformationBoxContainer>
        );
    }
}

const InformationBox = injectIntl(InformationBoxInternal);
export {InformationBox};

import * as React from "react";
import {withRouter} from "react-router";
import flowRight from "lodash/flowRight";
import isEmpty from "lodash/isEmpty";
import {
    B,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {CmsContext} from "../../../../cms";
import {FormattedMessage} from "react-intl";
import {Constants, pathof, RunningRaceJourney} from "@folksam-digital/model";
import {Template} from "../../../../components/journey/form/output/helpers";
import JourneySuccessBase from "./JourneySuccessBase";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {SummaryCardRowList} from "../../addOn/success/SummaryCardRowList";
import model from "@folksam-digital/model/lib/model/RunningRaceJourneyModel";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import DirectDebitButton from "../../../../components/journey/layout/Success/DirectDebitButton";
import {MyPagesButton} from "../../../../components/journey/layout/Success";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

class RunningRaceJourneySuccessInternal extends JourneySuccessBase<RunningRaceJourney> {
    public static contextType = CmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.RunningRace.Id);
        this.triggerDataLayerEvent(Constants.Journey.RunningRace.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        let coverPlanDefinition = "";
        if (data.policy.coverPlan === Constants.CoverPlan.RunningRace.Basic) {
            coverPlanDefinition = "{{#translate}} {{product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.title}} {{/translate}} {{#translate}} {{product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.scopeBasic}} {{/translate}}";
        } else if (data.policy.coverPlan === Constants.CoverPlan.RunningRace.Extended) {
            coverPlanDefinition = "{{#translate}} {{product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.title}} {{/translate}} {{#translate}} {{product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.scopeBig}} {{/translate}}";
        }

        const summaryCardList = [
            {
                term: <B><FormattedMarkdown messageKey="general.appliesTo.title"/></B>,
                definition: <span>{data.contact.firstName} {data.contact.lastName}</span>
            },
            {
                term: <B><FormattedMarkdown messageKey="general.review.scope.title"/></B>,
                definition: <Template
                    template={coverPlanDefinition}
                    data={data}
                />
            },
            {
                term: <B><FormattedMarkdown messageKey="general.startDate.title"/></B>,
                definition: <FormattedMarkdown
                    messageKey="product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.startDate.notification"/>
            },
            {
                term: <B><FormattedMarkdown
                    messageKey="product.runningRace.reviewAndSubmit.insurance.runningRaceInsurance.annualPrice"/></B>,
                definition:
                    <Template
                        template={`{{#formatNumber}} {{${pathof(model.policy.premium.yearlyPremium)}}} {{/formatNumber}} {{#translate}} {{general.yearlyPremium.suffix}} {{/translate}}`}
                        data={data}
                    />
            }
        ];

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.RunningRace.Id}
                    formData={{contact: data.contact}}
                    backUrl={Constants.Journey.RunningRace.backUrl}
                    navButton={CloseLink}
                    translations={{
                        header: "general.success.banner.heading",
                        subheader: "product.runningRace.success.banner.subheading",
                        headerText: <FormattedMessage id={`product.runningRace.pageTitle.headerText`} />
                    }}
                    doScroll={true}
                >
                    <SuccessHeaderMessageBox
                        message="product.runningRace.success.description.coverDatePremium"
                        formData={{
                            coverPlan: this.context.catalog.messages[`product.runningRace.success.coverPlan.${data.policy.coverPlan}`],
                            startDate: new Date(data.policy.startDate!),
                            yearlyPremium: data.policy?.premium?.yearlyPremium,
                            email: data.contact.emailAddress
                        }}
                    />
                    <SuccessHeaderTable email={data.contact.emailAddress} policyNumber={data?.policy?.policyNumber}/>
                    {this.isDirectDebitFormNeeded() &&
                        <DirectDebitButton handleDirectDebitRedirect={this.handleDirectDebitRedirect}/>}
                    <Spacing type={"padding"} bottom={'4'}/>
                    <MyPagesButton/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" top={"8"}/>
                                        <SummaryCard
                                            theme={this.summaryCardThemeID}
                                        >
                                            <SummaryCard.Header>
                                                <SummaryCard.HeaderText>
                                                    <FormattedMessage id='product.runningRace.success.insurance.name'/>
                                                </SummaryCard.HeaderText>
                                                <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                            </SummaryCard.Header>

                                            <SummaryCard.Body>
                                                <DescriptionList themeId={Constants.Themes.LighterBlue}>
                                                    <SummaryCardRowList list={summaryCardList}/>
                                                </DescriptionList>
                                            </SummaryCard.Body>
                                        </SummaryCard>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const RunningRaceJourneySuccess = flowRight(
    withCmsProvider("runningRace"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint
)(RunningRaceJourneySuccessInternal);

export default RunningRaceJourneySuccess;

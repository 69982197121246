import * as React from "react";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import findIndex from "lodash/findIndex";
import some from "lodash/some";
import omit from "lodash/omit";
import castArray from "lodash/castArray";
import {
    ShoppingCartContact,
    InsuredPersonContact,
    InsuredPersonTypes,
    PolicySearchResult,
    DeepPartial,
    IGetOfferedExistingPolicies,
    GetOfferedExistingPoliciesRequest,
    GetOfferedExistingPoliciesResponse
} from "@folksam-digital/model";
import {Spacing, defaultTheme, P, Grid} from "@folksam-digital/ui";
import {IMetadata, SsnInput as SsnInputComponent} from "../input/SsnInput";
import {SsnFormatter} from "@folksam-digital/services";
import {IShoppingCartService, IUserService} from "../../../../services";
import {container} from "../../../../inversify.config";
import {Types} from "../../../../Types";
import ProductSelectionWidget, {IMetadata as IProductSelectionWidgetMetadata} from "./ProductSelectionWidget";
import {ProductCardWidgetTitle} from "../output";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import {AddButton} from "../../../journey/form";
import {IInputComponentProps, InputComponentBase} from "../../../journey/form/input/InputComponentBase";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {ICmsContext} from "../../../../cms";
import {withCmsContextConsumer} from "../../../../cms/withCmsContextConsumer";
import {IntlShape, injectIntl} from "react-intl";
import flowRight from "lodash/flowRight";
import get from "lodash/get";
import {validateComplexSsn} from "../../../../Helpers/validation/isValidSsn";
import {withFormContext} from "../../../journey/form/withFormContext";
import {UuidGenerator} from "../../../../Helpers/UuidGenerator";

type TChildSsnInputMetadata = IMetadata & IProductSelectionWidgetMetadata;

export interface IChildrenSsnInputContainerState {
    values: (DeepPartial<ShoppingCartContact> & {id: string})[];
    errors: string[];
    isLoading: boolean;
}

interface IChildrenSsnInputContainerProps {
    cmsContext: ICmsContext;
    intl: IntlShape;
}

enum ResponseStatus {Success, Error, StopRequests}

type TContactResponse = DeepPartial<InsuredPersonContact> | IErrorResponse;

interface IErrorResponse {
    status: ResponseStatus;
    error?: string;
}

interface IContactMapResponse {
    values: DeepPartial<ShoppingCartContact>[];
    errors: string[];
}

interface ILoadContactDataAndOffers extends Pick<GetOfferedExistingPoliciesResponse, "messageCorrelationId"> {
    contacts: TContactResponse[];
    contactOffers: IGetOfferedExistingPolicies[];
}

class ChildrenSsnInputContainer extends InputComponentBase<any, TChildSsnInputMetadata, IChildrenSsnInputContainerState, IChildrenSsnInputContainerProps & IInputComponentProps<any, TChildSsnInputMetadata>> {
    public static contextType = FormContext;
    protected shoppingCartService: IShoppingCartService;
    protected userService: IUserService;

    constructor(props: IChildrenSsnInputContainerProps & IInputComponentProps<any, TChildSsnInputMetadata>, context: IFormContext) {
        super(props, context);
        this.loadContactAndOfferedPolicies = this.loadContactAndOfferedPolicies.bind(this);
        this.getFieldError = this.getFieldError.bind(this);
        this.isFieldInvalid = this.isFieldInvalid.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onAddMoreChildren = this.onAddMoreChildren.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.handleShowPriceClick = this.handleShowPriceClick.bind(this);
        this.prepareContactRequest = this.prepareContactRequest.bind(this);
        this.shoppingCartService = container.get<IShoppingCartService>(Types.ShoppingCartService);
        this.userService = container.get<IUserService>(Types.UserService);

        this.state = {values: [], errors: [], isLoading: false};
    }

    public componentDidMount(): void {
        let data = [{contact: {}}];

        if (!isEmpty(this.props.formData)) {
            data = this.props.formData;
        }

        this.setState({values: this.assignIdToValues(data)});
    }

    private assignIdToValues(values: DeepPartial<ShoppingCartContact>[]): IChildrenSsnInputContainerState["values"] {
        return values.map(value => ({
            id: UuidGenerator.generate(),
            ...value
        }));
    }

    public render() {
        return (
            <div>
                <SsnInputComponent
                    {...this.props}
                    isLoading={this.state.isLoading}
                    values={this.state.values}
                    getError={this.getFieldError}
                    isFieldInvalid={this.isFieldInvalid}
                    onValueChange={this.onChange}
                    onClick={this.handleShowPriceClick}
                    onRemove={this.onRemove}
                    showBorder={true}
                    addButton={
                        <Spacing type={"margin"} bottom={"sm"}>
                            <AddButton
                                {...this.props}
                                schema={{
                                    ...this.props.schema,
                                    title: CmsHelper.withPrefix(this.props.cmsContext, "childrenContactDetails.addMoreChildren.title")
                                }}
                                onClick={this.onAddMoreChildren}
                            />
                        </Spacing>
                    }
                />
                <Spacing type={"margin"} top={"md"} bottom={"sm"}>
                    {this.renderProductListAndPbbMessage(this.state.values)}
                </Spacing>
            </div>
        );
    }

    private renderProductListAndPbbMessage(values: DeepPartial<ShoppingCartContact>[]) {
        let showPbbMessage = false;
        const componentList = values.map((children: DeepPartial<ShoppingCartContact>, idx: number) => {
            const contact = children.contact;
            const offers = children.offers;

            if (!showPbbMessage && offers) {
                showPbbMessage = offers.length > 0;
            }

            if (contact && contact.contactLoaded && !contact.isProtected) {
                const {offersTitleMessage, offersSubtitleMessage} = this.metadata;
                return (
                    <React.Fragment key={`${contact?.ssn}`}>
                        <ProductCardWidgetTitle
                            firstName={contact.firstName!}
                            lastName={contact.lastName!}
                            titleMessage={offersTitleMessage || ""}
                            subtitleMessage={offersSubtitleMessage}
                        />
                        <ProductSelectionWidget
                            {...omit(this.props, ["formData"])}
                            index={idx}
                            key={`childOfferSelection${idx}`}
                            uiSchema={{
                                ...this.props.uiSchema,
                                componentMetadata: {
                                    ...this.metadata,
                                    person: contact
                                }
                            }}
                            name={`childOfferSelection${idx}`}
                            formData={offers}
                            onChange={(changedOffers: PolicySearchResult[]) => this.onProductListUpdate(changedOffers, idx)}
                        />
                    </React.Fragment>
                );
            } else {
                return <React.Fragment key={idx}/>;
            }
        });

        if (showPbbMessage) {
            componentList.push(
                <Grid key="pbbMessage">
                    <Grid.Col xs={12} md={12}>
                        <P style={{color: defaultTheme.colors.senary2}}>
                            <FormattedMarkdown
                                messageKey={CmsHelper.withGroupCommonPrefix("priceBaseAmountsInfoText")}/>
                        </P>
                    </Grid.Col>
                </Grid>
            );
        }

        return componentList;
    }

    public onProductListUpdate(productList: PolicySearchResult[], idx: number): any {
        const values = this.state.values;
        const data = cloneDeep(this.props.formData);

        if (values[idx]) {
            values[idx].offers = productList;
        }

        this.setState({values}, () => this.props.onChange(merge([], data, this.state.values)));
    }

    private async onChange(idx: number, value: string) {
        const values = this.state.values;
        const data = cloneDeep(this.props.formData);

        if (values[idx]) {
            values[idx].contact = values[idx].contact || {};
            values[idx].contact!.ssn = value;
            values[idx].contact!.contactChanged = true;

            const errors = this.state.errors;
            if (!isEmpty(errors[idx])) {
                const error = this.validateFieldByIndex(idx);

                if (isEmpty(error)) {
                    delete errors[idx];
                } else {
                    errors[idx] = error;
                }
            }

            this.setState(
                {values, errors},
                () => this.props.onChange(merge([], data, this.state.values))
            );
        }
    }

    private onAddMoreChildren() {
        const errors = this.state.errors;
        let duplicatesExist: boolean = false;
        this.state.values.forEach((shoppingCartContact: DeepPartial<ShoppingCartContact>, idx: number) => {
            if (this.isDuplicate(shoppingCartContact.contact, idx)) {
                duplicatesExist = true;
                errors[idx] = CmsHelper.withPrefix(this.props.cmsContext, "childrenContactDetails.ssn.error.duplicate");
            }
        });

        if (duplicatesExist) {
            this.setState({errors});
            return;
        }
        this.setState({
            values: [...this.state.values, {id: UuidGenerator.generate(), contact: {ssn: ""}}]
        }, () => this.props.onChange(this.state.values));
    }

    private isDuplicate(childContact: any, idx: number) {
        let appearances: number = 0;
        this.state.values.forEach((shoppingCartContact) => {
            if (childContact.ssn !== "" && SsnFormatter.formatWithoutDash(shoppingCartContact.contact!.ssn) === SsnFormatter.formatWithoutDash(childContact.ssn)) {
                appearances++;
            }
        });
        return appearances > 1 && (idx > this.state.values.findIndex(shoppingCartContact => SsnFormatter.formatWithoutDash(shoppingCartContact.contact!.ssn) === SsnFormatter.formatWithoutDash(childContact.ssn)) ||
            this.isContactLoaded(childContact.ssn));
    }

    private isContactLoaded(ssn: any): boolean {
        return this.state.values.some((shoppingCartContact) => {
            return SsnFormatter.formatWithoutDash(shoppingCartContact.contact!.ssn) === SsnFormatter.formatWithoutDash(ssn) && shoppingCartContact.contact!.contactLoaded;
        });
    }

    private async onRemove(idx: number) {
        let values = this.state.values;
        let errors = this.state.errors;

        if (values[idx]) {
            if (values.length > 1) {
                values.splice(idx, 1);

                if (errors[idx]) {
                    errors.splice(idx, 1);
                }
            } else {
                values = [{id: UuidGenerator.generate(), contact: {}}];
                errors = [];
            }

            this.setState({values, errors});

            const path = `${this.uiSchema.model}.${idx}`;

            await this.context.updatePremium(undefined, undefined, {path, data: [], childIndex: idx});

            this.props.onChange(values);
        }
    }

    private async handleShowPriceClick(event: any): Promise<void> {
        try {
            this.setState({isLoading: true});

            const contactsAndOffers = await this.loadContactDataAndOffers();

            if (contactsAndOffers) {
                const {values, errors} = this.mapContactDataResponse(contactsAndOffers);
                this.setState({values: this.assignIdToValues(values), errors, isLoading: false}, () => this.props.onChange(values));
            }

            if (contactsAndOffers?.messageCorrelationId) {
                this.context.setMessageCorrelationId(contactsAndOffers.messageCorrelationId);
            }
        } catch (error) {
            console.error(error);
            this.context.onError();
        }
    }

    private async loadContactDataAndOffers(): Promise<ILoadContactDataAndOffers | undefined> {
        const contacts = this.loadContacts();
        const foundContacts = await Promise.all(contacts) as unknown as TContactResponse[] & IErrorResponse[];
        const validContacts: InsuredPersonContact[] = [];

        for (const loadedContactResponse of foundContacts) {
            if (loadedContactResponse?.status === ResponseStatus.StopRequests || foundContacts?.every((foundContact: any) => foundContact?.ssn && foundContact?.contactChanged === false)) {
                this.setState({isLoading: false});
                return undefined;
            }
            if ("externalContactNumber" in loadedContactResponse && loadedContactResponse.externalContactNumber) {
                validContacts.push(loadedContactResponse);
            }
        }

        const data = await this.loadContactAndOfferedPolicies(validContacts);

        if (!data) {
            return undefined;
        }

        return {
            contacts: foundContacts,
            contactOffers: castArray(data.getOfferedExistingPoliciesResponse),
            messageCorrelationId: data.messageCorrelationId
        };
    }

    private loadContacts(): Promise<TContactResponse>[] {
        return this.state.values.map((value: DeepPartial<ShoppingCartContact>, idx: number) => {
            if (value.contact && value.contact.ssn && !value.contact.contactChanged) {
                // Skip processing already added contact
                return Promise.resolve(value.contact);
            }
            const error = this.validateFieldByIndex(idx);
            let promise;

            if (isEmpty(error) && value.contact && value.contact.ssn && value.contact.contactChanged) {
                promise = this.getContactBySsn(value.contact.ssn);
            } else {
                const {errors} = this.state;
                errors[idx] = error;
                this.setState({errors});
                promise = Promise.resolve({status: ResponseStatus.StopRequests, error});
            }

            return promise;
        });
    }

    private findContactOffers(contact: InsuredPersonContact, allContactsOffers: any[]) {
        let index = findIndex(allContactsOffers, (contactOffers) => {
            return some(contactOffers?.offers, (offer) => offer.contactExtNum === contact.externalContactNumber);
        });
        let offers = [];

        // TODO: Remove when IDIT returns contactExtNum
        if (index === -1) {
            index = 0;
        }

        if (index !== -1) {
            offers = Object.assign([], allContactsOffers[index]?.offers);
        }

        return {offers, index};

    }

    private mapContactDataResponse(data: ILoadContactDataAndOffers): IContactMapResponse {
        const values: any[] = this.state.values;
        const errors: any[] = this.state.errors;

        for (const idx in data.contacts) {
            const contactResponse = data.contacts[idx];
            if ("status" in contactResponse && contactResponse.status === ResponseStatus.Error) {
                errors[idx] = contactResponse.error;
            } else if ("externalContactNumber" in contactResponse) {
                const {offers, index} = this.findContactOffers(contactResponse, data.contactOffers);

                if (index !== -1) {
                    delete data.contactOffers[index];
                }

                values[idx] = {
                    contact: {...contactResponse, contactLoaded: true, contactChanged: false},
                    offers
                };
                delete errors[idx];
            }
        }

        return {values, errors};
    }

    private prepareContactRequest(contact: InsuredPersonContact) {
        const ssn = SsnFormatter.formatWithoutDash(contact.ssn);
        const memberSsn = SsnFormatter.formatWithoutDash(this.context?.data.member.contact.ssn);

        return {
            ssn,
            identityNumber: memberSsn,
            insuredPersonType: InsuredPersonTypes.Child,
            groupId: this.context.data.group.id
        };
    }

    private async loadContactAndOfferedPolicies(validContacts: InsuredPersonContact[]): Promise<GetOfferedExistingPoliciesResponse | undefined> {
        try {
            const request = validContacts.map(this.prepareContactRequest);

            return await this.getOfferedPolicies({
                messageCorrelationId: this.context?.data?.messageCorrelationId,
                getOfferedExistingPoliciesRequest: request,
                locale: this.props.intl.locale
            });
        } catch (e: any) {
            console.error(e);
            this.context.onError(e);
            return undefined;
        }
    }

    private validateFieldByIndex(idx: number): string {
        const {reservedSsnPath} = this.metadata;

        const values = this.state.values;
        let error = "";
        const childContact = values[idx].contact;

        if (!childContact || !childContact.ssn) {
            error = CmsHelper.withPrefix(this.props.cmsContext, "childrenContactDetails.ssn.error.required");
        }

        if (childContact && childContact.ssn) {
            const reservedSsnList: string[] = [];
            castArray(reservedSsnPath).forEach((reservedContactPath: string) => {
                castArray(get(this.props.formContext?.data, reservedContactPath)).forEach((reservedContact) => {
                    const scContact = reservedContact || {} as ShoppingCartContact;
                    if (scContact?.contact?.ssn) {
                        reservedSsnList.push(reservedContact.contact.ssn)
                    }
                })
            });

            error = validateComplexSsn(childContact.ssn, reservedSsnList, CmsHelper.withPrefix(this.props.cmsContext, "childrenContactDetails.ssn.error.ssnAlreadyInUse")) || "";
        }

        if (childContact && this.isDuplicate(childContact, idx)) {
            error = CmsHelper.withPrefix(this.props.cmsContext, "childrenContactDetails.ssn.error.duplicate");
        }

        return error;
    }

    private isFieldInvalid(idx: number): boolean {
        return (this.props.errorSchema[idx] && this.props.errorSchema[idx].__errors.length > 0) || !!this.state.errors[idx];
    }

    private getFieldError(idx: number): string | undefined {
        if (this.props.errorSchema[idx] && this.props.errorSchema[idx].__errors.length > 0) {
            return this.props.errorSchema[idx].__errors[0];
        } else if (!isEmpty(this.state.errors[idx])) {
            return this.state.errors[idx];
        }
    }

    private async getContactBySsn(ssn: string): Promise<InsuredPersonContact> {
        return this.userService.getContactBySSN(ssn) as InsuredPersonContact;
    }

    private async getOfferedPolicies(payload: GetOfferedExistingPoliciesRequest): Promise<GetOfferedExistingPoliciesResponse> {
        const result = await this.shoppingCartService.getOfferedPolicies(payload);

        return result;
    }
}

export default flowRight(injectIntl, withCmsContextConsumer, withFormContext)(ChildrenSsnInputContainer);

import {Route, RouteProps} from 'react-router-dom'
import {withTransaction} from "./withTransaction";
import withDynamicAnalyticLoader from "../withDynamicAnalyticLoader";
import {container} from "../../../inversify.config";
import {ICmsParamsService} from "../../../services";
import {Types} from "../../../Types";
import {withIncomeInsuranceParams, withInitParams} from "../withIncomeInsuranceParams";
import {withGroupInsuranceParams} from "../withGroupInsuranceParams";

const cmsParamsService = container.get<ICmsParamsService>(Types.CmsParamsService);
const sessionStorage = container.get<ICmsParamsService>(Types.SessionStorage);

const ApmRoute = withDynamicAnalyticLoader(withTransaction<RouteProps>("Route")(Route), cmsParamsService);

// Used for income addon - we need to load url params into sessionStorage
const ApmRouteWithInitialParams = withIncomeInsuranceParams({sessionStorage})(withDynamicAnalyticLoader(withTransaction<RouteProps>("Route")(Route), cmsParamsService));

const ClaimRouteWithInitialParams = withInitParams({sessionStorage})(withDynamicAnalyticLoader(withTransaction<RouteProps>("Route")(Route), cmsParamsService));

const ApmRouteGroupWithInitialParams = withGroupInsuranceParams({sessionStorage})(withDynamicAnalyticLoader(withTransaction<RouteProps>("Route")(Route), cmsParamsService));

export { ApmRoute, ApmRouteWithInitialParams, ClaimRouteWithInitialParams, ApmRouteGroupWithInitialParams };

import * as React from "react";
import isEmpty from "lodash/isEmpty";
import { FormComponentBase, IFormComponentProps } from "../../../journey/form/FormComponentBase"
import {IMetadata, SsnInput as SsnInputComponent} from "../input/SsnInput";
import ProductSelectionWidget, { IMetadata as IProductSelectionWidgetMetadata } from "./ProductSelectionWidget";
import {
    ShoppingCartContact,
    InsuredPersonContact,
    InsuredPersonTypes,
    GetOfferedExistingPoliciesRequest,
    PolicySearchResult,
    DeepPartial,
    Contact,
    GetOfferedExistingPoliciesResponse
} from "@folksam-digital/model";
import { withFormContext } from "../../../journey/form/withFormContext";
import { Spacing } from "@folksam-digital/ui";
import {SsnFormatter} from "@folksam-digital/services";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";
import set from "lodash/set";
import head from "lodash/head";
import { IShoppingCartService, IUserService } from "../../../../services";
import { container } from "../../../../inversify.config";
import {Types} from "../../../../Types";
import {ProductCardWidgetTitle} from "../output";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import {injectIntl, WrappedComponentProps} from "react-intl";
import flowRight from "lodash/flowRight";
import castArray from "lodash/castArray";
import {validateComplexSsn} from "../../../../Helpers/validation/isValidSsn";
import get from "lodash/get";
import {withCmsContextConsumer} from "../../../../cms/withCmsContextConsumer";
import {ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {UuidGenerator} from "../../../../Helpers/UuidGenerator";

type TPartnerSsnInputMetadata = IMetadata & IProductSelectionWidgetMetadata;

export interface IPartnerSsnInputContainerState {
    id: string,
    offers: PolicySearchResult[];
    contact: DeepPartial<InsuredPersonContact>;
    error: string;
    isLoading: boolean;
}

interface IPartnerSsnInputContainerProps extends IFormComponentProps<any, TPartnerSsnInputMetadata & WrappedComponentProps> {
    cmsContext: ICmsContext;
}

class PartnerSsnInputContainer extends FormComponentBase<any, TPartnerSsnInputMetadata, IPartnerSsnInputContainerState, IPartnerSsnInputContainerProps> {
    public static contextType = FormContext;
    protected shoppingCartService: IShoppingCartService;
    protected userService: IUserService;

    constructor(props: IPartnerSsnInputContainerProps, context: IFormContext) {
        super(props, context);
        this.onLoadContactData = this.onLoadContactData.bind(this);
        this.getFieldError = this.getFieldError.bind(this);
        this.isFieldInvalid = this.isFieldInvalid.bind(this);
        this.handleShowPriceClick = this.handleShowPriceClick.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onProductListUpdate = this.onProductListUpdate.bind(this);
        this.shoppingCartService = container.get<IShoppingCartService>(Types.ShoppingCartService);
        this.userService = container.get<IUserService>(Types.UserService);

        this.state = {id: UuidGenerator.generate(), contact: {}, offers: [], error: "", isLoading: false};
    }

    public componentDidMount(): void {
        if (this.props.formData) {
            this.setState({id: UuidGenerator.generate(), ...this.props.formData})
        }
    }

    private async getOfferedPolicies(payload: GetOfferedExistingPoliciesRequest): Promise<GetOfferedExistingPoliciesResponse> {
        const offeredPolicies = await this.shoppingCartService.getOfferedPolicies(payload);

        return offeredPolicies;
    }

    private showOfferList() {
        const { contact } = this.state;

        return (contact && contact.ssn && contact.contactLoaded && !contact.isProtected);
    }

    public render() {
        return (
            <div>
                <SsnInputComponent
                    {...this.props}
                    values={[this.state]}
                    getError={this.getFieldError}
                    isFieldInvalid={this.isFieldInvalid}
                    onRemove={this.onRemove}
                    onValueChange={this.onChange}
                    isLoading={this.state.isLoading}
                    onClick={this.handleShowPriceClick}
                    showBorder={false}
                />
                <Spacing type={"margin"} top={"md"} bottom={"sm"}>
                    {this.renderProductWidget()}
                </Spacing>
            </div>
        );
    }

    private renderProductWidget() {
        const { offersTitleMessage, offersSubtitleMessage } = this.metadata;
        if (this.showOfferList()) {
            const {contact: { firstName, lastName }, offers} = this.state;
            return (
                <>
                    <ProductCardWidgetTitle
                        firstName={firstName!}
                        lastName={lastName!}
                        titleMessage={offersTitleMessage || ""}
                        subtitleMessage={offersSubtitleMessage}
                    />
                    <ProductSelectionWidget
                        {...this.props}
                        name="partnerOfferSelection"
                        formData={offers}
                        onChange={this.onProductListUpdate}/>
                </>
            );
        } else {
            return(<></>)
        }
    }

    private getFieldError(idx: number): string | undefined {
        if (!isEmpty(this.state.error)) {
            return this.state.error;
        }

        if((this.props.rawErrors === undefined || this.props.rawErrors.length === 0)) {
            return undefined;
        }

        return this.props.rawErrors[0];
    }

    private isFieldInvalid(idx: number): boolean {
        return (this.props.rawErrors && this.props.rawErrors.length > 0) || !!this.state.error;
    }

    private async onRemove(idx: number) {
        const value = {
            contact: {},
            offers: []
        };
        this.setState({...value});

        await this.context.updatePremium(undefined, undefined, {path: this.uiSchema.model, data: value});

        this.props.onChange(value);
    }

    public onProductListUpdate(productList: any[]): any {
        const newData = {contact: this.state.contact, offers: productList};
        this.setState(newData);

        return this.props.onChange(newData);
    }

    private async onChange(idx: number, value: string): Promise<void> {
        const data = cloneDeep(this.props.formData);

        set(data, "contact.ssn", value);
        set(data, "contact.contactChanged", true);

        await this.setState({...data, contactChanged: true});

        if (!isEmpty(this.state.error)) {
            const error = this.validateField();
            this.setState({error});
        }

        this.props.onChange(merge(data, this.state.contact));
    }

    private validateField(): string {
        const {reservedSsnPath} = this.metadata;
        const contact = this.state.contact;

        let error = "";
        if (!contact || !contact.ssn) {
            error = CmsHelper.withPrefix(this.props.cmsContext, "partnerContactDetails.ssn.error.required");
        }

        if (contact && contact.ssn) {
            const reservedSsnList: string[] = [];
            castArray(reservedSsnPath).forEach((reservedContactPath: string) => {
                castArray(get(this.props.formContext?.data, reservedContactPath)).forEach((reservedContact) => {
                    const scContact = reservedContact || {} as ShoppingCartContact;
                    if (scContact?.contact?.ssn) {
                        reservedSsnList.push(reservedContact.contact.ssn)
                    }
                })
            });

            error = validateComplexSsn(contact.ssn, reservedSsnList, CmsHelper.withPrefix(this.props.cmsContext, "partnerContactDetails.ssn.error.ssnAlreadyInUse")) || "";
        }

        return error;
    }

    private async handleShowPriceClick(event: any): Promise<void> {
        const error = this.validateField();

        if (!isEmpty(error)) {
            this.setState({error});
            return;
        }
        const contact = this.state.contact;

        if (contact.contactChanged) {
            this.setState({isLoading: true});

            const contactData = await this.onLoadContactData();

            if (contactData && contactData.contact && contactData.contact.ssn) {
                contactData.contact.contactChanged = false;
                this.setState({
                    ...this.state,
                    ...contactData,
                    isLoading: false
                });

                if (contactData.messageCorrelationId) {
                    this.props.formContext?.setMessageCorrelationId(contactData.messageCorrelationId);
                }

                this.props.onChange(contactData);
            } else {
                this.setState({
                    error: CmsHelper.withPrefix(this.props.cmsContext, "partnerContactDetails.ssn.error.notFound"),
                    isLoading: false
                });
            }
        }
    }

    private async onLoadContactData(): Promise<ShoppingCartContact & Pick<GetOfferedExistingPoliciesResponse, "messageCorrelationId"> | undefined> {
        try {
            const { contact } = this.state;
            const contactData = {} as ShoppingCartContact;

            if (contact && contact.ssn) {
                const ssn = SsnFormatter.formatWithoutDash(contact.ssn);
                const memberSsn = SsnFormatter.formatWithoutDash(this.props.formContext?.data.member.contact.ssn);
                const contactResponse = await this.getContactBySsn(ssn);

                if (contactResponse?.externalContactNumber) {
                    const offerListResponse = await this.getOfferedPolicies({
                        getOfferedExistingPoliciesRequest: [{
                            ssn: ssn,
                            identityNumber: memberSsn,
                            insuredPersonType: InsuredPersonTypes.Partner,
                            groupId: this.props.formContext?.data.group.id,
                        }],
                        messageCorrelationId: this.props.formContext?.data?.messageCorrelationId,
                        locale: this.props.intl.locale
                    });

                    return {
                        messageCorrelationId: !!offerListResponse.messageCorrelationId ? offerListResponse.messageCorrelationId : undefined,
                        offers: head(offerListResponse.getOfferedExistingPoliciesResponse)?.offers,
                        contact: {...contactResponse, contactLoaded: true}
                    };
                }
            }

            return contactData;
        } catch (error) {
            this.props.formContext?.onError();
        }
    }

    protected async getContactBySsn(ssn: string): Promise<Contact | undefined> {
        return this.userService.getContactBySSN(ssn);
    }
}

export default flowRight(injectIntl, withCmsContextConsumer, withFormContext)(PartnerSsnInputContainer);

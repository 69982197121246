import * as React from "react";
import {FormattedMessage} from "react-intl";
import {PolicyGroupHeader} from "../../../shoppingCart/form/output";
import {CmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {defaultTheme as theme} from "@folksam-digital/ui";

export class OptCardHeaderMessage extends React.PureComponent<any, any> {
    public static contextType = CmsContext;

    public render() {
        return (
            <PolicyGroupHeader style={{margin: 0, lineHeight: theme.leading.tight}}>
                <FormattedMessage id={CmsHelper.withPrefix(this.context, "optOut.listHeader")}
                                  values={{fullName: this.props.fullName}}
                />
            </PolicyGroupHeader>
        );
    }
}

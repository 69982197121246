import * as React from "react";
import {
    Animate,
    ConstrainWidth,
    FormError,
    FormInputRadioTextbox,
    FormLabel,
    FormNote,
    Grid,
    Spacing
} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {IBreakPoint} from "../../layout/helpers";
import {IFormComponentProps} from "../FormComponentBase";
import {ILocaleContext} from "../../../../intl/LocaleContext";
import {FormattedMessage, injectIntl, WrappedComponentProps} from "react-intl";
import sanitizeField from "./helpers/sanitizeField";
import {Constants, InputMode, InputType} from "@folksam-digital/model";
import {assign, merge} from "lodash";
import {SsnFormatter} from "@folksam-digital/services/lib";

interface IMetadata {
    ssnPlaceholder?: string;
    dobPlaceholder?: string;
    help?: string;
    breakpoints?: IBreakPoint;
    index?: number;
    ssnLabelTitle?: string;
    dobLabelTitle?: string;
    dobHelp?: string;
}

interface ISsnOrDobRadioInputProps<TValue, TMetadata> extends IFormComponentProps<TValue, TMetadata>, WrappedComponentProps {
    localeContext: ILocaleContext;
}

class RadioSsnOrDateOfBirth extends InputComponentBase<any, IMetadata, any, ISsnOrDobRadioInputProps<any, IMetadata>> {
    private ssnName = Constants.ParticipantInfo.ssnOrDateOfBirth.ssn;
    private dobName = Constants.ParticipantInfo.ssnOrDateOfBirth.dateOfBirth;
    private currentIndex = this.metadata.index;

    public constructor(props: ISsnOrDobRadioInputProps<any, any>) {
        super(props);
        this.handleOnBlurCallbacks = this.handleOnBlurCallbacks.bind(this);
        this.handleOnBlurSsnCallback = this.handleOnBlurSsnCallback.bind(this);
    }

    componentDidMount() {
        if (!this.props?.formData?.type) {
            this.ssnTypeUpdate();
        }
    }

    componentDidUpdate() {
        if (this.props?.formData === undefined) {
           this.ssnTypeUpdate();
        }
    }

    private ssnTypeUpdate() {
        const data = this.props?.formData;
        const dataMerged = merge({}, data, {type: `${this.ssnName}-${this.currentIndex}`});
        this.props.onChange(dataMerged);
    }

    private ssnOrDateOfBirth(type: any, value: any) {
        if (type === `${this.ssnName}-${this.currentIndex}`) {
            return {ssn: value, dateOfBirth: ""};
        }
        if (type === `${this.dobName}-${this.currentIndex}`) {
            return {dateOfBirth: value, ssn: ""};
        }
        return {};
    }

    private handleToggle(e: any): void {
        const type = e.target.name;
        const data = this.props?.formData;
        const dataMerged = merge({}, data, {type});
        this.props.onChange(dataMerged);
        this.context.fieldValidation(this.props.idSchema, this.props.uiSchema, dataMerged);
    }

    private handleInput(e: any): void {
        const value = !e.target.value ? "" : sanitizeField(e.target.value);
        const data = this.props?.formData;
        const type = data?.type || `${this.ssnName}-${this.currentIndex}`;
        const inputData = this.ssnOrDateOfBirth(type, value);
        const dataMerged = assign({}, data, inputData);
        this.props.onChange(dataMerged);
    }

    private renderHeader(props: any) {
        return (
            <div style={props.disabled ? {opacity: "50%"} : {}}>
                <Spacing type={"margin"} left={"lg"}>
                    <Spacing type={"margin"} left={"sm"}>
                        <FormLabel required={true} invalid={props.invalid} htmlFor={props.inputId}>
                            {props.labelTitle}
                        </FormLabel>
                        {props.help &&
                        <FormNote>
                            {props.help}
                        </FormNote>
                        }
                    </Spacing>
                </Spacing>
            </div>
        );
    }

    private renderError(error?: string) {
            return error ?
            (
                <Spacing type={"margin"} left={"lg"}>
                    <Spacing type={"margin"} left={"sm"}>
                        <Animate.Drop>
                            <ConstrainWidth>
                                <FormError>
                                    <FormattedMessage id={error}/>
                                </FormError>
                            </ConstrainWidth>
                        </Animate.Drop>
                    </Spacing>
                </Spacing>
            )
            : null;
    }

    private isDobSelected(): boolean {
        return this.props?.formData?.type === `${this.dobName}-${this.currentIndex}`;
    }

    private isSsnSelected(): boolean {
        if (!this.props?.formData?.type) {
            return true;
        }
        return this.props?.formData?.type === `${this.ssnName}-${this.currentIndex}`;
    }

    private handleOnBlurCallbacks(event: any) {
        if (this.props.onBlur && typeof this.props.onBlur === "function") {
            this.props.onBlur(event);
        }
        this.context.fieldValidation(this.props.idSchema, this.props.uiSchema, this.props?.formData);
    }

    private handleOnBlurSsnCallback(event: any) {
        const data = this.props?.formData;
        if (data) {
            const ssnFormatted = SsnFormatter.formatWithDash(data?.ssn?.trim());

            data.ssn = ssnFormatted;

            if (this.props.onBlur && typeof this.props.onBlur === "function") {
                this.props.onBlur(event);
            }

            this.onChangeWithValidation(data);
        }
    }

    public render() {
        const {breakpoints} = this.metadata;
        const {ssn, dateOfBirth} = this.props?.formData || {};
        const {intl} = this.props;
        const error = this.getError();

        return (
            <Grid.Row  style={{flexDirection: 'column'}}>
                <Grid.Col {...breakpoints}>
                {this.renderHeader({
                    inputId: `${this.ssnName}-${this.currentIndex}`,
                    labelTitle: intl.formatMessage({id: this.metadata.ssnLabelTitle}),
                    disabled: !this.isSsnSelected(),
                    invalid: this.isSsnSelected() && this.isInvalid()})
                }
                <FormInputRadioTextbox
                        id={`${this.ssnName}-${this.currentIndex}`}
                        name={`${this.ssnName}-${this.currentIndex}`}
                        value={this.isSsnSelected() ? ssn : ""}
                        onButtonToggle={(e: any) => this.handleToggle(e)}
                        onTextInput={(e: any) => this.handleInput(e)}
                        checked={this.isSsnSelected()}
                        inputValue={this.isSsnSelected() ? ssn : ""}
                        placeholder={this.metadata.ssnPlaceholder && intl.formatMessage({id: this.metadata.ssnPlaceholder})}
                        invalid={this.isSsnSelected() && this.isInvalid()}
                        onBlur={this.handleOnBlurSsnCallback}
                        type={InputType.tel}
                        inputMode={InputMode.tel}
                    />
                {this.isSsnSelected() && this.renderError(error)}
                <Spacing type={"margin"} bottom={"sm"}/>
                </Grid.Col>
                <Grid.Col {...breakpoints}>
                    {this.renderHeader({
                        inputId: `${this.dobName}-${this.currentIndex}`,
                        labelTitle: intl.formatMessage({id: this.metadata.dobLabelTitle}),
                        help: intl.formatMessage({id: this.metadata.dobHelp}),
                        disabled: !this.isDobSelected(),
                        invalid: this.isDobSelected() && this.isInvalid()})
                    }
                </Grid.Col>
                <Grid.Col {...breakpoints}>
                <FormInputRadioTextbox
                        id={`${this.dobName}-${this.currentIndex}`}
                        name={`${this.dobName}-${this.currentIndex}`}
                        value={this.isDobSelected() ? dateOfBirth : ""}
                        onButtonToggle={(e: any) => { this.handleToggle(e)}}
                        onTextInput={(e: any) => this.handleInput(e)}
                        checked={this.isDobSelected()}
                        inputValue={this.isDobSelected() ? dateOfBirth : ""}
                        placeholder={this.metadata.dobPlaceholder && intl.formatMessage({id: this.metadata.dobPlaceholder})}
                        invalid={this.isDobSelected() && this.isInvalid()}
                        onBlur={this.handleOnBlurCallbacks}
                        type={InputType.tel}
                        inputMode={InputMode.tel}
                    />
                {this.isDobSelected() && this.renderError(error)}
                </Grid.Col>
            </Grid.Row>
        );
    }
}
const SsnOrDobRadioInput = injectIntl(RadioSsnOrDateOfBirth);
export {SsnOrDobRadioInput};

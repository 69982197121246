import * as React from "react";
import {FormattedMessage, injectIntl, IntlShape} from "react-intl";
import scrollIntoView from "scroll-into-view-if-needed";
import {
    BannerIconNew,
    BrandLogoNew,
    BuySuccessIcon,
    ClaimSuccessIcon,
    defaultTheme,
    GenericUnionIcon,
    PageHeader,
    Spacing
} from "@folksam-digital/ui";
import {RouteComponentProps, withRouter} from "react-router";
import flowRight from "lodash/flowRight";
import {ILayoutComponentProps, LayoutComponentBase} from "../LayoutComponentBase";
import withAuthentication from "../../../../authentication/withAuthentication";
import {AuthenticationContext, IAuthenticationContext} from "../../../../authentication/AuthenticationContext";
import {CmsContext, ICmsContext} from "../../../../cms";
import {DynamicComponent} from "../../../DynamicComponent";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {logoHref} from "../../navigation/common/params";
import {Constants} from "@folksam-digital/model";
import {pageTitleHelper} from "../../../../Helpers/pageTitleHelper";
import {DynamicTitle} from "../../../general/DynamicTitle";
import {
    bannerIconBrandLogoHelper,
    IBannerIconNewCollaboration
} from "../../form/output/helpers/bannerIconBrandLogoHelper";
import {SuccessHeaderMessageBox} from "./SuccessHeaderMessageBox";
import {FormContext, IFormContext} from "../../form/FormContext";

interface IHeaderLayoutProps extends ILayoutComponentProps {
    bannerIconTheme: number;
    journeyId?: string;
    unionGroupName?: string;
    logOutUrl?: string;
    stepBack?: boolean;
    backUrl?: string;
    returnUrl?: string;
    navButton?: any;
    translations: {
        header: string;
        subheader?: string;
        headerText?: string;
        headerInfoBox?: string;
        collaborationBanner?: string;
    },
    formData: any;
    doScroll?: boolean;
    noContentPadding?: boolean;
    intl: IntlShape;
}

export class SuccessHeaderLayoutInternal extends LayoutComponentBase<IHeaderLayoutProps & RouteComponentProps, {}> {
    private readonly containerRef: React.RefObject<any>;

    constructor(props: IHeaderLayoutProps & RouteComponentProps) {
        super(props);
        this.containerRef = React.createRef();
    }

    public componentDidMount(): void {
        if (this.props.doScroll) {
            this.scrollToThisElement();
        }
    }

    private getCollaborationIcon(cmsContext: ICmsContext) {
        if (this.props.journeyId && bannerIconBrandLogoHelper.hasOwnProperty(this.props.journeyId)) {
            const collaborationData = bannerIconBrandLogoHelper[this.props.journeyId](this.props.formData, cmsContext, this.props.intl) as IBannerIconNewCollaboration;
            return collaborationData?.icon;
        } else {
            return <GenericUnionIcon/>;
        }
    }

    public render() {
        const {
            stepBack,
            backUrl,
            logOutUrl,
            returnUrl,
            navButton: NavButtonComponent,
            journeyId,
            formData
        } = this.props;

        return (
            <React.Fragment>
                <DynamicTitle keyId={pageTitleHelper(journeyId!, formData, this.context)}/>
                <AuthenticationContext.Consumer>
                    {(authenticationContext: IAuthenticationContext) =>
                        <CmsContext.Consumer>
                            {(cmsContext: ICmsContext) => (
                                <FormContext.Consumer>
                                    {(context: IFormContext) => {
                                        return <div ref={this.containerRef}>
                                        {context?.data?.agentId !== Constants.AgentId.AppWebView &&
                                            <PageHeader fullWidth headerText={this.props.translations.headerText} logoHref={logoHref}>
                                                {!!NavButtonComponent &&
                                                    <DynamicComponent component={NavButtonComponent} {...{
                                                        isAuthenticated: authenticationContext.isAuthenticated,
                                                        stepBack,
                                                        backUrl,
                                                        logOutUrl,
                                                        returnUrl
                                                    }} />}
                                            </PageHeader>
                                        }
                                        <BannerIconNew
                                            successPage={true}
                                            centered={true}
                                            theme={Constants.Themes.LightBlue}
                                            icon={this.getSuccessPageIcon()}
                                        >
                                            <Spacing type={"padding"} bottom={"4"}/>
                                            {this.props?.translations?.header && <BannerIconNew.Header>
                                                <FormattedMessage id={this.props.translations.header}
                                                                  values={this.props?.formData?.contact}/>
                                            </BannerIconNew.Header>}

                                            {this.props?.translations?.subheader &&
                                            <Spacing type={"padding"} bottom={"4"}/>}
                                            <BannerIconNew.Subheader centered>
                                                {this.props?.translations?.subheader &&
                                                <FormattedMarkdown
                                                    messageKey={this.props?.translations?.subheader}
                                                    disallowedTypes={[]}
                                                    messageValue={this.props?.formData}
                                                />
                                                }
                                            </BannerIconNew.Subheader>

                                            {this.props.translations.headerInfoBox &&
                                            <SuccessHeaderMessageBox message={this.props.translations.headerInfoBox}/>
                                            }

                                            {this.props.unionGroupName &&
                                            <BrandLogoNew icon={this.getCollaborationIcon(cmsContext)} successPage={true}>
                                                <div style={{color: defaultTheme.colors.black}}>
                                                    <FormattedMarkdown
                                                        messageKey={this.props.translations.collaborationBanner ? this.props.translations.collaborationBanner : "bannerLogo.inCollaborationWith"}
                                                        messageValue={{groupName: `${this.props.unionGroupName}`}}
                                                    />
                                                </div>
                                            </BrandLogoNew>
                                            }

                                            {this.props.children &&
                                            <BannerIconNew.Content
                                                noPadding={this.props.noContentPadding}>{this.props.children}</BannerIconNew.Content>}
                                        </BannerIconNew>
                                    </div>;
                                    }}
                                </FormContext.Consumer>
                            )}
                        </CmsContext.Consumer>}
                </AuthenticationContext.Consumer>
            </React.Fragment>
        );
    }

    private scrollToThisElement() {
        scrollIntoView(this.containerRef.current, {block: "start", behavior: "smooth"});
    }

    private getSuccessPageIcon() {
        switch (this.props.bannerIconTheme) {
            case Constants.SuccessPageHeaderProperties.ImageTheme.Buy:
                return <BuySuccessIcon/>;
            case Constants.SuccessPageHeaderProperties.ImageTheme.Claim:
                return <ClaimSuccessIcon/>;
            default:
                return <BuySuccessIcon/>;
        }
    }
}

const SuccessHeaderLayout = flowRight(withRouter, withAuthentication, injectIntl)(SuccessHeaderLayoutInternal);
export {SuccessHeaderLayout};

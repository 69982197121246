import React, {FunctionComponent, ReactElement} from "react";
import isFunction from "lodash/isFunction";

interface IDynamicComponent {
    component: FunctionComponent<any> | ReactElement<any>;
    [key: string]: any;
}

export const DynamicComponent = React.forwardRef(({component: Component, ...props}: IDynamicComponent, ref: React.Ref<any>) => {
    if (isFunction(Component)) {
        return <Component ref={ref} { ...props }/>
    } else {
        return React.cloneElement(Component as ReactElement<any>, {...props, ref})
    }
});

export function findChildError(errorSchema: any, schemaObj: any): boolean {
    let hasError = false;

    // child unwrapped field
    if (errorSchema?.__errors?.length > 0) {
        return true;
    }

    // child within fieldGroup.children
    if (Object.keys(errorSchema).length > 0 && schemaObj?.properties) {
        for (const errorSchemaKey in errorSchema as any) {
            if (errorSchemaKey !== '__errors' && errorSchema.hasOwnProperty(errorSchemaKey) && schemaObj.properties.hasOwnProperty(errorSchemaKey)) {
                hasError = findChildError(errorSchema[errorSchemaKey], schemaObj.properties[errorSchemaKey]);
                if (hasError) {
                    break;
                }
            }
        }
    }

    return hasError;
}

import * as React from "react";
import {FormattedMessage} from "react-intl";
import {DescriptionList} from "@folksam-digital/ui";
import {CmsContext} from "../../../../../cms";
import {CmsHelper} from "../../../../../Helpers/cms/CmsHelper";

interface IContactHeaderProps {
    fullName: string;
    customMessageFormatId?: string;
}

export abstract class ContactHeader extends React.Component<IContactHeaderProps, {}> {
    public static contextType = CmsContext;

    public render() {
        const { fullName, customMessageFormatId } = this.props;

        return (
            <DescriptionList.Row separated={true}>
                <DescriptionList.Content>
                    <DescriptionList.Term>
                        <FormattedMessage id={customMessageFormatId ? customMessageFormatId : CmsHelper.withPrefix(this.context, "reviewAndSubmit.selectedItems.groupTitle")}/>
                    </DescriptionList.Term>
                    <DescriptionList.Definition>
                        {fullName}
                    </DescriptionList.Definition>
                </DescriptionList.Content>
            </DescriptionList.Row>
        );
    }
}

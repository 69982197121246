import * as React from "react";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {defaultTheme, Grid, Modal} from "@folksam-digital/ui";

interface IMetadata {
    path: string;
    messageId: string;
    shouldReturnFocusAfterClose?: boolean;
    shouldCloseOnEsc?: boolean;
    showCloseButton?: boolean;
}

export class CarModalYesNoWidget extends InputComponentBase<any, IMetadata, any> {
    constructor(props: IInputComponentProps<any, IMetadata>) {
        super(props);
        this.handleOnClose = this.handleOnClose.bind(this);
    }

    public render() {
        const title = this.schema.title;
        const {
            shouldReturnFocusAfterClose = true,
            shouldCloseOnEsc = true,
            showCloseButton
        } = this.metadata;

        const modalDefaults = {
            role: "dialog",
            shouldFocusAfterRender: true,
        };

        return (
            <Modal
                {...modalDefaults}
                id={this.props.name}
                isOpen={true}
                shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
                shouldCloseOnEsc={shouldCloseOnEsc}
                onRequestClose={this.handleOnClose}
                style={{
                    overlay: {
                        zIndex: "110000",
                    },
                }}
            >
                <Modal.Header showCloseButton={showCloseButton} onModalClose={this.handleOnClose} style={{paddingBottom: "0"}}>
                    <FormattedMarkdown disallowedTypes={[]} messageKey={title}/>
                </Modal.Header>
                <Modal.Body>
                    <Grid>
                        <Grid.Row>
                            <div style={{
                                fontSize: defaultTheme.textSizes.sm,
                                fontWeight: defaultTheme.fontWeights.regular
                            }}>
                                <FormattedMarkdown disallowedTypes={[]} messageKey={this.metadata.messageId}/>
                            </div>
                        </Grid.Row>
                    </Grid>
                </Modal.Body>
            </Modal>
        );
    }

    private handleOnClose(event: any) {
        event?.preventDefault();
        this.props.onChange(true);
    }
}

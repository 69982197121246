import * as React from "react";
import {ILocaleContext, LocaleContext} from "../intl/LocaleContext";
import {LocaleUnawareCmsProvider} from "./LocaleUnawareCmsProvider";
import {CmsCatalogDetails} from "./CmsCatalogs";

interface IProps {
    catalog: CmsCatalogDetails
}

export class CmsProvider extends React.Component<IProps> {

    /**
     * Render context provider with data from CMS
     */
    public render(): any {
        return (
            <LocaleContext.Consumer>
                {(value: ILocaleContext) =>
                    <LocaleUnawareCmsProvider locale={value.locale}
                                              catalog={this.props.catalog}
                                              children={this.props.children} />
                }
            </LocaleContext.Consumer>
        )
    }
}

import React, {useCallback} from "react";
import {useHistory, } from "react-router";
import * as H from 'history';

import {ButtonResponsive, IconEditV2, Button} from "@folksam-digital/ui";
import {onBtnClick} from "../output/helpers";
import {FormattedMessage} from "react-intl";
import {IFormContext} from "../FormContext";

interface IRedirectControlProps {
    id: string;
    context: IFormContext;
    redirectControl?: {
        redirectText: string,
        redirectUrl: string,
        asBtn?: boolean
    };
    props: any;
    summaryCardRedirect?: boolean;
}

export const RedirectControl: React.FC<IRedirectControlProps> = ({id, context, redirectControl, summaryCardRedirect, props}) => {
    const history: H.History = useHistory();
    const onClick = useCallback((event: any) => {
        onBtnClick(event, !!redirectControl ? redirectControl?.redirectUrl : "", context, {history});
    }, [redirectControl, context, history]);

    // asBtn - provides fallback to previous button use.
    if (redirectControl && redirectControl.asBtn) {
        return <ButtonResponsive
            id={id}
            type="button"
            outline={true}
            small={true}
            xSmall={props.viewContext?.isMobile}
            style={{flex: 1}}
            onClick={onClick}
        >
            <FormattedMessage id={redirectControl.redirectText} />
        </ButtonResponsive>
    } else if (redirectControl) {
        return <Button id={id} editButton fontSize={"small"}
            onClick={(event: any) => onBtnClick(event, redirectControl.redirectUrl, context, props)}
        >
            <IconEditV2 style={{paddingRight: "4px", width: 18, height: 18}} />
            <FormattedMessage id={"general.accordionPanel.button.edit"} />
        </Button>
    }

    return null
};

import {BaseAnalytics, BaseMapping, IOnTriggerDataLayerEventParams, JourneyType} from "./BaseAnalytics";

export class DirectDebitAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName = params.messages[`general.adobe.${params.journeyId!.toString()}`];
        const pageName: string = params.currentStep === "setUpForm" ? "" : this.getPageName(params);
        const pageStart = params.messages[`general.adobe.directDebit.pageStart`];

        const source: BaseMapping = {
            pageUrl: '',
            gender: '',
            customerId: '',
            age: '',
            pageName: `${pageStart}|${journeyName}${pageName}`,
            pageType: journeyName,
            serviceName: journeyName,
            siteSection1: `${pageStart}|`,
            siteSection2: `${pageStart}|${journeyName}`,
            siteSection3: `${pageStart}|${journeyName}${pageName}|`,
            siteSection4: `${pageStart}|${journeyName}${pageName}||`,
        };

        this.setDataLayer(source, `${params.transactionType}_${params.event}`, params, JourneyType.Product);
    }
}

import React from "react";
import {FormattedMessage} from "react-intl";
import {B, defaultTheme as theme, DescriptionList, Spacing} from "@folksam-digital/ui";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import {
    Cover,
    Discount,
    JourneyBase,
    LegacyDiscount,
    LegacyPremium,
    pathof,
    Premium,
    PriceBarFrequency
} from "@folksam-digital/model";
import {DiscountsTitle} from "./DiscountsTitle";
import {Tooltip} from "../../../../components/formFieldLayout/Tooltip";
import {Template} from "../../../../components/journey/form/output/helpers";
import JourneyBaseModel from "@folksam-digital/model/lib/model/JourneyBaseModel";

export interface IAddon {
    data?: Cover;
    headingId: string;
}

interface IListRowItemProps {
    id?: string;
    children?: any;
    definitionStyle?: object;
    termBold?: boolean;// Only used in Sports travel and Accident journeys unclear why not standardized style.
    discounts?: Discount[] | LegacyDiscount[];
}

interface IRowProps {
    premium: Premium | LegacyPremium;
}

interface IRowDiscountsProps extends IRowProps {
    tooltipId?: string;
}

export interface IRowAddon {
    prefix: string;
    data?: any;
}

export const RowAddon = ({prefix, data}: IRowAddon): React.ReactElement => {
    const addons: { [key: string]: Cover } | undefined = data?.policy?.covers;
    let isAnyAddonSelected = false;
    const addonList: IAddon[] = [];

    if (addons) {
        isAnyAddonSelected = Object.values(addons).some((addon) => addon.selected && addon.available);
        const prefixCalc: string = !prefix ? prefix : `${prefix}.`;
        for (const addonName in addons) {
            if (addons.hasOwnProperty(addonName)) {
                addonList.push({
                    data: data.policy.covers[addonName],
                    headingId: `${prefixCalc}${addonName}.heading`
                });
            }
        }
    }

    return (
        isAnyAddonSelected
            ? <DescriptionList.Row>
                <DescriptionList.Content>
                    {<DescriptionList.Term>
                        {<FormattedMessage id={"general.review.addition.title"}/>}
                    </DescriptionList.Term>}
                    {addonList && addonList.map((addon: IAddon) => (
                        addon.data?.selected && addon.data?.available
                            ? <DescriptionList.Definition key={addon.data.id}>
                                <FormattedMessage
                                    id={addon.headingId}
                                />
                                <DescriptionList.Price
                                    component={<FormattedPrice
                                        suffixId={"general.monthlyPremium.suffix"}
                                        value={Math.round(addon.data?.monthlyPremium || 0)}
                                    />}
                                />
                            </DescriptionList.Definition>
                            : <></>
                    ))}
                </DescriptionList.Content>
            </DescriptionList.Row>
            : <></>
    );
};

export const RowScope = ({contentMessage, data, id}: { id?: string, contentMessage: React.ReactNode, data: JourneyBase<any> }): React.ReactElement => {
    return (
        <ListRowItem
            id={id || "general.review.scope.title"}>
            {contentMessage}
            <DescriptionList.Price
                component={<Template
                    template={`{{#extractCoverPlanPremium}} {{${pathof(JourneyBaseModel.policy.coverPlan)}}} : {{${PriceBarFrequency.Monthly}}} {{/extractCoverPlanPremium}} {{#translate}} {{general.monthlyPremium.suffix}} {{/translate}}`}
                    data={data}
                    style={{whiteSpace: "nowrap"}}/>}
            />
        </ListRowItem>
    );
};

export const RowPrice = ({premium}: IRowProps): React.ReactElement => {
    return (
        <ListRowItem definitionStyle={{alignItems: "flex-start"}}>
            <FormattedMessage
                id="general.totalPrice.title"/>
            <DescriptionList.Price
                background
                fontWeight={theme.fontWeights.semiBold}
                component={<FormattedPrice
                    suffixId={"general.monthlyPremium.suffix"}
                    value={Math.round(premium?.monthlyPremium!)}/>}
                componentSub={<>(<FormattedPrice
                    value={premium?.yearlyPremium!}
                    suffixId={"general.yearlyPremium.suffix"}/>)</>}
            />
        </ListRowItem>
    );
};

export const RowDiscounts = ({premium, tooltipId}: IRowDiscountsProps): React.ReactElement => {
    return (
        <ListRowItem
            definitionStyle={{
                justifyContent: "flex-start",
                alignItems: "center"
            }}
            discounts={premium.discounts}
        >
            <DiscountsTitle/>
            <Tooltip
                tooltip={tooltipId}/>
        </ListRowItem>
    );
};

export const ListRowItem = ({id, children, definitionStyle, termBold, discounts}: IListRowItemProps): React.ReactElement => {

    const term = id && <FormattedMessage id={id}/>;

    return (
        <DescriptionList.Row>
            <DescriptionList.Content>
                {id &&
                <DescriptionList.Term>
                    {termBold ? <B>{term}</B> : term}
                </DescriptionList.Term>}
                {<DescriptionList.Definition fontWeight={theme.fontWeights.semiBold}
                                             style={definitionStyle || undefined}>
                    {children}
                </DescriptionList.Definition>}
                {discounts && discounts.length > 0 &&
                <Spacing top={'3'}>
                    {discounts?.map((discount: Discount | LegacyDiscount) => {
                        return (
                            <DescriptionList.Definition fontWeight={theme.fontWeights.medium} key={discount.id}>
                                {discount.reason}
                                <DescriptionList.Price
                                    component={<FormattedPrice value={discount.amount!}
                                                               suffixId={"general.yearlyPremium.suffix"}/>}/>
                            </DescriptionList.Definition>
                        )
                    })}
                </Spacing>}
            </DescriptionList.Content>
        </DescriptionList.Row>
    );
};

import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import flowRight from "lodash/flowRight";
import compact from "lodash/compact";
import {
    ButtonResponsive,
    defaultTheme,
    FlexRow,
    MaxWidthWrapper,
    Spacing,
    Step,
    WarningWithoutCircleIcon
} from "@folksam-digital/ui";
import {JourneyErrorInternal} from "../JourneyError";
import {IErrorParams} from "../journeyErrorTypes";
import withDataAnalytics from "../../../components/general/withDataAnalytics";
import {IErrorBaseState} from "../../ErrorBase";
import FormattedMarkdown from "../../../components/FormattedMarkdown";
import {HeaderLayoutNew} from "../../../components";
import {onBtnClick} from "../../../components/journey/form/output/helpers";
import {IFormContext} from "../../../components/journey/form/FormContext";
import {withFormContext} from "../../../components/journey/form/withFormContext";
import withCmsProvider from "../../../components/general/withCmsProvider";
import {JourneyErrorNavButton} from "../../../components/journey/navigation/JourneyErrorNavButton";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import {ICmsContext} from "../../../cms";
import {UrlHelper} from "@folksam-digital/services";
import {ComponentBackgroundWrapper} from "../../../components/ComponentBackgroundWrapper";
import injectSheet from "react-jss";
import classNames from "classnames";
import withHistoryBackConstraint from "../../../components/general/withHistoryBackConstraint";
import {Constants} from "@folksam-digital/model";
import {SatelliteEvents, SatelliteEventTransactionTypes} from "../../../analytics/new/BaseAnalytics";
import {CustomerHasNoPolicy} from "../product/model/CustomerHasNoPolicy";
import {StopCustomerError} from "./model/StopCustomerError";
import {DefaultJourneyError} from "../product/model/DefaultJourneyError";
import {BaseErrorMessage} from "../product/model/BaseErrorMessage";
import {getAnalytics, AnalyticsType} from "../../../analytics/AnalyticsSwitch";

interface IClaimErrorProps extends IErrorParams, RouteComponentProps<any, any> {
    agentId?: string;
    formContext: IFormContext;
    errorType: ClaimErrorType;
    urlSlug?: string;
    classes?: any;
    journeyId: string;
}

export enum ClaimErrorType {
    ClaimCreationErr = "claim.claimError.claim.description",
    InternalErr = "claim.claimError.claim.internalError.description",
    StopCustomerError = "stopCustomerError",
    LoadContactError = "claim.claimError.claim.internalError.description",
    CustomerHasNoPolicy = "customerHasNoPolicy"
}

const cssStyles = {
    wrapper: {
        backgroundColor: defaultTheme.colors.primary5,
        height: '100vh',
        position: 'fixed',
        width: '100%'
    }
}

class ClaimCreateErrorInternal extends JourneyErrorInternal<IClaimErrorProps, IErrorBaseState> {
    protected constructor(props: Readonly<IClaimErrorProps> & IErrorParams, context: ICmsContext) {
        super(props, context);

        this.state = {
            urlParts: [],
        };

        this.onButtonClick = this.onButtonClick.bind(this);
    }

    public async componentDidMount() {
        this.setState({message: this.getMessage()});
        const data = this.data;
        // Trigger Analytics Error Lead Start
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: data?.defaultData,
            journeyId: data?.journeyId || "",
            currentStep: "error",
            transactionType: SatelliteEventTransactionTypes.Lead,
            event: SatelliteEvents.Start,
            errorMessage: this.getMessage()
        });

        if (!this.data) {
            this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
            return;
        }

        this.prepareUrlParts();
    }

    private getMessage() {
        let error: BaseErrorMessage;

        switch (this.data?.errorType) {
            case ClaimErrorType.CustomerHasNoPolicy:
                error = new CustomerHasNoPolicy(this.data?.journeyId);
                break;
            case ClaimErrorType.StopCustomerError:
                error = new StopCustomerError(this.data?.journeyId);
                break;
            default:
                error = new DefaultJourneyError(this.data?.journeyId);
        }
        return error?.getMessage();
    }

    public prepareUrlParts() {
        const data = this.data;
        let urlParts = ["protected", "journey", "claim", data.journeyId, data.urlSlug];

        if (!UrlHelper.isFlowProtected(data.journeyId)) {
            urlParts.splice(0, 1);
        }

        if (data?.tryAgainUrl) {
            urlParts = data.tryAgainUrl.split("/");
        }

        this.setState({urlParts: compact(urlParts)});
    }

    public onButtonClick(event: any) {
        const data = this.data || {};
        if (data?.errorType === ClaimErrorType.CustomerHasNoPolicy) {
            window.location.assign(Constants.Links.claimsPage);
        } else {
            onBtnClick(event, UrlHelper.getUrl(this.state.urlParts), this.props.formContext, this.props);
        }
    }

    public render(): React.ReactNode {
        const data = this.data || {};
        const {classes} = this.props;
        const {backUrl, logOutUrl, returnUrl, journeyId, agentId, errorType, headerText} = data;
        return (
            <div className={classNames(classes.wrapper)}>
                <HeaderLayoutNew
                    themeId={1}
                    journeyId={journeyId || ""}
                    agentId={agentId}
                    formData={{}}
                    renderCollabBadge={false}
                    logOutUrl={logOutUrl}
                    returnUrl={returnUrl}
                    backUrl={backUrl}
                    navButton={JourneyErrorNavButton}
                    translations={{
                        header: CmsHelper.withPrefix(this.context, "banner.heading"),
                        headerText: headerText
                    }}
                    params={{
                        icon: <WarningWithoutCircleIcon/>,
                        centered: true
                    }}
                    isErrorPage={true}
                />
                <ComponentBackgroundWrapper theme={3}>
                    <MaxWidthWrapper compact={true}>
                        <Step.SectionBody>
                            <FlexRow className="center-xs">
                                {!!this.state?.message &&
                                    <FormattedMarkdown
                                        messageKey={CmsHelper.withPrefix(this.context, this.state.message)}/>}
                            </FlexRow>
                            <Spacing type={"margin"} top={"lg"}>
                                <ButtonResponsive
                                    full
                                    id={"redirectButton"}
                                    type="button"
                                    disabled={errorType === ClaimErrorType.StopCustomerError}
                                    onClick={this.onButtonClick}
                                >
                                    <FormattedMessage
                                        id={errorType === ClaimErrorType.CustomerHasNoPolicy ? CmsHelper.withPrefix(this.context, "claim.BackToReportDamage") : CmsHelper.withPrefix(this.context, "claim.tryAgain")}/>
                                </ButtonResponsive>
                            </Spacing>
                        </Step.SectionBody>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </div>
        );
    }
}

const ClaimError = flowRight(
    withRouter,
    withCmsProvider("claimError"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withFormContext,
    withHistoryBackConstraint,
    // @ts-ignore
    injectSheet(cssStyles, {inject: ["classes"]} as any)
)(ClaimCreateErrorInternal);

export default ClaimError;

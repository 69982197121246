import * as React from "react";
import flowRight from "lodash/flowRight";
import {withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import isEmpty from "lodash/isEmpty";
import {ClaimCompensation, Constants, MobilePhoneJourney} from "@folksam-digital/model";
import {B, Grid, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import ClaimSuccessBase, {IClaimSuccessBaseProps} from "./ClaimSuccessBase";
import {IOnTriggerDataLayerEvent} from "../../../../analytics/new/BaseAnalytics";
import {CmsContext, ICmsContext} from "../../../../cms";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {CompensationCard} from "../common/CompensationCard";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

interface MobilePhoneClaimSuccessProps extends IClaimSuccessBaseProps, IOnTriggerDataLayerEvent {

}

class MobilePhoneClaimSuccessInternal extends ClaimSuccessBase<MobilePhoneJourney, MobilePhoneClaimSuccessProps> {
    public static contextType = CmsContext;
    protected prefix = "claim.mobilePhone";

    constructor(props: MobilePhoneClaimSuccessProps, context?: ICmsContext) {
        super(props, Constants.Journey.MobilePhone.Id, context);
    }

    public componentDidMount(): void {
        this.checkData(Constants.Journey.MobilePhone.Id);
        this.triggerDataLayerEvent(Constants.Journey.MobilePhone.Id);
    }

    public render() {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <FormContext.Provider value={{data: data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Claim}
                        journeyId={Constants.Journey.MobilePhone.Id}
                        formData={{contact: data.informant}}
                        logOutUrl={Constants.Journey.MobilePhone.backUrl}
                        navButton={CloseLink}
                        translations={{
                            header: "general.success.banner.heading",
                            subheader: this.getBannerSubheaderText(),
                            headerText: <FormattedMessage id={`${this.prefix}.pageTitle.headerText`} />
                        }}
                        doScroll={true}
                    >
                        <SuccessHeaderMessageBox message={this.getSuccessMessageText()}/>
                        <SuccessHeaderTable claimNumber={data.claimNumber}
                                            email={this.renderEmailBoxContent(data)}/>
                        <Spacing type={"padding"} bottom={'4'}/>
                        {this.renderMyPagesButton()}
                    </SuccessHeaderLayout>
                    {!this.isIditTargetEnvironment(data) && <MaxWidthWrapper compact={true}>
                        <Step.SectionBody>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderDeductibleSection(data)}
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        {this.renderCompensationCard(data)}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.SectionBody>
                    </MaxWidthWrapper>}
                    {/*Footer*/}
                    <ComponentBackgroundWrapper theme={1}>
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            {this.renderContactUsSection()}
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        )
    }

    protected getBannerSubheaderText(): string {
        if (this.isIncidentTypeTheftOrLoss(this.data)) {
            return `${this.prefix}.success.theftOrLoss.banner.subheading`;
        }

        return super.getBannerSubheaderText(`${this.prefix}.success.damage.banner.subheading`);
    }

    protected getSuccessMessageText() {
        if (this.isIditTargetEnvironment(this.data)){
            return `general.claim.success.idit.text.information`
        }
        if (this.isIncidentTypeTheftOrLoss(this.data)) {
            return this.isSTPClaim(this.data) ? `${this.prefix}.success.theftOrLoss.stp.text.information` : super.getSuccessMessageText(`${this.prefix}.success.theftOrLoss.manual.text.information`)
        }
        if (this.isIncidentTypeDamage(this.data)) {
            return this.isSTPClaim(this.data) ? `${this.prefix}.success.damage.stp.text.information` : super.getSuccessMessageText(`${this.prefix}.success.damage.manual.text.information`)
        }
        // @ToDo - "" will log translation APM error. Most likely can be removed and is not used?
        return ''
    }

    private renderEmailBoxContent(data: MobilePhoneJourney) {
        return (this.isIncidentTypeTheftOrLoss(data) && this.isSTPClaim(data)) ||
            (this.isIditTargetEnvironment(data)) ?
            this.getContact()?.emailAddress :
            undefined
    }

    private renderDeductibleSection(data: MobilePhoneJourney): React.ReactNode {
        if (this.isIncidentTypeDamage(data) && this.isSTPClaim(data)) {

            let compensation: ClaimCompensation = {} as ClaimCompensation;
            if (data.compensations !== undefined) {
                compensation = data.compensations[0] as ClaimCompensation;
            }

            return (
                <Spacing type="padding" top="8" bottom="8">
                    <Step.SectionBody withBackground={true}>
                        <Spacing type="padding" top="8" bottom="8">
                            <B><FormattedMessage id={`${this.prefix}.success.damage.stp.deductible.title`}
                                                 values={{...compensation}}/></B>
                            <div className="helpText"><FormattedMessage
                                id={`${this.prefix}.success.damage.stp.deductible.text`}/></div>
                        </Spacing>
                    </Step.SectionBody>
                </Spacing>
            );
        }
    }

    private renderCompensationCard(data: MobilePhoneJourney): React.ReactNode {
        if (this.isIncidentTypeTheftOrLoss(data) && this.isSTPClaim(data)) {
            return (
                <Spacing type="padding" top="8" bottom="8">
                    <CompensationCard
                        title={`${this.prefix}.success.theftOrLoss.stp.compensation.title`}
                        compensations={data.compensations}/>
                </Spacing>
            );
        }
    }

    private isIncidentTypeDamage(data: MobilePhoneJourney): boolean {
        return data?.causeOfLoss === Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.Damage;
    }

    private isIncidentTypeTheftOrLoss(data: MobilePhoneJourney): boolean {
        return data?.causeOfLoss === Constants.ClassifierGeneralType.DamageOrLossIncidentTypes.TheftOrLoss;
    }
}

const MobilePhoneClaimSuccess = flowRight(
    withCmsProvider("mobilePhone"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withRouter,
    withHistoryBackConstraint
)(MobilePhoneClaimSuccessInternal);

export default MobilePhoneClaimSuccess;

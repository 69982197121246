import * as React from "react";
import omit from "lodash/omit";
import {InputComponentBase} from "./InputComponentBase";
import {Carousel, CoverageCard, Grid, QuestionSkeleton, Spacing, withViewContext} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {Markdown} from "../../../Markdown";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormContext, IFormContext} from "../FormContext";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {CoverPlan} from "@folksam-digital/model/lib";
import {ModalIframe} from "../output";

interface IMetadata {
    coverPlansToShow?: string[];
    modal: {
        title: string;
        url: string;
    };
}

class PackageSelectionCardsDynamicInternal extends InputComponentBase<string, IMetadata, {}> {
    public static contextType = FormContext;

    public render() {
        const {
            // @ts-ignore
            viewContext
        } = this.props;

        const Container = viewContext.isMobile ? Carousel : Grid.Row;
        let coverPlans: CoverPlan[] = Object.values(this.context.data.policy.premium.coverPlans);

        if (this.metadata.coverPlansToShow && this.metadata.coverPlansToShow?.length > 0) {
            const {coverPlansToShow} = this.metadata;
            coverPlans = coverPlans.filter(function (coverPlan) {
                return coverPlan?.id && !!coverPlansToShow.includes(coverPlan.id);
            })
        }

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <FormContext.Consumer>
                        {(context: IFormContext) => (
                            context.data.policy?.premium && context.data.policy?.premium?.coverPlans ?
                                <FormFieldLayout {...this.getLayoutProps()}>
                                    <Container>
                                        {
                                            coverPlans.map((coverPlan: CoverPlan) => {
                                                return coverPlan?.id && this.renderCoverPlan(coverPlan.id, cmsContext);
                                            })
                                        }
                                    </Container>
                                </FormFieldLayout>
                                :
                                <QuestionSkeleton/>
                        )}
                    </FormContext.Consumer>
                )}
            </CmsContext.Consumer>
        );
    }

    private renderCoverPlan(coverPlanId: string, cmsContext: ICmsContext) {
        const {
            formData,
            intl,
            // @ts-ignore
            viewContext,
        } = this.props;

        const {modal} = this.metadata;
        const prefix = CmsHelper.getPrefix(cmsContext);

        const Item = viewContext.isMobile ? Carousel.Item : Grid.Col;

        const coverPlan: CoverPlan = this.context.data.policy.premium?.coverPlans.find((row: CoverPlan) => row?.id === coverPlanId);
        const imageAttributes = CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, `coverageImage_${coverPlanId}`), cmsContext.catalog!);

        return (
            <Item lg={12} md={12} sm={12} key={coverPlanId}>
                <CoverageCard
                    id={coverPlanId}
                    option={{value: coverPlanId}}
                    input={{
                        value: formData,
                        onChange: () => {
                            this.onChangeWithValidation(coverPlanId)
                        }
                    }}
                    price={intl.formatMessage({id: `${prefix}.sizeOfInsurance.package.price.labelTemplate`}, {
                        ...omit(coverPlan, ["covers"]),
                        monthlyPremium: Math.round(coverPlan?.monthlyPremium || 0)
                    })}
                    buttonText={{
                        unselected: intl.formatMessage({id: `general.package.unselected`}),
                        selected: intl.formatMessage({id: `general.package.selected`})
                    }}
                >
                    <CoverageCard.Heading
                        subHeading={intl.formatMessage({id: `${prefix}.sizeOfInsurance.package.${coverPlanId}.subheading`})}
                        heading={intl.formatMessage({id: `${prefix}.sizeOfInsurance.package.${coverPlanId}.heading`})}
                        image={imageAttributes}
                    />
                    <CoverageCard.Section>
                        <Markdown
                            source={this.props.intl.formatMessage({id: `${prefix}.sizeOfInsurance.package.${coverPlanId}.list`})}/>
                        <div className="spaceTop">
                            <ModalIframe url={modal.url} title={<FormattedMessage id={modal.title}/>}
                                         viewContext={viewContext}/>
                        </div>
                    </CoverageCard.Section>
                </CoverageCard>
                <Spacing bottom={5}/>
            </Item>
        );
    }
}

const PackageSelectionCards = injectIntl(withViewContext(PackageSelectionCardsDynamicInternal));
export {PackageSelectionCards};

import sanitizeField from "../helpers/sanitizeField";
import extractRecordValues from "../helpers/extractRecordValues";

const defaultPlaceRequest = {
    fields: ['name'] // https://developers.google.com/maps/documentation/javascript/reference/places-widget#Autocomplete.setFields
};

export type IValuePlaceCallBack = (suggestions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => void;

export function valuePlaceCallBack(this: any, suggestions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus): void {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
        // @ToDo possibly improved to not use any found suggestion by input value
        // current clarification: to use similarly as in stst map with any suggestion found via input value.
        const firstFoundSuggestion = suggestions.find(suggestion => suggestion.description && suggestion.place_id);

        if (firstFoundSuggestion) {
            // set place, when component mounts, or when selected suggestion place_id differs from AutocompleteSuggestion selected.
            this.setPlace(firstFoundSuggestion)
        }
    }
}

export type ISetPlaceValueCallback = (record: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => void;

export function setPlaceValueCallback(this: any, record: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus): void {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
        const {placesRequest = defaultPlaceRequest, googleMapOptions} = this.metadata;

        const value = sanitizeField(extractRecordValues(placesRequest.fields, record, googleMapOptions ? ["geometry"] : undefined));
        this.props.onChange(value);
        this.renewSessionToken();
        if (googleMapOptions && record.geometry) {
            this.setState({
                geometry: record.geometry,
                withPlaces: this.state.withPlaces === undefined ? undefined : true
            })
        }
    }
}

export type ISetPlace = (suggestion: google.maps.places.AutocompletePrediction) => void;

export function setPlace(this: any, suggestion: google.maps.places.AutocompletePrediction): void {
    const {placesRequest = defaultPlaceRequest, googleMapOptions} = this.metadata;

    if (googleMapOptions) {
        // default geometry in placesRequest as it's necessary to have in res for Marker.
        placesRequest.fields = placesRequest.fields ? placesRequest.fields : [];
        placesRequest.fields.push("geometry")
    }
    if (suggestion && suggestion.place_id) {
        this.placesService.getDetails(
            {
                placeId: suggestion.place_id,
                ...placesRequest,
                sessionToken: this.state.sessionToken
            },
            this.setPlaceValueCallback
        );
        if (googleMapOptions) {
            this.setState({geometry: undefined});
        }
    }
}

export function renewSessionToken(this: any): void {
    const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    this.setState({
        sessionToken
    });
}

import "reflect-metadata";
import "@folksam-digital/ui/dist/docs/font/fontFamily.css";
import React, {Component, Suspense} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {ProgressBarInfinite, UIProvider} from "@folksam-digital/ui";
import {LocaleProvider} from "./intl/LocaleProvider";
import PregnancyJourneyMaleSSN from "./Routers/Journey/product/PregnancyJourneyMaleSSN";
import DirectDebitRoutes from "./Routers/DirectDebit";
import JourneySuccessRoutes from "./Routers/Journey/product/success";
import ClaimJourneySuccessRoutes from "./Routers/Journey/claim/success";
import {IRoute} from "./Routers/IRoute";
import JourneyError from "./Routers/Journey/JourneyError";
import ShoppingCartSuccessPage from "./Routers/Journey/group/shoppingCart/SuccessPage";
import OptInOptOutSuccessPage from "./Routers/Journey/group/optInOptOut/SuccessPage";
import MemberNotFoundError from "./Routers/Journey/group/optInOptOut/MemberNotFoundError";
import JourneyType from "@folksam-digital/model/lib/constants/JourneyType";
import {
    FeatureRoute,
    FeatureRouteGroupWithInitialParams,
    FeatureRouteWithInitialParams
} from "./components/general/featureToggle/FeatureRoute";
import ClaimError from "./Routers/Journey/claim/ClaimError";
import {
    ApmRoute,
    ApmRouteGroupWithInitialParams,
    ApmRouteWithInitialParams,
    ClaimRouteWithInitialParams
} from "./components/general/elasticApmRum/ApmRoute";
import {Constants} from "@folksam-digital/model";
import IncomeInsuranceSuccessRoutes from "./Routers/Journey/incomeInsurance/success";
import IncomeInsuranceError from "./Routers/Journey/incomeInsurance/IncomeInsuranceError";
import {UrlHelper} from "@folksam-digital/services";
import {PoliciesFailedError} from "./Routers/Journey/group/optInOptOut/PoliciesFailedError";

// Lazy import different journey types so that models are bundled separately
const JourneyView = React.lazy(() => import("./page/journey/JourneyView"));
const ClaimView = React.lazy(() => import("./page/journey/ClaimView"));
const ShoppingCartView = React.lazy(() => import("./page/group/ShoppingCartView"));
const OptInOptOutView = React.lazy(() => import("./page/group/OptInOptOutView"));
const IncomeInsuranceView = React.lazy(() => import("./page/journey/IncomeInsuranceView"));

class App extends Component {
    public render() {
        const baseUrl = UrlHelper.getBaseUrl();
        return (
            <UIProvider>
                <Router>
                    <LocaleProvider>
                        <Suspense fallback={<ProgressBarInfinite duration={20}/>}>
                            <Switch>
                                {/*Map Product Success routes*/}
                                {JourneySuccessRoutes.map((route: IRoute) => (
                                        <FeatureRoute key={route.path}
                                                      journeyType={JourneyType.Journey}
                                                      path={`${baseUrl}${JourneyType.Journey}/${route.path}/success`}
                                                      exact={true}
                                                      component={route.component} featureName={route.path}/>
                                    )
                                )}

                                {/*Map Claim Success routes*/}
                                {ClaimJourneySuccessRoutes.map((route: IRoute) => (
                                        <FeatureRoute key={route.path}
                                                      path={`${baseUrl}${route.path}/success`}
                                                      exact={true}
                                                      journeyType={route.journeyType as any}
                                                      component={route.component} featureName={route.featureName}/>
                                    )
                                )}

                                {/*Map DirectDebit routes*/}
                                {DirectDebitRoutes.map((route: IRoute) => (
                                        <FeatureRoute key={route.path} path={`${baseUrl}direct-debit/${route.path}`}
                                                      exact={true}
                                                      journeyType={JourneyType.DirectDebit}
                                                      component={route.component} featureName={"direct-debit"}/>
                                    )
                                )}

                                {/*Map Income Insurance Success routes*/}
                                {IncomeInsuranceSuccessRoutes.map((route: IRoute) => (
                                    <ApmRouteWithInitialParams
                                        key={route.path}
                                        path={`${baseUrl}${JourneyType.JourneyProtected}/${route.path}/success`}
                                        exact={true}
                                        journeyType={JourneyType.JourneyProtected}
                                        component={route.component}
                                        featureName={route.featureName}/>
                                    )
                                )}
                                {/*Add individual routes*/}
                                <ApmRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/error`}
                                          journeyType={JourneyType.Group}
                                          exact={true}
                                          component={JourneyError}/>
                                <FeatureRouteWithInitialParams path={`${baseUrl}${JourneyType.JourneyProtected}/${Constants.Journey.IncomeChange.Id}/:slug?`}
                                              journeyType={JourneyType.JourneyProtected} exact={true}
                                              featureName={Constants.Journey.IncomeChange.Id}>
                                    <IncomeInsuranceView journeyId={Constants.Journey.IncomeChange.Id} />
                                </FeatureRouteWithInitialParams>
                                <FeatureRouteWithInitialParams path={`${baseUrl}${JourneyType.JourneyProtected}/${Constants.Journey.IncomeAddon.Id}/:slug?`}
                                              journeyType={JourneyType.JourneyProtected}
                                              exact={true}
                                              featureName={Constants.Journey.IncomeAddon.Id}>
                                    <IncomeInsuranceView journeyId={Constants.Journey.IncomeAddon.Id} />
                                </FeatureRouteWithInitialParams>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/memberNotFound`}
                                              journeyType={JourneyType.Group} exact={true}
                                              component={MemberNotFoundError} featureName={"optInOptOut"}/>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/policiesFailed`}
                                              journeyType={JourneyType.Group} exact={true}
                                              component={PoliciesFailedError} featureName={"optInOptOut"}/>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/shoppingCart/success`}
                                              exact={true}
                                              journeyType={JourneyType.Group}
                                              component={ShoppingCartSuccessPage}
                                              featureName={"shoppingCart"}/>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/optInOptOut/success`}
                                              journeyType={JourneyType.Group}
                                              exact={true}
                                              component={OptInOptOutSuccessPage}
                                              featureName={"optInOptOut"}/>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/shoppingCart/:slug?`}
                                              exact={true}
                                              component={ShoppingCartView}
                                              journeyId="shoppingCart"
                                              journeyType={JourneyType.Group}
                                              featureName={"shoppingCart"}/>
                                <FeatureRouteGroupWithInitialParams path={`${baseUrl}${JourneyType.Group}/optInOptOut/:slug?`}
                                              exact={true}
                                              component={OptInOptOutView}
                                              journeyType={JourneyType.Group}
                                              featureName={"optInOptOut"}
                                              journeyId="optInOptOut"/>
                                <ApmRoute path={`${baseUrl}${JourneyType.Journey}/error`}
                                          exact={true}
                                          journeyType={JourneyType.Journey}
                                          component={JourneyError}/>
                                <ApmRoute path={`${baseUrl}${JourneyType.JourneyProtected}/error`}
                                          exact={true}
                                          journeyType={JourneyType.JourneyProtected}
                                          component={IncomeInsuranceError}/>
                                <ApmRoute path={`${baseUrl}${JourneyType.ClaimProtected}/error`}
                                          exact={true}
                                          journeyType={JourneyType.ClaimCreationErrorProtected}
                                          component={ClaimError}/>
                                <ApmRoute path={`${baseUrl}${JourneyType.ClaimUnprotected}/error`}
                                          exact={true}
                                          journeyType={JourneyType.ClaimCreationErrorUnprotected}
                                          component={ClaimError}/>
                                <FeatureRoute
                                    path={`${baseUrl}${JourneyType.Journey}/${Constants.ObfuscatedJourneyId.Pregnancy}/${Constants.Journey.Pregnancy.Steps.maleSsn}`}
                                    exact={true}
                                    component={PregnancyJourneyMaleSSN}
                                    journeyType={JourneyType.Journey}
                                    featureName={Constants.Journey.Pregnancy.Id}/>
                                <ClaimRouteWithInitialParams path={`${baseUrl}${JourneyType.ClaimProtected}/:journeyId/:slug?`}
                                              exact={true}
                                              component={ClaimView}
                                              journeyType={JourneyType.ClaimProtected}/>
                                <ClaimRouteWithInitialParams path={`${baseUrl}${JourneyType.ClaimUnprotected}/:journeyId/:slug?`}
                                              exact={true}
                                              component={ClaimView}
                                              journeyType={JourneyType.ClaimUnprotected}/>
                                <FeatureRoute path={`${baseUrl}${JourneyType.JourneyProtected}/:journeyId/:slug?`}
                                              exact={true}
                                              component={JourneyView}
                                              journeyType={JourneyType.JourneyProtected}/>
                                <FeatureRoute path={`${baseUrl}${JourneyType.Journey}/:journeyId/:slug?`}
                                              exact={true}
                                              component={JourneyView}
                                              journeyType={JourneyType.Journey}/>
                                <FeatureRoute path={`${baseUrl}${JourneyType.JourneyProtected}/:journeyId/:slug?`}
                                              exact={true}
                                              component={JourneyView}
                                              journeyType={JourneyType.JourneyProtected}/>
                            </Switch>
                        </Suspense>
                    </LocaleProvider>
                </Router>
            </UIProvider>
        );
    }
}

export default App;

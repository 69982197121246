import {ClaimBase, ClaimJourneyBase, Constants, IJourneySchema} from "@folksam-digital/model";
import {FeatureToggleUtils, UrlHelper} from "@folksam-digital/services";
import * as React from "react";
import {RouteComponentProps} from "react-router";
import {
    IOnTriggerDataLayerEvent,
    SatelliteEvents,
    SatelliteEventTransactionTypes
} from "../../../../analytics/new/BaseAnalytics";
import {ICmsContext} from "../../../../cms";
import {MyPagesButton, ProductContactUsSection} from "../../../../components/journey/layout/Success";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {container} from "../../../../inversify.config";
import {IDraftService, StorageKeys} from "../../../../services";
import {Types} from "../../../../Types";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";

export interface IClaimSuccessBaseProps extends RouteComponentProps<any, any, any>, IOnTriggerDataLayerEvent {
}

export default abstract class ClaimSuccessBase<TData extends ClaimJourneyBase<any, any>, TProps extends IClaimSuccessBaseProps> extends React.Component<IClaimSuccessBaseProps> {
    protected readonly isInterimEmailSolutionEnabled: boolean;
    protected draftService: IDraftService;
    protected journeyId: string;

    protected constructor(props: TProps, journeyId: string, context?: ICmsContext) {
        super(props, context);

        this.journeyId = journeyId;

        this.isInterimEmailSolutionEnabled = FeatureToggleUtils.isInterimEmailSolutionEnabledForJourney(journeyId);

        this.draftService = container.get<IDraftService>(Types.DraftService);

        document.title = this.context.catalog.messages[CmsHelper.withPrefix(this.context, "title.message")];
    }

    public get data(): TData {
        return this.draftService.getDraft<TData>();
    }

    public checkData(journeyId: string): void {
        if (!this.data) {
            const schema = {} as IJourneySchema;
            this.draftService.updateDraft({
                journeyId: journeyId,
                backUrl: schema?.backUrl
            }, StorageKeys.JOURNEY_ERROR_DATA_KEY);

            this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
            return;
        } else {
            setJourneyCompleted(this.draftService, journeyId);
        }
    }

    public abstract render(): React.ReactNode

    protected triggerDataLayerEvent(journeyId: string) {
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            data: this.data,
            journeyId: journeyId,
            currentStep: "success",
            transactionType: SatelliteEventTransactionTypes.ClaimFlow,
            event: SatelliteEvents.Complete
        });
    }

    protected renderMyPagesButton() {
        const isAppWebView: boolean = this.data?.agentId === Constants.AgentId.AppWebView;
        
        return isAppWebView
            ? undefined
            : <MyPagesButton myPagesButtonTitle="general.claim.success.bannerPanel.myPages.header"/>
    }

    protected getContact() {
        return this.data?.informant;
    }

    protected renderContactUsSection() {
        return <ProductContactUsSection displayContactUs={false}
                                        privacyMessageKey={this.isIditTargetEnvironment(this.data) ? "general.claim.success.idit.privacy.text" : undefined}
        />
    }

    protected isSTPClaim(data: ClaimBase<any>): boolean {
        return data.claimStatus === Constants.ClaimStatus.STP;
    }

    protected isIditTargetEnvironment(data: ClaimBase<any>): boolean {
        return data?.targetEnv === Constants.TargetEnvironment.Idit;
    }

    protected getBannerSubheaderText(messageKeyPrefix: string): string {
        if (this.isIditTargetEnvironment(this.data)){
            return `general.claim.success.idit.banner.subheading`
        }

        return messageKeyPrefix;
    }

    protected getSuccessMessageText(messageKeyPrefix: string): string {
        if (this.isIditTargetEnvironment(this.data)){
            return `general.claim.success.idit.text.information`
        }

        return messageKeyPrefix;
    }
}

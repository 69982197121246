import * as React from "react";
import {IFormContext} from "../../../journey/form/FormContext";
import {
    DescriptionListResponsive,
    SummaryCard,
    DescriptionList,
    IconCheckMarkV2,
    UmbrellaIcon,
    IconHeart,
    TeddyBearIcon,
    defaultTheme as theme,
    Spacing
} from "@folksam-digital/ui";
import {ShoppingCartContact} from "@folksam-digital/model";
import {FormattedMessage} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CmsContext} from "../../../../cms";
import {FormattedPrice} from "../../../formFieldLayout/FormattedPrice";

export enum OfferGroupKeys {
    member = 'member',
    partner = 'partner',
    children = 'children'
}

const headerIconMapByGroup = {
    [OfferGroupKeys.member]: <UmbrellaIcon
        color={theme.colors.brand}
        fill={theme.colors.primary2}
    />,
    [OfferGroupKeys.partner]: <IconHeart
        color={theme.colors.transparent}
        fill={theme.colors.brand}/>,
    [OfferGroupKeys.children]: <TeddyBearIcon/>
};

interface IListedOffer extends ShoppingCartContact {
    order?: number;
}

type IListedOffers = {
    [key in keyof typeof OfferGroupKeys]: IListedOffer[];
};

interface IShoppingCartProductListProps {
    renderRedirectControl?: (key: OfferGroupKeys) => JSX.Element;
    context: IFormContext;
}

class ShoppingCartProductList extends React.Component<IShoppingCartProductListProps, {}> {

    public static contextType = CmsContext;

    render() {

        const {context} = this.props;
        const groupedOffers = this.getOffersGroupedByType(context.data);

        return (
                Object.entries(groupedOffers).map(([key, value], idx) => {

                    const addMarginTop: boolean = idx > 0;

                    return this.renderSummaryCardSection(key as OfferGroupKeys, value, context, addMarginTop);
                })
        )
    }

    private renderSummaryCardSection(key: OfferGroupKeys, sections: IListedOffer[], context: IFormContext, addMarginTop: boolean) {
        return (
            sections.map((section) => (
                <>
                    {addMarginTop && <Spacing type={"margin"} top={"sm"}/>}
                    <SummaryCard customHeader={
                        <SummaryCard.Header theme={5} noPaddingSide>
                            <SummaryCard.HeaderIcon
                                large
                                iconLeft
                                component={headerIconMapByGroup[key]} />
                            <SummaryCard.HeaderText>
                                <FormattedMessage id={CmsHelper.withPrefix(this.context, this.getSummaryCardTitleKey(key))}
                                    values={{number: section.order}} />
                            </SummaryCard.HeaderText>
                            <DescriptionListResponsive.Header>
                                {this.renderHeaderContent(key)}
                            </DescriptionListResponsive.Header>
                        </SummaryCard.Header>
                    }>
                        <SummaryCard.Body>
                            <DescriptionList>
                                <DescriptionList.Row>
                                    <DescriptionList.Content>
                                        <DescriptionList.Term>
                                            <FormattedMessage
                                                id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.row.contact.title")} />
                                        </DescriptionList.Term>
                                        <DescriptionList.Definition>
                                            {this.getSummaryCardContactName(section)}
                                        </DescriptionList.Definition>
                                    </DescriptionList.Content>
                                </DescriptionList.Row>
                                {
                                    section.offers?.map((policy) => {
                                        return policy.selected && (<DescriptionList.Row key={policy?.id}>
                                            <DescriptionList.Content>
                                                <DescriptionList.Term>
                                                    <FormattedMessage
                                                        id={CmsHelper.withPrefix(this.context, "reviewAndSubmit.summaryList.row.insuranceSelected.term")} />
                                                </DescriptionList.Term>
                                                <DescriptionList.Definition>
                                                    {policy.productTitle}
                                                    {<DescriptionList.Price
                                                        primary
                                                        component={<FormattedPrice
                                                            suffixId={"general.monthlyPremium.suffix"}
                                                            value={Math.round(policy.monthlyPremium!)}
                                                        />}
                                                    />}
                                                </DescriptionList.Definition>
                                            </DescriptionList.Content>
                                        </DescriptionList.Row>)
                                    }
                                    )
                                }
                            </DescriptionList>
                        </SummaryCard.Body>
                    </SummaryCard>
                </>
            ))
        );
    }

    private renderHeaderContent(key: OfferGroupKeys) {
        const {renderRedirectControl} = this.props;

        return !!renderRedirectControl ? renderRedirectControl(key) :
            <SummaryCard.HeaderIcon
                large={true}
                component={<IconCheckMarkV2 color={theme.colors.primary2}/>}
            />
    }

    private getOffersGroupedByType(data: any) {
        const listedOffers: IListedOffers = {
            [OfferGroupKeys.member]: [],
            [OfferGroupKeys.partner]: [],
            [OfferGroupKeys.children]: []
        };


        if (data?.children && data.children.length > 0) {
            data.children.forEach((child: any, idx: number) => {
                if (child.offers && child.offers.length > 0) {
                    listedOffers[OfferGroupKeys.children].push({
                        contact: child.contact,
                        offers: child.offers,
                        order: idx + 1
                    })
                }
            })
        }

        if (data?.member?.offers && data.member.offers.length > 0) {
            listedOffers[OfferGroupKeys.member].push({
                contact: data.member.contact,
                offers: data.member.offers
            });
        }

        if (data?.partner?.offers && data.partner.offers.length > 0) {
            listedOffers[OfferGroupKeys.partner].push({
                contact: data.partner.contact,
                offers: data.partner.offers
            });
        }

        return listedOffers;
    }

    private getSummaryCardTitleKey(key: OfferGroupKeys): string {
        switch (key) {
            case OfferGroupKeys.member:
                return "reviewAndSubmit.insuranceForYou.title";
            case OfferGroupKeys.partner:
                return "reviewAndSubmit.insuranceForYourPartner.title";
            case OfferGroupKeys.children:
                return "reviewAndSubmit.insuranceForYourChild.title";
            default:
                return "";
        }
    };

    private getSummaryCardContactName(offer: IListedOffer) {
        return `${offer.contact.firstName} ${offer.contact.lastName}`
    };
}

export default ShoppingCartProductList;

import * as React from "react";
import {Button, ConstrainWidth, Link} from "@folksam-digital/ui";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {FormContext, IFormContext} from "../FormContext";
import {withRouter} from "react-router";
import {IFormComponentProps} from "../FormComponentBase";
import {OutputComponentBase} from "./OutputComponentBase";
import {onBtnClick} from "./helpers";
import isObject from "lodash/isObject";
import {LinkButton} from "../input/LinkButton";
import {ModalLink} from "./ModalLink";
import {MarkdownListTypes} from "@folksam-digital/model";

interface IMetadata {
    title: string;
    url: string | { link?: string, external?: boolean };
    classNames?: string;
    modalHeader?: string;
    modalContent?: string;
    listType?: MarkdownListTypes;
    customStyles?: React.CSSProperties;
}

class LinkInternal extends OutputComponentBase<void, IMetadata, {}> {
    public render() {
        const {title} = this.schema;
        const {
            url,
            classNames,
            modalHeader,
            modalContent,
            customStyles,
            listType
        } = this.metadata;

        if (isObject(url) && url?.external) {
            return (<ConstrainWidth className={classNames}><LinkButton url={url}> <FormattedMarkdown messageKey={title}/> </LinkButton></ConstrainWidth>)
        }

        // new implementation of opening link in modal(used in HomeBuilding)
        if (title && (modalHeader || modalContent)) {
            return (<ModalLink style={customStyles} linkText={title} modalHeader={modalHeader} modalContent={modalContent} listType={listType}/>)
        }

        const isExternalLink = /^(http|https):\/\//.test(url as string);
        return (
            <FormContext.Consumer>
                {(context: IFormContext) => (
                    <ConstrainWidth className={classNames}>
                        {isExternalLink
                            ?
                            <Link to={url} style={customStyles}><FormattedMarkdown messageKey={title}/></Link>
                            :
                            (<Button type="button"
                                     style={customStyles}
                                     onClick={(event: any) => onBtnClick(event, url as string, context, this.props)}
                                     asLink={true}>
                                <FormattedMarkdown messageKey={title}/>
                            </Button>)
                        }
                    </ConstrainWidth>)
                }
            </FormContext.Consumer>
        );
    }
}

const LinkOutput = withRouter<IFormComponentProps<void, IMetadata>, React.ComponentType<IFormComponentProps<void, IMetadata>>>(LinkInternal);
export {LinkOutput};

import {
    BaseAnalytics,
    BaseMapping,
    IOnTriggerDataLayerEventParams
} from "./BaseAnalytics";
import {
    ClaimBase,
    Constants,
    DelayedLuggage,
    PersonalAccident,
    PregnancyComplications
} from "@folksam-digital/model/lib";
import moment from "moment";
import {IMessages} from "../../cms";
import isEmpty from "lodash/isEmpty";
import {UrlHelper} from "@folksam-digital/services";
import {SatelliteEvents} from "../new/BaseAnalytics";

export interface ClaimMapping extends BaseMapping {
    claim_process: string;
    claim_product: string;
    claim_start_option?: string;
    domain?: string;
    age?: string;
    claim_detail?: string[];
    claim_type?: string[];
    gender?: string;
    pageurl?: string;
    claimId?: string;
}

export class ClaimAnalytics extends BaseAnalytics {
    public async onTriggerDataLayerEvent(params: IOnTriggerDataLayerEventParams): Promise<void> {
        const journeyName: string = params.messages[`general.adobe.claim.${params.journeyId!.toString()}`];
        const isInformant: boolean | undefined = this.getIsInformant(params.data);
        const claimantAge: number | undefined = this.getClaimantAge(params.data);
        const causeOfLoss: { [key: string]: string[] } = this.getCauseOfLoss(params);
        const damageTypes: { [key: string]: string[] } = this.getClaimDamageTypes(params);

        let dataLayerObj: ClaimMapping = await this.getDefaultClaimMapping(journeyName, params);

        if (isInformant !== undefined) {
            dataLayerObj.claim_start_option = isInformant ? params.messages["general.adobe.claim.startOption.self"] : params.messages["general.adobe.claim.startOption.other"];
        }
        if (claimantAge && params.journeyId!.toString() !== Constants.Journey.PregnancyComplications.Id) {
            dataLayerObj.age = `${claimantAge}`;
        }

        // @ToDo - currently specific to cat/dog but in future if requirements change can be improved for all claims.
        if (params.journeyId === Constants.Journey.DogClaim.Id || params.journeyId === Constants.Journey.CatClaim.Id) {
            if (params.data?.contact?.gender) {
                dataLayerObj.gender = params.data?.contact?.gender ? params.messages[`general.adobe.gender.${params.data.contact.gender}`] : "";
                dataLayerObj.age = (params.data?.contact?.dateOfBirth && moment.utc(params.data?.contact?.dateOfBirth)?.year()?.toString()) || "";
            }
            dataLayerObj.pageurl = UrlHelper.getClaimAnalyticsPageURL(params?.journeyId!, window.origin, params?.currentStep);

            // remove of not listed dataLayerObj properties
            delete dataLayerObj.sitesection5;
            delete dataLayerObj.claim_start_option;
            delete dataLayerObj.domain;
        }

        if (params.data?.claimNumber){
            dataLayerObj.claimId = params.data.claimNumber;
        }

        if (!isEmpty(causeOfLoss)) {
            dataLayerObj = {
                ...dataLayerObj,
                ...causeOfLoss
            };
        }
        if (!isEmpty(damageTypes)) {
            dataLayerObj = {
                ...dataLayerObj,
                ...damageTypes
            };
        }

        let trackKey = `${params.transactionType}_${params.event}`;

        if (params.event === SatelliteEvents.Step) {
            const nextStepIndex: number = params.currentStepIndex! + 1;
            trackKey = `${trackKey}_${nextStepIndex}`;
        }

        this.setDataLayer(dataLayerObj, trackKey);
    }

    private async getDefaultClaimMapping(journeyName: string, params: IOnTriggerDataLayerEventParams): Promise<ClaimMapping> {
        const pageName: string = this.getPageName(params);
        const pageStart = this.getPageStart(params.journeyId!, params.messages);
        const pageType = this.getPageType(params.journeyId!, params.messages);

        return {
            customer_id: await this.getUserId(params.data?.contact?.externalContactNumber),
            pagename: `${pageStart}|${pageType}|${journeyName}|${pageName}`,
            pagetype: `${pageType}`,
            sitesection1: `${pageStart}`,
            sitesection2: `${pageStart}|${pageType}`,
            sitesection3: `${pageStart}|${pageType}|${journeyName}`,
            sitesection4: `${pageStart}|${pageType}|${journeyName}|${pageName}`,
            sitesection5: `${pageStart}|${pageType}|${journeyName}|${pageName}|`,
            claim_process: this.getClaimProcessType(params.journeyId!, params.messages, journeyName),
            claim_product: this.getClaimProductType(params.journeyId!, params.messages),
            domain: "folksam.se",
        } as ClaimMapping;
    }

    protected getPageName(params: IOnTriggerDataLayerEventParams): string {
        let pageName = "";
        switch (params.journeyId) {
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                if (this.isStepTypeError(params.currentStep)) {
                    pageName = params.messages["general.adobe.error"];
                } else if (this.isStepTypeSuccess(params.currentStep)) {
                    pageName = params.messages["general.adobe.legacyStep.success"];// key reused
                } else {
                    pageName = params.messages[`general.adobe.claimStep.${params.journeyId}.${Number(params.currentStepIndex || 0) + 1}`];
                }
                break;
            default:
                pageName = super.getPageName(params);
                break;
        }

        return pageName;
    }

    private getPageStart(journeyId: string, messages: IMessages): string {
        let key: string;
        switch (journeyId) {
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                key = `general.adobe.claim.pageStart.application`;
                break;
            default:
                key = `general.adobe.claim.pageStart`;
                break;
        }

        return messages[key];
    }

    private getPageType(journeyId: string, messages: IMessages): string {
        let key: string;
        switch (journeyId) {
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                key = `general.adobe.claim.pageType.damage`;
                break;
            default:
                key = `general.adobe.claim.pageType`;
                break;
        }

        return messages[key];
    }

    private getIsInformant(data: ClaimBase<any>): boolean | undefined {
        return data?.claimant?.isInformant;
    }

    private getClaimantAge(data: ClaimBase<any>): number | undefined {
        return data?.claimant?.contact?.dateOfBirth ? moment(data.claimant.contact.dateOfBirth).year() : undefined;
    }

    private getCauseOfLoss(params: IOnTriggerDataLayerEventParams): { [key: string]: string[] } {
        if (params.journeyId === Constants.Journey.PersonalAccident.Id) {
            const data: ClaimBase<PersonalAccident> = params.data;
            return data?.causeOfLoss ? {
                claim_detail: [params.messages[`general.adobe.claim.${params.journeyId!.toString()}.${data.causeOfLoss}`]]
            } : {};
        }

        return {};
    }

    private getClaimDamageTypes(params: IOnTriggerDataLayerEventParams): { [key: string]: string[] } {
        switch (params.journeyId) {
            case Constants.Journey.PersonalAccident.Id:
                return this.getPersonalAccidentDamageTypes(params.data, params.messages, params.journeyId);
            case Constants.Journey.PregnancyComplications.Id:
                return this.getPregnancyComplicationsDamageTypes(params.data, params.messages, params.journeyId);
            case Constants.Journey.DelayedLuggage.Id:
                return this.getDelayedLuggageDamageTypes(params.data, params.messages, params.journeyId);
            default:
                return {};
        }
    }

    private getPersonalAccidentDamageTypes(data: ClaimBase<PersonalAccident>, messages: IMessages, journeyId: string): { [key: string]: string[] } {
        const damageTypes: { [key: string]: string[] } = {
            claim_type: []
        };
        if (data?.damage?.isBodilyInjury) {
            damageTypes["claim_type"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.Bodily}`]);
        }
        if (data?.damage?.isDentalInjury) {
            damageTypes["claim_type"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.Dental}`]);
        }
        return damageTypes["claim_type"].length > 0 ? damageTypes : {};
    }

    private getPregnancyComplicationsDamageTypes(data: ClaimBase<PregnancyComplications>, messages: IMessages, journeyId: string): { [key: string]: string[] } {
        const damageTypes: { [key: string]: string[] } = {
            claim_detail: []
        };
        if (data?.damage?.isPregnancyComplications) {
            damageTypes["claim_detail"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.PregnancyComplications}`]);
        }
        if (data?.damage?.isBirthComplications) {
            damageTypes["claim_detail"].push(messages[`general.adobe.claim.${journeyId}.${Constants.DamageType.BirthComplications}`]);
        }
        return damageTypes["claim_detail"].length > 0 ? damageTypes : {};
    }

    private getDelayedLuggageDamageTypes(data: ClaimBase<DelayedLuggage>, messages: IMessages, journeyId: string): { [key: string]: string[] } {
        const damageTypes: { [key: string]: string[] } = {
            claim_type: []
        };

        if (data?.causeOfLoss) {
            damageTypes["claim_type"].push(messages[`general.adobe.claim.${journeyId}.${data?.causeOfLoss}`]);
        }

        return damageTypes["claim_type"].length > 0 ? damageTypes : {};
    }

    private getClaimProductType(journeyId: string, messages: IMessages): string {
        switch (journeyId) {
            case Constants.Journey.Burglary.Id:
            case Constants.Journey.Fire.Id:
            case Constants.Journey.Glass.Id:
            case Constants.Journey.Nature.Id:
            case Constants.Journey.OtherDamageOrLoss.Id:
            case Constants.Journey.Storm.Id:
            case Constants.Journey.Theft.Id:
            case Constants.Journey.Water.Id:
            case Constants.Journey.HouseholdAppliances.Id:
            case Constants.Journey.Bicycle.Id:
                return messages["general.adobe.claim.claimProduct.boende"];
            case Constants.Journey.AccidentDuringTravel.Id:
            case Constants.Journey.IllnessDuringTravel.Id:
            case Constants.Journey.CancelledTravel.Id:
            case Constants.Journey.DelayedTraveler.Id:
            case Constants.Journey.DelayedLuggage.Id:
                return messages["general.adobe.claim.claimProduct.rese"];
            case Constants.Journey.VehicleCollision.Id:
            case Constants.Journey.VehicleCollisionWithAnimal.Id:
            case Constants.Journey.VehicleSingleAccident.Id:
            case Constants.Journey.VehicleHitAndRunAccident.Id:
            case Constants.Journey.VehicleVandalism.Id:
            case Constants.Journey.VehicleTheftOrBurglary.Id:
            case Constants.Journey.VehicleFire.Id:
            case Constants.Journey.VehicleEngineDamage.Id:
            case Constants.Journey.VehicleInteriorDamage.Id:
            case Constants.Journey.VehicleLossOfKey.Id:
            case Constants.Journey.VehicleMisfuelling.Id:
                return messages["general.adobe.claim.claimProduct.motor"];
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                return messages["general.adobe.claim.claimProduct.animal"];
            default:
                return messages["general.adobe.claim.claimProduct.person"];
        }
    }

    private getClaimProcessType(journeyId: string, messages: IMessages, journeyName: string): string {
        switch (journeyId) {
            case Constants.Journey.AccidentDuringTravel.Id:
            case Constants.Journey.IllnessDuringTravel.Id:
            case Constants.Journey.CancelledTravel.Id:
            case Constants.Journey.DelayedTraveler.Id:
            case Constants.Journey.DelayedLuggage.Id:
            case Constants.Journey.DogClaim.Id:
            case Constants.Journey.CatClaim.Id:
                return messages[`general.adobe.claim.claimProcess.${journeyId}`];
            case Constants.Journey.Fire.Id:
            case Constants.Journey.VehicleFire.Id:
                return `${this.getClaimProductType(journeyId, messages)}|${journeyName}`;
            default:
                return journeyName;
        }
    }
}

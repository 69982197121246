import * as React from "react";
import {injectIntl, FormattedMessage} from "react-intl";
import {InsuranceAmount, Cover, CoverPlan} from "@folksam-digital/model";
import {FormInputRadioButton, QuestionSkeleton, B, Spacing} from "@folksam-digital/ui";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldGroupLayout} from "../../../FormFieldGroupLayout";
import {FormContext} from "../FormContext";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";

interface IMetadata {
    coverId: number;
    classNames?: string;
    help?: string;
}

class AmountOptionRadioGroupInternal extends InputComponentBase<number, IMetadata, {}> {
    public static contextType = FormContext;

    public render() {
        const {
            name,
            formData
        } = this.props;

        const { coverId } = this.metadata;

        const covers = this.context?.data?.policy?.premium?.coverPlans.find((row: CoverPlan) => row.id === this.context.data.policy.coverPlan).covers as Cover[];
        const insuranceAmounts = this.getInsuranceAmountOptions(coverId, covers) as InsuranceAmount[];

        return (
            <FormFieldGroupLayout {...this.getLayoutProps()}>
                <CmsContext.Consumer>
                    {(cmsContext: ICmsContext) =>
                        insuranceAmounts.length > 0 ?
                            insuranceAmounts.map((option, index: number) => (
                                <Spacing type={"margin"} top={'2'} key={`${name}-${index}`}>
                                    <FormInputRadioButton
                                        id={`${name}-${index}`}
                                        name={name}
                                        label={
                                            <B><FormattedMessage
                                                id={CmsHelper.withPrefix(cmsContext, "sizeOfInsurance.diagnosticInsuranceCompensation.size.labelTemplateDescription")}
                                                values={{...option}}/></B>
                                        }
                                        value={option.levelNumber}
                                        onChange={(event: any) => this.onChangeWithValidation(parseInt(event.target.value, 10))}
                                        checked={formData === option.levelNumber}
                                    />
                                </Spacing>
                            ))
                            :
                            <QuestionSkeleton/>
                    }
                </CmsContext.Consumer>
            </FormFieldGroupLayout>
        );
    }

    private getInsuranceAmountOptions(id: number, covers?: Cover[]): InsuranceAmount[] | undefined {
        if (covers && covers.length > 0) {
            for (const cover of covers) {
                if (cover.availableInsuranceAmounts && cover.id === id) {
                    return cover.availableInsuranceAmounts;
                }
            }
        }
    }
}

const AmountOptionRadioGroup = injectIntl(AmountOptionRadioGroupInternal);
export {AmountOptionRadioGroup};

import * as React from "react";
import {
    FormInput,
    Headings,
    ButtonResponsive,
    Spacing,
    DescriptionListResponsive,
    withViewContext
} from "@folksam-digital/ui";
import { FormattedMessage, injectIntl } from "react-intl";
import {DeepPartial, InputType, InsuredPersonContact} from "@folksam-digital/model";
import { SsnFormatter } from "@folksam-digital/services";
import { FormFieldLayout } from "../../../FormFieldLayout";
import FormattedMarkdown from "../../../FormattedMarkdown";
import { InputComponentBase, IInputComponentProps } from "../../../journey/form/input/InputComponentBase";
import { RemoveButton } from "../../../journey/form/containers/personSsnInput/RemoveButton";
import { FetchedContact } from "../../../journey/form/containers/personSsnInput/FetchedContact";
import sanitizeField from "../../../journey/form/input/helpers/sanitizeField";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {componentStyles, schemaTitleStyle} from "../../../common/styles/componentStyles";

export interface IMetadata {
    prefix?: string;
    suffix?: string;
    classNames?: string;
    help?: string;
    reservedSsnPath?: string | string[];
}

export interface ISsnInputProps extends IInputComponentProps<string, IMetadata> {
    getError: (idx: number) => string | undefined;
    onRemove: (idx: number) => void;
    isFieldInvalid: (idx: number) => boolean;
    values:  {id: string, contact: InsuredPersonContact}[];
    addButton?: React.ReactElement;
    onClick: (event: any) => void;
    isLoading?: boolean;
    onValueChange: (idx: number, name: string) => void;
    showBorder: boolean;
}

class SsnInputInternal extends InputComponentBase<string, IMetadata, any, ISsnInputProps> {
    public render() {
        const {
            name,
            values,
            showBorder,
        } = this.props;

        const {
            classNames,
        } = this.metadata;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {

                    const hasValues = this.checkIfValuesProvided(values);

                    return (
                        <div className={classNames}>
                            <div style={componentStyles.ssnInput.card}>
                                <Headings.H3 color={'primary1'}><FormattedMessage id={CmsHelper.withPrefix(cmsContext, `${name}.ssn.headerText`)} /></Headings.H3>
                                <div style={componentStyles.ssnInput.description}>
                                    <FormattedMarkdown disallowedTypes={[]} messageKey={CmsHelper.withPrefix(cmsContext, `${name}.ssn.description`)} />
                                </div>
                                <div>
                                    {values.map(({id, contact: value}: any, idx: number) => {
                                        if (!showBorder) {
                                            return (
                                                <React.Fragment key={id}>
                                                    {value && value.contactLoaded ?
                                                        this.renderFilledTextField(idx, value, cmsContext) :
                                                        this.renderFormField(idx, value, cmsContext)}
                                                    <Spacing top={"sm"}>
                                                        {this.renderShowPricesButton(cmsContext, !hasValues)}
                                                    </Spacing>
                                                </React.Fragment>
                                            )
                                        }
                                        else {
                                            return (
                                                <React.Fragment key={id}>
                                                    <Spacing type={"margin"} bottom={"sm"}>
                                                        {value && value.contactLoaded ?
                                                            this.renderFilledTextField(idx, value, cmsContext) :
                                                            this.renderFormField(idx, value, cmsContext)}
                                                    </Spacing>
                                                </React.Fragment>
                                            )
                                        }
                                    })}
                                    {!!this.props.addButton && this.props.addButton}
                                </div>
                                {this.props.showBorder &&
                                <div>
                                    {this.renderShowPricesButton(cmsContext, !hasValues)}
                                </div>
                                }
                            </div>
                        </div>
                    );
                }}
            </ CmsContext.Consumer>
        );
    }

    private checkIfValuesProvided(values: any[]) {
        return !!values.length && !!Object.keys(values[0].contact).length;
    };

    private renderFormField(idx: number, value: DeepPartial<InsuredPersonContact>, cmsContext: ICmsContext): JSX.Element {
        const {
            name,
            required,
            onValueChange,
            disabled,
            readonly,
            viewContext
        } = this.props;

        const {
            help,
            prefix,
        } = this.metadata;

        const maxLength = this.schema.maxLength;

        const schemaTitle =
            <div style={{display: (viewContext.isMobile || viewContext.isTablet) && 'inline-flex', flexWrap: 'wrap'}}>
                <FormattedMessage id={CmsHelper.withPrefix(cmsContext, `${name}.ssn`)} />
                <div style={{...schemaTitleStyle}}>
                    <FormattedMessage id={"general.form.personSsnNumber.placeholder"} />
                </div>
            </div>

        return (
            <FormFieldLayout
                id={name}
                title={!value.contactLoaded && schemaTitle}
                help={help}
                required={required}
                error={this.props.getError(idx)}
                invalid={!!this.props.getError(idx)}>
                <FormInput
                    id={`${name}_${idx}`}
                    autoFocus={false}
                    prefix={prefix}
                    suffix={this.renderRemoveIcon(idx, value)}
                    placeholder={this.props.intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, `${name}.ssn.placeholder`)})}
                    value={this.formatSsnNumber(value)}
                    disabled={disabled}
                    readOnly={readonly}
                    invalid={this.props.isFieldInvalid(idx)}
                    maxLength={maxLength}
                    onChange={(event: any) => onValueChange(idx, sanitizeField(event.target.value))}
                    type={InputType.tel}
                />
            </FormFieldLayout>
        );
    };

    private renderRemoveIcon(idx: number, value: DeepPartial<InsuredPersonContact>): JSX.Element {
        if (value.contactLoaded || idx > 0) {
            return (
                <RemoveButton
                    id={`${this.props.name}Remove_${idx}`}
                    onClick={(event: any) => this.props.onRemove(idx)}
                    uniqueKey={`removeFetchedPerson_${idx}`}
                    index={idx}
                    editIcon={value?.contactLoaded}
                />
            );
        }

        return <></>;
    };

    private renderShowPricesButton(cmsContext: ICmsContext, disabled: boolean): JSX.Element {
        const {
            name,
            onClick,
            isLoading,
        } = this.props;

        return (
            <ButtonResponsive
                disabled={disabled}
                id={`${name}Submit`}
                type="button"
                onClick={onClick}
                isBusy={isLoading}
                outline={true}
                full={true}
            >
                <FormattedMessage id={CmsHelper.withPrefix(cmsContext, `${name}.showPrices`)} />
            </ButtonResponsive>
        );
    };

    private renderFilledTextField(idx: number, value: DeepPartial<InsuredPersonContact>, cmsContext: ICmsContext): JSX.Element {
        return (
            <>
                <DescriptionListResponsive.Header>
                    <div style={{marginBottom: 12}}>
                        <FormattedMarkdown messageKey={CmsHelper.withPrefix(cmsContext, `${this.props.name}.ssn`)} />
                    </div>
                </DescriptionListResponsive.Header>
                <FetchedContact
                    key={`fetchedPersonKey_${idx}`}
                    contact={{
                        ssn: this.formatSsnNumber(value),
                        isProtected: value.isProtected,
                    }}
                    removeButton={this.renderRemoveIcon(idx, value)}
                />
            </>
        );
    }

    private formatSsnNumber(contact: DeepPartial<InsuredPersonContact>): string | undefined {
        if (contact.contactChanged) {
            return contact.ssn;
        }

        if (contact.ssn) {
            return SsnFormatter.formatWithDash(contact.ssn);
        }
    }
}

const SsnInput = withViewContext(injectIntl(SsnInputInternal));
export { SsnInput };

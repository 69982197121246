import * as React from "react";
import {FormattedMessage} from "react-intl";
import {Button, DisplayIcon, IconBankID} from "@folksam-digital/ui";
import {Constants} from "@folksam-digital/model";
import {CmsContext} from "../../../../cms";
import {JourneyType} from "../../../../analytics/new/BaseAnalytics";

interface IProps {
    myPagesButtonTitle?: string;
}

export class MyPagesButton extends React.Component<IProps>{
    public static contextType = CmsContext;
    public render() {
        let url: string;
        url = Constants.Links.signInUrlProtected;

        if (this.context.folderName === JourneyType.Claim){
            url = Constants.Links.signInUrlProtectedClaim;
        }

        if (this.context.folderName === JourneyType.Product){
            url = Constants.Links.signInUrlProtectedProduct;
        }

        return (
            <Button full={true} onClick={() => window.open(url, "_blank")}>
                <DisplayIcon style={{marginRight: 8}} baseline={true} large={false} icon={<IconBankID/>}/>
                <FormattedMessage id={this.props?.myPagesButtonTitle ?? "general.success.bannerPanel.myPages.header"}/>
            </Button>
        );
    }
}

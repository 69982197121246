import * as React from "react";
import {FormattedMessage} from "react-intl";
import {useCallback, useMemo} from "react";
import {NavigationModalButton} from "../common/modalButton";
import {logoHref} from "../common/params";
import {clearSessionStorage} from "../common/clearSessionStorage";
import {UrlHelper} from "@folksam-digital/services";

const CloseButtonInternal = (
    {
        backUrl,
        returnUrl,
        isAuthenticated,
    }: any) => {
    const onReturn = useCallback(() => {
        let url = returnUrl || backUrl || logoHref;
        if (isAuthenticated) {
            url = UrlHelper.getLogoutUrl(url);
        }
        clearSessionStorage();
        window.open(url, "_self");
    }, [isAuthenticated, returnUrl, backUrl]);

    const messages = useMemo(() => ({
        modalHeaderMessage: "general.loggedUser.cancel.confirmation.heading",
        modalContentMessage: "general.loggedUser.cancel.confirmation.body"
    }), []);

    return (
        <NavigationModalButton
            onSubmit={onReturn}
            linkMessageId={"general.navigate.close"}
            modalHeaderMessage={
                <FormattedMessage id={messages.modalHeaderMessage} />
            }
            modalContentMessage={
                <FormattedMessage
                    id={messages.modalContentMessage}
                />
            }
        />
    );
};

export const CloseModalButton = CloseButtonInternal;

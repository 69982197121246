import * as React from "react";
import {defaultTheme, IconCloseV2, Link} from "@folksam-digital/ui";
import {useStyles} from "../common/useStyles";
import useGoBack from "../hooks/useGoBack";

export const CancelLink = (
    {
        backUrl,
        stepBack,
        forceClearSessionStorage
    }: any) => {

    const classes = useStyles();
    const goBackFn = useGoBack({backUrl, stepBack, forceClearSessionStorage});

    return (
        <Link to={""}
              className={classes.root}
              target={'_self'}
              onClick={goBackFn}
        >
            <IconCloseV2 style={{paddingLeft: 5}}
                         color={defaultTheme.colors.primary2}
                         width={14}
                         height={14}/>
        </Link>
    );
};

import * as React from "react";
import get from "lodash/get";
import { Step } from "@folksam-digital/ui";
import { OutputComponentBase } from "./OutputComponentBase";
import { FormattedMessage } from "react-intl";
import { FormContext } from "../FormContext";

interface IMetadata {
    dataPath?: string;
    noPaddingTop?: boolean;
    noPaddingBottom?: boolean;
}

export class SectionHeader extends OutputComponentBase<void, IMetadata, {}> {
    public static contextType = FormContext;

    public render() {
        const title = this.props.schema.title;
        const {dataPath, noPaddingTop, noPaddingBottom} = this.metadata;
        let values;
        if (dataPath) {
            values = get(this.context.data, dataPath);
        }

        return (
            <Step.SectionHeader noPaddingTop={noPaddingTop} noPaddingBottom={noPaddingBottom} headerText={<FormattedMessage id={title} values={values} />} />
        );
    }
}

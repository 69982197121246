import * as React from "react";
import Zoom from "@folksam-digital/model/lib/constants/GoogleMapZoom";


interface IMapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    defaultCenterLatLng: undefined | google.maps.LatLng | google.maps.LatLngLiteral;
    initialZoom: number;
    withMount?: boolean;
    withPlaces?: boolean;
    withPlacesLatLng?: google.maps.LatLng | google.maps.LatLngLiteral;
    onClick?: (e: google.maps.MouseEvent) => void;
}

export const Map: React.FC<IMapProps> = ({onClick, children, style, initialZoom, defaultCenterLatLng, withMount, withPlaces, withPlacesLatLng, fullscreenControl, streetViewControl}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            // required map options(zoom, center)
            // see https://developers.google.com/maps/documentation/javascript/overview#MapOptions
            setMap(new window.google.maps.Map(ref.current, {
                zoom: initialZoom || Zoom.initial,
                center: defaultCenterLatLng || {lat: 0, lng: 0}
            }));
        }
    }, [ref, map, initialZoom, defaultCenterLatLng]);

    React.useEffect(() => {
        if (map) {
            if (withMount && defaultCenterLatLng && defaultCenterLatLng?.lat && typeof defaultCenterLatLng?.lat === 'function') {
                // after mount zoom in on valid place with cords
                map.setZoom(Zoom.validPlace);
                map.setCenter(defaultCenterLatLng)
            } else if (withMount) {
                // after mount when no coords, use default location without zoom(stays initial)
                map.setCenter(defaultCenterLatLng || {lat: 0, lng: 0});
            } else if (withPlaces && withPlacesLatLng) {
                // center map and zoom on valid places suggestion selection
                map.setZoom(Zoom.validPlace);
                map.setCenter(withPlacesLatLng);
            }
            map.setOptions({fullscreenControl, streetViewControl});
        }
    }, [map, withMount, defaultCenterLatLng, withPlaces, withPlacesLatLng, fullscreenControl, streetViewControl]);

    React.useEffect(() => {
        if (map) {
            ["click"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );

            if (onClick) {
                map.addListener("click", onClick);
            }

            // added
            return () => {
                if (map) {
                    ["click"].forEach((eventName) =>
                        google.maps.event.clearListeners(map, eventName)
                    );
                }
            };
        }
    }, [map, onClick]);

    return (
        <>
            <div ref={ref} style={style}/>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    return React.cloneElement(child, {map});
                }
            })}
        </>
    );
};

import * as React from "react";
import {FormattedMessage} from "react-intl";
import {B, ButtonResponsive, FormNote, Grid, IconPlus} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {FormContext} from "../FormContext";
import {IBreakPoint} from "../../layout/helpers";
import get from "lodash/get";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";
import {Template} from "../output/helpers";

export interface IAddButtonMetaData {
    help?: string;
    breakpoints?: IBreakPoint;
    classNames?: string;
    modelPath?: string;
    record?: any;
    disableButtonByAccordionPanelSchemaBaseName?: string;
    limitPath?: string;
    titleAsTemplate?: boolean;
}

interface IAddButtonProps extends IInputComponentProps<any, IAddButtonMetaData> {
    onClick?: () => any;
}

export class AddButton extends InputComponentBase<any, IAddButtonMetaData, any, IAddButtonProps> {
    public static contextType = FormContext;

    public render() {
        const title = this.schema.title;

        const {name} = this.props;
        // @ToDo 1) currently simplified padding using top '5'(20px) everywhere- without Step.SectionBody wrapping(seems, it won't be used in future design)
        // @ToDo 2) <Spacing top={'5'}> - won't be used, when clarified AddButton being centered for every AccordionPanel.

        const {
            breakpoints,
            help,
            titleAsTemplate,
            classNames
        } = this.metadata;
        const disabledHelpStyle = this.isDisabled() ? {opacity: 0.5} : undefined;

        const formattedTitle = <FormattedMessage id={title}/>;
        const templateTitle = <Template template={title} data={this.context.data}/>;

        return (
            <React.Fragment>
                <ButtonResponsive
                    id={name}
                    type="button"
                    primary={true}
                    asLink={true}
                    onClick={this.onClickHandler}
                    disabled={this.props.disabled || this.isDisabled() || this.isLimitReached()}
                >
                    <IconPlus
                        style={{
                            width: 17,
                            height: 17,
                            marginRight: 7,
                            alignSelf: "center"
                        }}
                    />
                    <B>{titleAsTemplate ? templateTitle : formattedTitle}</B>
                </ButtonResponsive>
                <Grid.Row className={classNames}>
                    <Grid.Col {...this.getBreakpoints(breakpoints)} style={disabledHelpStyle}>
                        {help && <FormNote><FormattedMessage id={help}/></FormNote>}
                    </Grid.Col>
                </Grid.Row>
            </React.Fragment>
        );
    }

    private isLimitReached(): boolean {
        const {limitPath, modelPath} = this.metadata || undefined;
        if (limitPath && modelPath) {
            const limit = get(this.context.data, limitPath);
            const length = get(this.context.data, modelPath).length;
            return length >= limit;
        }
        return false;
    }

    private isDisabled(): boolean {
        const {disableButtonByAccordionPanelSchemaBaseName} = this.metadata;
        return disableButtonByAccordionPanelSchemaBaseName ? this.isButtonDisabledByAccordionBaseName(disableButtonByAccordionPanelSchemaBaseName) : this.context.disableButton;
    }

    private isButtonDisabledByAccordionBaseName(pattern: string): boolean {
        const expandedStateAllAccordions = this.context.data?.uiState?.accordion[this.context.currentStepName] || {};
        return Object.keys(expandedStateAllAccordions).some(key => key.indexOf(pattern) > -1 && expandedStateAllAccordions[key] === true)
    }

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        return {
            ...defaultBreakpoints.addButton,
            ...breakpoints
        };
    }

    private onClickHandler = () => {
        if (this.props.onClick) {
            this.props.onClick();
        } else {
            this.addNewPlaceHolderData();
        }
    };

    private addNewPlaceHolderData = () => {
        const {modelPath, record} = this.metadata;

        if (modelPath && record !== undefined) {
            this.props.onChange({modelPath, record});

            return;
        }

        const data = this.props.formData;

        data.push({});

        this.props.onChange(data);
    }
}

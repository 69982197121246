import * as React from "react";
import {CancelModalButton} from "./loggedIn/CancelModalButton";
import {CancelLink} from "./loggedOut/CancelLink";

interface IJourneyErrorNavButtonProps {
    isAuthenticated?: boolean;
    [key: string]: any;
}

export const JourneyErrorNavButton = (props: IJourneyErrorNavButtonProps) => {
    if (props.isAuthenticated) {
        return <CancelModalButton { ...props } />
    }

    return (<CancelLink {...props} />);
};

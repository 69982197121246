import {injectable} from "inversify";
import {ICmsParamsService, IInitParams} from "../ICmsParamsService";
import {IStorage} from "../IStorage";

@injectable()
export class CmsShimParamsService implements ICmsParamsService {
    private static storageName: string = "params";
    private readonly sessionStorage: IStorage;

    constructor(sessionStorage: IStorage) {
        this.sessionStorage = sessionStorage;
    }

    public getInitParams(): IInitParams {
        const initParams = this.sessionStorage.get<IInitParams>(CmsShimParamsService.storageName);
        if(!initParams) {
            throw Error("Page not initialized correctly");
        }
        return initParams;
    }
}

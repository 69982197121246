import * as React from "react";
import get from "lodash/get";
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import {OutputComponentBase} from "../../../journey/form/output/OutputComponentBase";
import {FormattedMessage} from "react-intl";
import {Spacing} from "@folksam-digital/ui";
import {PolicySearchResult} from "@folksam-digital/model";
import {IFormContext, FormContext} from "../../../journey/form/FormContext";
import {PolicyListGroup, PolicyListRow} from "../output";

interface IMetadata {
    policiesModelPath: string;
    cmsPrefix: string;
}

class PolicyList extends OutputComponentBase<void, IMetadata, {}> {
    public static contextType = FormContext;

    private groupPolicies(policies: PolicySearchResult[]) {
        return groupBy(policies, policy => policy.policyOwnerName)
    }

    public render() {
        const policiesList: PolicySearchResult[] = get(this.context.data, this.metadata.policiesModelPath);
        const groupName = this.context.data?.group?.name;
        const cmsPrefix = this.metadata.cmsPrefix;

        return (
            <FormContext.Consumer>
                {
                    (context: IFormContext) => (
                        <div>
                            <div>
                                {
                                    policiesList?.length > 0
                                    ?
                                        <FormattedMessage
                                            id={`${cmsPrefix}.youHaveThis.existingPolicies.description`}
                                            values={{groupName}}/>
                                    :
                                        <FormattedMessage
                                            id={`${cmsPrefix}.youHaveThis.noExistingPolicies.description`}
                                            values={{groupName}}/>
                                }
                            </div>
                            <Spacing bottom={"md"}/>
                            {
                                map(this.groupPolicies(policiesList), (policies, group) =>
                                    <PolicyListGroup
                                        key={group}
                                        listItemComponent={PolicyListRow}
                                        policies={policies}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </FormContext.Consumer>
        );
    }
}

export default PolicyList;

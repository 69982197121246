import * as React from "react";
import {injectIntl} from "react-intl";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import set from "lodash/set";
import memoize from "lodash/memoize";
import {BoatCover, Cover, CoverPlan} from "@folksam-digital/model";
import {Spacing, withViewContext} from "@folksam-digital/ui";
import {IInputComponentProps, InputComponentBase} from "../../input/InputComponentBase";
import {AddOnCardView} from "./AddOnCardView";
import {IFormContext} from "../../FormContext";
import {SeePriceView} from "./SeePriceView";
import {IBreakPoint} from "../../../layout/helpers";
import {
    doValuesMatch,
    isValidFormat,
    isValidMinMaxValue,
    isValue
} from "../../../../../Helpers/validationFunctions/validateSmallBoatHelper";
import {PriceBlockThemes} from "./PriceBlock";

interface IBoatAddOnCardAdvancedContainerMetadata {
    coverId: number;
    addOnId: string;
    pathToUserInputValue: string;
    pathToValue: string;
    breakpoints?: IBreakPoint;
    model?: string;
    prefix: string;
}

interface IBoatAddOnCardAdvancedContainerState {
    value?: string;
    isLoading?: boolean;
    premiumRecalculated?: boolean;
}

class BoatAddOnCardAdvancedContainer extends InputComponentBase<any, IBoatAddOnCardAdvancedContainerMetadata, IBoatAddOnCardAdvancedContainerState> {
    constructor(props: IInputComponentProps<any, IBoatAddOnCardAdvancedContainerMetadata>, context: IFormContext) {
        super(props, context);

        this.onCardChange = this.onCardChange.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onSeePriceClick = this.onSeePriceClick.bind(this);

        this.state = {
            value: "",
            isLoading: false,
            premiumRecalculated: false
        };
    }

    public componentDidMount(): void {
        this.setState({ value: this.getCalculatedFieldValue(), premiumRecalculated: !isEmpty(this.getCalculatedFieldValue())});
    }

    public render(): React.ReactNode {
        const {
            name,
            intl,
            formData,
            viewContext
        } = this.props;

        const {
            coverId,
            addOnId,
            model,
            breakpoints,
            prefix
        } = this.metadata;

        const cover = this.findSmallBoatCover(this.context.data, coverId);
        const layoutProps = {...this.getLayoutProps()};

        return (
            <AddOnCardView
                coverId={coverId}
                addOnId={addOnId}
                intl={intl}
                checked={!!(formData && formData?.selected)}
                disabled={this.isCardDisabled()}
                prefix={prefix}
                label={
                    formData && formData?.selected
                        ? intl.formatMessage({id: "general.button.product.added.text"})
                        : intl.formatMessage({id: "general.button.product.add.text"})
                }
                isLoading={isEmpty(cover)}
                onCardChange={this.onCardChange}
                selectionDisabled={false}
                cover={cover}
                model={model}
                priceBlockTheme={this.state.premiumRecalculated ? PriceBlockThemes.Transparent : PriceBlockThemes.Grey}
                recalculatedPrice={this.state.premiumRecalculated}
            >
                <SeePriceView
                    name={name}
                    intl={intl}
                    viewContext={viewContext}
                    value={this.state.value}
                    placeholder={`${prefix}.goodSupplementaryInsurance.smallBoatInsurance.input.placeholder`}
                    layoutProps={layoutProps}
                    isInvalid={this.isInvalid()}
                    isDisabled={!!formData?.selected}
                    onInputChange={this.onInputChange}
                    isValidField={this.isValidField()}
                    onSeePriceClick={this.onSeePriceClick}
                    columnBreakpoints={{inputField: {md: 7}, button: {md: 5}}}
                    breakpoints={breakpoints}
                    isLoading={this.state.isLoading}
                />
                <Spacing type={"margin"} bottom={"sm"}/>
            </AddOnCardView>
        );
    }

    private async onCardChange(event: any): Promise<void> {
        const newData = this.props.formData as BoatCover;
        newData.selected = !!event.target.checked;
        this.onChangeWithValidation(newData);
        this.context.updatePremium()
    }

    private onInputChange(tempValue?: number): void {
        const value = tempValue ? tempValue.toString() : undefined;
        this.setState({value});

        const data = this.props.formData as BoatCover;
        set(data, this.metadata.pathToUserInputValue, value);

        this.props.onChange(data);
    }

    private async onSeePriceClick(): Promise<void> {
        this.setState({ isLoading: true });

        const data = this.props.formData as BoatCover;
        set(data, this.metadata.pathToUserInputValue, this.state.value);
        set(data, this.metadata.pathToValue, this.state.value);

        this.context.updatePremium();
        this.onChangeWithValidation(data);

        this.setState({ isLoading: false, premiumRecalculated: true });
    }

    private getFieldValue(): string {
        let inputValue = get(this.props.formData, this.metadata.pathToUserInputValue);

        if (!inputValue) {
            inputValue = this.state.value;
        }
        return inputValue;
    }

    private getCalculatedFieldValue(): string {
        return get(this.props.formData, this.metadata.pathToValue);
    }

    private isValidField(): boolean {
        const value = this.getFieldValue();
        const { minimum, maximum, pattern } = this.schema;

        return !(!isValue(value) || !isValidFormat(value, pattern!) || !isValidMinMaxValue(value, Number(minimum), Number(maximum)));

    }

    private isCardDisabled(): boolean {
        return !(this.state.value && doValuesMatch(this.props.formData?.details?.value || "", this.state.value || ""));
    }

    private findSmallBoatCover = memoize((data: any, id: number) => {
        let covers: Cover[] = [] as Cover[];
        const coverPlan = data.policy.coverPlan;

        if (data.policy?.premium?.covers) {
            covers = data.policy.premium.covers as Cover[];
        }
        else if (data.policy?.premium?.coverPlans) {
            covers = data.policy.premium.coverPlans.find((row: CoverPlan) => row.id === coverPlan)?.covers as Cover[];
        }

        if (!isEmpty(covers)) {
            for (const cover of covers) {
                if (cover.id === id) {
                    return cover;
                }
            }
        }
    });
}

export default injectIntl(withViewContext(BoatAddOnCardAdvancedContainer));

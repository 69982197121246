import * as React from "react";
import {FormInputNumber, Grid, SalaryCard as SalaryCardFUI} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {FormContext, IFormContext} from "../FormContext";
import {FormattedMessage, injectIntl} from "react-intl";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {IBreakPoint} from "../../layout/helpers";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {Breakpoints, InputMode, SelectedCover} from "@folksam-digital/model";
import {defaultBreakpoints} from "./helpers/breakpoints/defaultBreakpoints";

interface IMetadata {
    breakpoints?: IBreakPoint;
    inputReadOnly?: boolean;
    step?: number;
}

class SalaryCardInternal extends InputComponentBase<SelectedCover, IMetadata, {}> {
    public static contextType = FormContext;

    public constructor(props: any, context: IFormContext) {
        super(props, context);

        this.onSelectCover = this.onSelectCover.bind(this);
        this.onUnSelectCover = this.onUnSelectCover.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    public render() {
        const {cover: formData} = this.context.data;
        const {intl, name} = this.props;
        const {baseMaxValue, addonMinValue, salaryDifference} = this.context.data.premium;
        const {breakpoints, inputReadOnly, step} = this.metadata;

        const minSalaryCap = Number(addonMinValue) - Number(baseMaxValue);
        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => (
                    <Grid>
                        <Grid.Row>
                            <Grid.Col {...this.getBreakpoints(breakpoints)}>
                                <SalaryCardFUI
                                    id={name}
                                    name={name}
                                    value={name}
                                    heading={intl.formatMessage({id: CmsHelper.withPrefix(cmsContext, "salaryCap.salaryCard.heading")})}
                                    image={CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, "salaryCap.salaryCard.headerImage"), cmsContext.catalog)}
                                    emphesizedText={<FormattedMarkdown
                                        messageKey={CmsHelper.withPrefix(cmsContext, "salaryCap.salaryCard.income.text")}
                                        messageValue={{
                                            baseIncome: baseMaxValue
                                        }}
                                    />}
                                    paragraphElem={<FormattedMarkdown
                                        messageKey={`${CmsHelper.withPrefix(cmsContext, "salaryCap.salaryCard.totalInsured.text")}`}
                                        messageValue={{
                                            totalInsuredAmount: Number(this.context.data.premium.baseMaxValue) + Number(formData?.inputValue || 0),
                                            monthlyPremium: formData?.monthlyPremium
                                        }}/>}
                                    price={<FormattedMessage id={"general.priceBar.premium.monthly"}
                                                             values={{monthlyPremium: formData?.monthlyPremium}}/>}
                                    buttonText={{
                                        unselected: <FormattedMessage
                                            id={"general.package.unselected"}/>,
                                        selected: <FormattedMessage
                                            id={"general.package.selected"}/>
                                    }}
                                    checked={formData?.selected}
                                    onChecked={this.onSelectCover}
                                >
                                    <FormFieldLayout {...this.getLayoutProps()} breakpoints={{[Breakpoints.md]: 5}}>
                                        <FormInputNumber
                                            id={`${name}Input`}
                                            max={salaryDifference}
                                            min={minSalaryCap}
                                            inputReadOnly={inputReadOnly}
                                            value={formData?.inputValue}
                                            step={step ? step : 10000}
                                            suffix={intl.formatMessage({id: "general.price.suffix"})}
                                            onChange={this.onChange}
                                            inputMode={InputMode.numeric}
                                            invalid={this.isInvalid()}
                                        />
                                    </FormFieldLayout>
                                </SalaryCardFUI>
                            </Grid.Col>
                        </Grid.Row>
                    </Grid>
                )}
            </CmsContext.Consumer>
        );
    }

    private onSelectCover(event: React.ChangeEvent<HTMLInputElement>): void {
        const newData = this.context.data.cover as SelectedCover;
        newData.selected = !newData.selected;

        if (newData.selected) {
            this.onChangeWithValidation(newData);
        } else {
            this.props.onChange(newData);
        }
    }

    private onUnSelectCover() {
        const newData = this.context.data.cover;
        newData.selected = false;
        this.props.onChange(newData);
    }

    private onChange(event: React.ChangeEvent<HTMLInputElement>): void {
        const newData = this.context.data.cover;
        newData.inputValue = event.target.value;
        this.onUnSelectCover();
        this.props.onChange(newData);
    };

    private getBreakpoints(breakpoints?: IBreakPoint): IBreakPoint {
        return {
            ...defaultBreakpoints.salaryCard,
            ...breakpoints
        };
    }
}

const SalaryCard = injectIntl(SalaryCardInternal);
export {SalaryCard};

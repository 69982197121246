import * as React from "react";
import {FormattedMessage} from "react-intl";
import {ClaimCompensation} from "@folksam-digital/model";
import {DescriptionListResponsive, Grid, Headings, SummaryCard} from "@folksam-digital/ui";
import {CmsContext, ICmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import getCompensationSum from "./helpers/getCompensationSum";

interface ICompensationsTableProps {
    title: string;
    compensations?: ClaimCompensation[];
    compensationCalcFn?: (compensations?: ClaimCompensation[]) => number;
}

export class CompensationCard extends React.Component<ICompensationsTableProps> {

    public render(): React.ReactNode {
        const {compensationCalcFn, compensations} = this.props;

        return (
            <CmsContext.Consumer>
                {(cmsContext: ICmsContext) => {
                    const imageAttribute = CmsHelper.getImageAttributes(CmsHelper.withPrefix(cmsContext, 'compensationImage'), cmsContext.catalog);

                    return (
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <DescriptionListResponsive themeId={3}>
                                    <DescriptionListResponsive.Header>
                                        <Headings.H3 style={{margin: 0}}>
                                            <FormattedMessage id={this.props.title}
                                                              values={{amount: compensationCalcFn ? compensationCalcFn(compensations) : getCompensationSum(compensations)}}/>
                                        </Headings.H3>
                                        <SummaryCard.Image {...imageAttribute} />
                                    </DescriptionListResponsive.Header>
                                </DescriptionListResponsive>
                            </Grid.Col>
                        </Grid.Row>
                    );
                }}
            </ CmsContext.Consumer>
        );
    }
}

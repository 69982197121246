import * as React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import {FormComponentBase, IFormComponentProps} from "../../../journey/form/FormComponentBase";
import {PolicySearchResult} from "@folksam-digital/model";
import {Grid, P} from "@folksam-digital/ui";
import {OptCard} from "../input";
import {IOptCardPolicy} from "../input/OptCard";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import isNil from "lodash/isNil";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import {FormContext, IFormContext} from "../../../journey/form/FormContext";
import OptCardGroupList from "../output/OptCardGroupList";
import {groupPolicies} from "../../../../Helpers/optInOptOut/groupPolicies";
import FormattedMarkdown from "../../../FormattedMarkdown";
import * as OptInOptOutProductListBaseFunctions from "./helpers/OptInOptOutProductListBaseFunctions";
import {isMemberPolicy} from "./helpers";
import {OptCardHeaderMessage} from "../layout/OptCardHeaderMessage";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CmsContext} from "../../../../cms";
import {OptOutInContainerFooter} from "./OptOutInContainerFooter";
import {OptOutInContainerHeader} from "./OptOutInContainerHeader";

interface IOptInContainerProps extends IFormComponentProps<any, IMetadata> {
    index?: number;
}

export interface IMetadata {

}

class OptInContainer extends FormComponentBase<PolicySearchResult[], IMetadata, {}, IOptInContainerProps> {
    public static contextType = CmsContext;

    private appendIndexToPolicies(policies: PolicySearchResult[]): IOptCardPolicy[] {
        return map(policies, (policy: PolicySearchResult, idx: number) => ({
            ...policy, index: idx
        }));
    }

    private shouldShowPbbMessage(policies: PolicySearchResult[]) {
        return find(policies, (policy: PolicySearchResult) => !isNil(policy.pbbUnitValue));
    }

    private getHeaderMessage(context: IFormContext, group?: number) {
        if (!isMemberPolicy(Number(group))) {
            return [
                <OptCardHeaderMessage key="listHeader"
                                      fullName={this.props.intl.formatMessage({id: CmsHelper.withPrefix(this.context, "optCard.yourPartner")})}/>,
                <P key="listDescription">
                    <FormattedMessage id="groupInsurance.optInOptOut.optIn.forPartner.description"/>
                </P>
            ]
        } else {
            const member = context.data.member;
            return <OptCardHeaderMessage key="listHeader"
                                         fullName={`${member.contact.firstName} ${member.contact.lastName}`}/>;
        }
    }

    public render() {
        const startDates = OptInOptOutProductListBaseFunctions.extractDates(this.props.formData, policy => policy.startDate!);
        const pbbPolicy = this.shouldShowPbbMessage(this.props.formData);
        const startDate = OptInOptOutProductListBaseFunctions.getYoungestDate(startDates);
        const formData = this.appendIndexToPolicies(this.props.formData);

        if (!startDate || isEmpty(this.props.formData)) {
            return null;
        }

        return (
            <FormContext.Consumer>
                {(context: IFormContext) => {
                    return (
                        <Grid>
                            <Grid.Col xs={12} md={12}>
                                <OptOutInContainerHeader text={isEmpty(context.data?.optOut)
                                    ? <FormattedMarkdown
                                        messageKey={CmsHelper.withPrefix(this.context, "optIn.description")}
                                        messageValue={{
                                            union: context.data.group.name,
                                        }}/>
                                    : <FormattedMarkdown
                                        messageKey={CmsHelper.withPrefix(this.context, "optIn.description.mixed")}/>}
                                />
                                {map(groupPolicies(formData), (policies: any, group: any) => {
                                        return (
                                            <OptCardGroupList
                                                header={
                                                    this.getHeaderMessage(context, group)
                                                }
                                                group={`optIn-${group}`}
                                                defaultValue=""
                                                key={group}
                                                getError={this.getFieldError}
                                                onChange={this.onCardChange}
                                                listItemComponent={OptCard}
                                                policies={policies}
                                            />
                                        );
                                    }
                                )}
                                <OptOutInContainerFooter pbbPolicy={pbbPolicy}/>
                            </Grid.Col>
                        </Grid>
                    )
                }}
            </FormContext.Consumer>
        )
    }

    private getFieldError = (idx: number): string | undefined => {
        if (this.props.errorSchema[idx] && this.props.errorSchema[idx].__errors.length > 0) {
            return this.props.errorSchema[idx].__errors[0];
        }
    };

    public onCardChange = (proposal: IOptCardPolicy) => {
        const optInPolicies = cloneDeep(this.props.formData);

        if (!isNil(proposal.index)) {
            optInPolicies[proposal.index].selected = proposal.selected;
        }

        this.setState(
            {formData: optInPolicies},
            () => {
                this.props.onChange(optInPolicies)
            }
        );
    }
}

export default injectIntl(OptInContainer);

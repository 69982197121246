import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {FormattedMessage} from "react-intl";
import {ButtonResponsive, MaxWidthWrapper, P, Spacing, Step, ErrorHeaderIcon} from "@folksam-digital/ui";
import {HeaderLayoutNew} from "../../components";
import FormattedMarkdown from "../../components/FormattedMarkdown";
import {CmsContext} from "../../cms";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {Constants} from "@folksam-digital/model";
import {UrlHelper} from "@folksam-digital/services";
import {DynamicTitle} from "../../components/general/DynamicTitle";
import {JourneyErrorNavButton} from "../../components/journey/navigation/JourneyErrorNavButton";
import {ChooseTimeToCallBackComponent} from "../errorPage/ChooseTimeToCallBackComponent";
import { ComponentBackgroundWrapper } from "../../components/ComponentBackgroundWrapper";

interface IHeaderMessages {
    header: string;
    subheader?: string;
    headerText: string;
}

interface IErrorPageViewProps {
    formData: any;
    headerMessages: IHeaderMessages;
    onTryAgain?: () => void;
    contactForm?: React.ReactNode | null;
    description: string;
}

class ErrorPageViewInternal extends React.Component<IErrorPageViewProps & RouteComponentProps<any, any>, any> {
    public static contextType = CmsContext;
    private readonly contactFormDisablingErrors: string[] = [
        Constants.Signing.statusCodes.internal.fault.mandateAlreadySigned
    ];

    constructor(props: IErrorPageViewProps & RouteComponentProps<any, any>) {
        super(props);

        this.onTryAgain = this.onTryAgain.bind(this);
    }

    public onTryAgain() {
        this.props.history.push(UrlHelper.getUrl(["direct-debit", "set-up"]));
    }

    public render() {
        const { formData, headerMessages } = this.props;

        return (
            <React.Fragment>
                <DynamicTitle keyId={CmsHelper.withPrefix(this.context, "title.message")}/>
                <HeaderLayoutNew
                    centered={true}
                    compact={true}
                    themeId={formData?.themeId ? Number(formData.themeId) : 2}
                    journeyId={"directDebit"}
                    formData={{contact: this.props.formData}}
                    logOutUrl={formData?.logOutUrl}
                    returnUrl={formData?.returnUrl}
                    navButton={JourneyErrorNavButton}
                    backUrl={UrlHelper.getUrl(["direct-debit", "set-up"])}
                    renderCollabBadge={false}
                    translations={{
                        header: headerMessages.header,
                        headerText: <FormattedMarkdown messageKey={headerMessages.headerText} />,
                    }}
                    params={{
                        icon: <ErrorHeaderIcon />,
                        bigCircle: true,
                        centered: true
                    }}
                    forceClearSessionStorageOnNav={true}
                />
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.SectionBody>
                            <Spacing type={"margin"} top={"base"} bottom={"base"}>
                                <P>
                                    <FormattedMarkdown messageKey={this.props.description}/>
                                </P>
                                {!this.contactFormDisablingErrors.includes(this.props.formData?.failureReason) &&
                                    <Spacing type={"padding"} y={'4'}>
                                        <ButtonResponsive
                                            full={true}
                                            primary={false}
                                            onClick={this.onTryAgain}
                                        >
                                            <FormattedMessage id={CmsHelper.withPrefix(this.context, "navigate.tryAgain")}/>
                                        </ButtonResponsive>
                                    </Spacing>}
                                <ChooseTimeToCallBackComponent />
                            </Spacing>
                        </Step.SectionBody>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        )
    }
}

export default withRouter(ErrorPageViewInternal);

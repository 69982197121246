import * as React from "react";
import {ContactHeader} from "./ContactHeader";
import OfferList from "./OfferList";
import {InsuredPersonContact, PolicySearchResult} from "@folksam-digital/model";

interface IContactHeaderProps {
    filteredOfferList: PolicySearchResult[];
    customMessageFormatId?: string;
    contact: InsuredPersonContact;
    showHeader?: boolean;
}

export abstract class PersonProductList extends React.Component<IContactHeaderProps, {}> {
    private shouldShowDetails(selectedOffers: PolicySearchResult[]) {
        const {showHeader} = this.props;

        return (showHeader || selectedOffers.length > 0);
    }

    public render() {
        const {contact, customMessageFormatId, filteredOfferList} = this.props;
        const shouldShowDetails = this.shouldShowDetails(filteredOfferList);

        return (!!contact && shouldShowDetails &&
            <>
                <ContactHeader fullName={`${contact.firstName} ${contact.lastName}`}
                               customMessageFormatId={customMessageFormatId}/>
                <OfferList offers={filteredOfferList}/>
            </>
        );
    }

}

import * as React from "react";
import get from "lodash/get";
import classNames from "classnames";
import injectSheet from "react-jss";
import {defaultTheme, FlexRow, FormNote, Headings, Spacing} from "@folksam-digital/ui";
import {OutputComponentBase} from "./OutputComponentBase";
import {FormattedMessage} from "react-intl";
import {FormContext} from "../FormContext";
import FormattedMarkdown from "../../../FormattedMarkdown";
import * as JourneyOutputComponentBaseFunctions from "./helpers/JourneyOutputComponentBaseFunctions";

interface IMetadata {
    dataPath?: string;
    help?: string;
    checkBoxMargin?: string;
    lineSeparator?: boolean;
    messageValues?: {[key: string]: string};
    classNames?: string;
}

const styles = {
    "line": {
        "borderBottom": `2px solid ${defaultTheme.colors.quinary1}`
    }
};

export class SectionSubheaderInternal extends OutputComponentBase<void, IMetadata, {}, any> {
    public static contextType = FormContext;

    private getMessageValuesFromContext(): JourneyOutputComponentBaseFunctions.IMetadata | undefined {
        let values;
        if (this.metadata.messageValues && this.props.formContext) {
            values = JourneyOutputComponentBaseFunctions.getMessageValues(this.metadata.messageValues, this.props.formContext!);
        }
        return values;
    }

    public render() {
        const title = this.props.schema.title;
        const name = this.props.uiSchema.name;
        const {classes} = this.props;
        const {
            help,
            checkBoxMargin,
            classNames: schemaClassNames,
            lineSeparator,
            dataPath
        } = this.metadata;
        const values = dataPath && get(this.context.data, dataPath);
        const cn = classNames({
            schemaClassNames,
            [classes.line]: lineSeparator
        });

        return (
            <>
                <FlexRow className={cn} id={name}>
                    {title &&
                        <Headings.H3 style={{margin: 0}}><FormattedMessage id={title} values={values}/></Headings.H3>}
                </FlexRow>
                {help &&
                    <FormNote>
                        <Spacing type={"margin"} left={checkBoxMargin && "lg"} top={"sm"}>
                            <div>
                                <FormattedMarkdown messageKey={help} messageValue={this.getMessageValuesFromContext()}/>
                            </div>
                        </Spacing>
                    </FormNote>
                }
            </>
        );
    }
}

export const SectionSubheader = injectSheet(styles, {inject: ["classes"]} as any)(SectionSubheaderInternal);

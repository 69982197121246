import { Constants } from "@folksam-digital/model";

export interface IProductAnalyticsCode {
    [key: string]: string;
}

export const productAnalyticsCodes = {
    // @ToDo - Specific Product constants be moved and used from Analytics.ProductCode
    // Accident Simple less than 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA2000}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA1000}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA500}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_1_${Constants.Journey.Accident.Id}`]: "VOF",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.financialDisability}_1_${Constants.Journey.Accident.Id}`]: "VOF",

    // Accident Simple over 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA2000}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA1000}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.Accident.SA500}_3_${Constants.Journey.Accident.Id}`]: "VOP",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_3_${Constants.Journey.Accident.Id}`]: "VOP",

    // Accident and Illness less than 65 years
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.AccidentAndIllness.AI2000}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.CoverPlan.AccidentAndIllness.AI1000}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",
    [`${Constants.Product.AccidentAndIllness.id}_${Constants.Cover.AccidentAndIllness.criticalIllness}_2_${Constants.Journey.AccidentAndIllness.Id}`]: "VOS",

    // Individual Child
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Large}`]: "BOS",
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Medium}`]: "BOS",
    [`${Constants.Product.IndividualChild.id}_${Constants.CoverPlan.IndividualChild.Base}`]: "BOS",

    // Pregnancy
    [`${Constants.Product.Pregnancy.id}_${Constants.CoverPlan.Pregnancy.Extended}`]: "GRU",
    [`${Constants.Product.Pregnancy.id}_${Constants.CoverPlan.Pregnancy.Basic}`]: "GRG",

    // Member Child
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Large}`]: "BOSM",
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Medium}`]: "BOSM",
    [`${Constants.Product.MemberChild.id}_${Constants.CoverPlan.MemberChild.Base}`]: "BOSM",

    // Running race
    [`${Constants.Product.RunningRace.id}_${Constants.CoverPlan.RunningRace.Extended}`]: "MLF",
    [`${Constants.Product.RunningRace.id}_${Constants.CoverPlan.RunningRace.Basic}`]: "MLF",
} as IProductAnalyticsCode;

export const unionAnalyticsCodes: {name: string, value: string}[] = [
    {name: "ABF", value: "09219"},
    {name: "Akademikerförbundet SSR", value: "09220"},
    {name: "Akavia", value: "09221"},
    {name: "Byggnads", value: "09206"},
    {name: "Elektrikerförbundet", value: "09211"},
    {name: "Fastighets", value: "09213"},
    {name: "Finansförbundet", value: "09222"},
    {name: "Forena", value: "09223"},
    {name: "Funktionsrätt Sverige", value: "09224"},
    {name: "Försvarsförbundet", value: "09225"}, 
    {name: "GS", value: "09212"},
    {name: "Handels", value: "09205"},
    {name: "Hotell- och restaurangfacket", value:"09210"},
    {name: "Hjärtebarnsfonden", value:"09226"},
    {name: "HSB", value:"09227"},
    {name: "IF Metall ", value:"09207"},
    {name: "Journalistförbundet", value:"09228"},
    {name: "Studenthem Journalistförbundet", value:"09228"},
    {name: "Kommunal", value:"09204"},
    {name: "Kyrkans Akademikerförbund", value:"09229"},
    {name: "LO", value:"09230"},
    {name: "Livs", value:"09214"},
    {name: "Musikerförbundet", value:"09215"},
    {name: "Målareförbundet", value:"09216"},
    {name: "Officersförbundet", value:"09231"},
    {name: "Pappers", value:"09217"},
    {name: "Riksbyggen", value:"09232"},
    {name: "SAP", value:"09233"},
    {name: "Seko", value:"09208"},
    {name: "Seko privata väg", value:"09234"},
    {name: "Seko sjöfolk", value:"09209"},
    {name: "Stockholms kooperativa bostadsförening", value:"09235"},
    {name: "SRAT", value:"09236"},
    {name: "Studenthem SRAT", value:"09236"},
    {name: "ST", value:"09237"},
    {name: "SULF", value:"09238"},
    {name: "Sveriges Arbetsterapeuter", value:"09239"},
    {name: "Sveriges Arkitekter", value:"09240"},
    {name: "Studenthem Arkitekterna", value:"09240"},
    {name: "Sveriges spelmän", value:"09241"},
    {name: "Sveriges läkarförbund", value:"09242"},
    {name: "Studenthem Läkarförbundet", value:"09242"},
    {name: "TCO", value:"09243"},
    {name: "Teaterförbundet", value:"09244"},
    {name: "Tjänstetandläkarna", value:"09245"},
    {name: "Transportarbetarförbundet", value:"09218"},
    {name: "Tull-Kust", value:"09246"},
    {name: "Unionen Egenföretagare", value:"09247"},
    {name: "Vision", value:"09248"},
    {name: "Studenthem Vision", value:"09248"},
    {name: "Vårdförbundet", value:"09249"},
    {name: "Aktiva seniorer", value:"09250"},
    {name: "PRO", value:"09251"},
    {name: "SKPF", value:"09252"},
    {name: "Sveriges Ingenjörer", value:"49011"},
    {name: "Naturvetarna", value:"49014"},
    {name: "Farmacevterna", value:"49015"},
    {name: "DIK", value:"49017"},
    // {name:  "SRAT", value:"49018"},
    // {name:  "Sveriges Arbetsterapeuter", value:"49019"},
    // {name:  "Sveriges Arkitekter", value:"49020"},
    {name: "Fysioterapeuterna", value:"49021"},
    {name: "Sveriges Psykologförbund", value:"49022"},
    // {name:  "Sveriges läkarförbund", value:"49023"}
]

import JourneySuccessBase, {IWithLocaleContextSuccessProps} from "./JourneySuccessBase";
import {Constants, HomeStudentJourney, yearMonthDayFormat} from "@folksam-digital/model";
import {CmsContext} from "../../../../cms";
import * as React from "react";
import isEmpty from "lodash/isEmpty";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {FormattedMessage} from "react-intl";
import flowRight from "lodash/flowRight";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {withRouter} from "react-router";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {
    B,
    BackPackIcon,
    defaultTheme as theme,
    DescriptionList,
    Grid,
    IconCheckMarkV2,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {withLocaleContext} from "../../../../components/journey/form/withLocaleContext";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import SuccessPageHeaderContent from "../../../../components/journey/layout/Success/SuccessPageHeaderContent";
import {getSuccessPageHeaderValues} from "../../../../Helpers/getSuccessPageHeaderValues";
import {ListRowItem, RowAddon, RowDiscounts, RowPrice} from "../common/ListRowItem";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {HomeRowScope} from "../common/HomeRowScope";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

class HomeStudentJourneySuccessInternal extends JourneySuccessBase<HomeStudentJourney, IWithLocaleContextSuccessProps> {
    public static contextType = CmsContext;

    public componentDidMount(): void {
        this.checkData(Constants.Journey.HomeStudent.Id);
        this.triggerDataLayerEvent(Constants.Journey.HomeStudent.Id);
    }

    public render(): React.ReactNode {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }
        data.policy.startDate = new Date(data.policy?.startDate!).toLocaleString(this.props.localeContext.locale, yearMonthDayFormat.reactIntl) as any;
        const {address, city, postalCode, newAddress} = data?.policy?.insuredObject?.address!;

        return (
            <React.Fragment>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.HomeStudent.Id}
                    formData={{
                        contact: data.contact
                    }}
                    backUrl={Constants.Journey.HomeStudent.backUrl}
                    navButton={CloseLink}
                    translations={getSuccessPageHeaderValues<HomeStudentJourney>(data, this.context)}
                    doScroll={true}
                >
                    <SuccessPageHeaderContent
                        formData={data}
                        handleDirectDebitRedirect={this.handleDirectDebitRedirect}
                        journeyId={Constants.Journey.HomeStudent.Id}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} bottom={"8"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <Spacing type='padding' top={"8"}>
                                            <SummaryCard theme={this.summaryCardThemeID}>
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<BackPackIcon
                                                        style={{marginRight: '10px'}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id="product.homeStudent.reviewAndBuy.summaryTable.header"/>
                                                    </SummaryCard.HeaderText>
                                                    <SummaryCard.HeaderIcon large={true} component={<IconCheckMarkV2
                                                        color={this.summaryIconCheckMarkColor}/>}/>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionList breakpoint={12}>
                                                        <ListRowItem
                                                            id="general.review.insuranceAppliesTo.title">
                                                            {newAddress
                                                                ? <B> {address}, {city ? city + ",": ""} {postalCode} </B>
                                                                : <B> {address} </B>
                                                            }
                                                        </ListRowItem>
                                                        <HomeRowScope
                                                            prefix={CmsHelper.getPrefix(this.context)}
                                                            data={data}
                                                        />
                                                        <RowAddon
                                                            prefix={""}
                                                            data={data}
                                                        />
                                                        {
                                                            !isEmpty(data?.policy?.premium?.discounts) &&
                                                            <RowDiscounts premium={data.policy.premium!}
                                                                          tooltipId="product.homeStudent.reviewAndBuy.discountedDiscounts.tooltip"
                                                            />
                                                        }
                                                        <RowPrice premium={data.policy.premium!}/>
                                                    </DescriptionList>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        {this.renderContactUsSection()}
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </React.Fragment>
        );
    }
}

const HomeStudentJourneySuccess = flowRight(
    withCmsProvider("homeStudent"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withRouter,
    withHistoryBackConstraint,
    withLocaleContext
)(HomeStudentJourneySuccessInternal);

export default HomeStudentJourneySuccess;

import React from "react";
import ReactMarkdown, {NodeType} from 'react-markdown';
import {List, Link, DynamicList} from "@folksam-digital/ui";
import {ModalIframe} from "./journey/form";
import {MarkdownListTypes} from "@folksam-digital/model";

export enum NodeTypes {
    root = 'root',
    text = 'text',
    break = 'break',
    paragraph = 'paragraph',
    emphasis = 'emphasis',
    strong = 'strong',
    thematicBreak = 'thematicBreak',
    blockquote = 'blockquote',
    delete = 'delete',
    link = 'link',
    image = 'image',
    linkReference = 'linkReference',
    imageReference = 'imageReference',
    table = 'table',
    tableHead = 'tableHead',
    tableBody = 'tableBody',
    tableRow = 'tableRow',
    tableCell = 'tableCell',
    list = 'list',
    listItem = 'listItem',
    definition = 'definition',
    heading = 'heading',
    inlineCode = 'inlineCode',
    code = 'code',
    html = 'html',
    virtualHtml = 'virtualHtml'
}

interface IMarkdownProps {
    source: string;
    listType?: MarkdownListTypes;
    disallowedTypes?: NodeType[];
    greyedText?: boolean;
    customFont?: string | string[];
    fontSize?: string;
}

interface IRendererProps {
    children: any,
    ordered: boolean,
    href?: string,
}

interface DefaultProps {
    disallowedTypes: NodeType[]
}

const defaultProps = {
    disallowedTypes: [NodeTypes.paragraph]
} as DefaultProps;

export class Markdown extends React.PureComponent<IMarkdownProps & Partial<DefaultProps>> {
    public render() {
        const {source, greyedText, customFont, fontSize} = this.props;
        const renderers = {
            paragraph: (props: IRendererProps) => {
                return <span>{props.children}</span>;
            },
            list: (props: IRendererProps) => {
                if (this.props.listType === MarkdownListTypes.BULLET) {
                    return <List bullet={true} list={props.children}/>
                }
                if (this.props.listType === MarkdownListTypes.ORDERED) {
                    return <List ordered={true} list={props.children}/>
                }
                return <DynamicList greyedText={greyedText} list={props.children} customFont={customFont} fontSize={fontSize} />;
            },
            listItem: (props: IRendererProps) => props.children,
            link: (props: IRendererProps) => this.renderLinkComponent(props)
        };

        const disallowedTypes = this.props.disallowedTypes ? this.props.disallowedTypes : defaultProps.disallowedTypes;

        return (
            <ReactMarkdown
                disallowedTypes={disallowedTypes}
                source={source}
                renderers={renderers}
                unwrapDisallowed={true}
                escapeHtml={false}
            />
        );
    }

    private renderLinkComponent(props: IRendererProps) {
        const {href, children} = props;

        if (children[0].props.value.includes(":modal") && href) {
            const title = children[0].props.value.replace(":modal", "");
            return <ModalIframe title={title} url={href} isBold={false}/>;
        } else {
            return <Link to={href}>{children}</Link>;
        }
    }
}

export function markdown(source: string) {
    return <Markdown source={source}/>
}

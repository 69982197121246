import * as React from "react";
import {useState} from "react";
import {container} from "../../../../inversify.config";
import {IDraftService} from "../../../../services";
import {Types} from "../../../../Types";
import {Constants, IJourneySchema, IncomeAddonJourney} from "@folksam-digital/model";
import {useDraftData} from "../../../../util/hooks/useDraftData";
import {useTriggerDataLayerEvent} from "../../../../util/hooks/useTriggerDataLayerEvent";
import {flowRight, isEmpty} from "lodash";
import {CmsContext} from "../../../../cms";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {
    B,
    DateCalendarIcon,
    defaultTheme as theme,
    DescriptionList,
    DescriptionListResponsive,
    Grid,
    MaxWidthWrapper,
    P,
    Spacing,
    Step,
    SummaryCard
} from "@folksam-digital/ui";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {CloseLink} from "../../../../components/journey/navigation/common/CloseLink";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {IRowDefinition, SummaryCardRowList} from "../../addOn/success/SummaryCardRowList";
import {ProductContactUsSection} from "../../../../components/journey/layout/Success";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import FormattedMarkdown from "../../../../components/FormattedMarkdown";
import {setJourneyCompleted} from "../../../../Helpers/setJourneyCompleted";
import {FormattedPrice} from "../../../../components/formFieldLayout/FormattedPrice";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

const IncomeAddOnSuccessView = (props: any) => {
    const draftService = container.get<IDraftService>(Types.DraftService);
    const schema = {} as IJourneySchema;
    const [data, setData] = useState<IncomeAddonJourney>();
    useDraftData<IncomeAddonJourney>(draftService, Constants.Journey.IncomeAddon.Id, schema, setData);

    const cmsContext = React.useContext(CmsContext);
    const {onTriggerDataLayerEvent} = props;

    useTriggerDataLayerEvent(cmsContext, onTriggerDataLayerEvent, data, Constants.Journey.IncomeAddon.Id);

    setJourneyCompleted(draftService, Constants.Journey.IncomeAddon.Id);

    const summaryCardList = React.useMemo(() => {
        if (isEmpty(data)) {
            return [];
        }

        let increasedTime;
        if (data?.product === Constants.IncomeInsuranceType.Time) {
            increasedTime = {
                term: <FormattedMessage id={"incomeInsurance.incomeAddon.time.reviewAndSubmit.increasedTime.title"}/>,
                definition: <B><FormattedNumber value={Math.round(Number(data?.premium?.addonCompensationDays) || 0)}/>
                    &nbsp;<FormattedMessage id="incomeInsurance.incomeAddon.time.reviewAndSubmit.days.title"/></B>,
            };
        }
        let totalInsuredAmount;
        if (data?.product === Constants.IncomeInsuranceType.SalaryCap) {
            totalInsuredAmount = {
                definition: <>
                    <FormattedMessage
                        id={"incomeInsurance.incomeAddon.salaryCap.reviewAndSubmit.totalInsuredAmount.title"}/>
                    <DescriptionList.Price
                        component={<FormattedPrice
                            suffixId={"incomeInsurance.incomeAddon.price.suffix"}
                            value={Math.round(Number(data?.cover?.inputSetValue || 0))}
                        />}
                    />
                </>,
            };
        }
        const list = [
            {
                term: <FormattedMessage id="incomeInsurance.incomeAddon.salaryCap.reviewAndSubmit.appliesTo.title"/>,
                definition: <B><span>{data?.contact.firstName} {data?.contact.lastName}</span></B>,
            },
            increasedTime,
            {
                definition: <>
                    <FormattedMessage id={data?.product === Constants.IncomeInsuranceType.Time
                        ? "incomeInsurance.incomeAddon.time.reviewAndSubmit.increasedSalary.title"
                        : "incomeInsurance.incomeAddon.salaryCap.reviewAndSubmit.increasedSalaryCap.title"}/>
                    {
                        data?.product === Constants.IncomeInsuranceType.Time ?
                            <DescriptionList.Price
                                component={<FormattedPrice
                                    suffixId={"incomeInsurance.incomeAddon.price.suffix"}
                                    value={Math.round(Number(data?.cover?.inputSetValue!))}
                                />}
                            />
                            :
                            <DescriptionList.Price
                                component={<FormattedPrice
                                    suffixId={"incomeInsurance.incomeAddon.price.suffix"}
                                    value={Math.round(Number(data?.cover?.inputValue!))}
                                />}
                            />
                    }
                </>,
            },
            totalInsuredAmount,
            {
                definition: <>

                    {data?.product === Constants.IncomeInsuranceType.Time
                        ? (<React.Fragment>
                                <FormattedMessage id="general.monthlyPrice.title"/>
                                <DescriptionList.Price
                                    background
                                    component={<FormattedPrice
                                        suffixId={"general.monthlyPremium.suffix"}
                                        value={data?.cover?.monthlyPremium!}
                                    />}
                                />
                            </React.Fragment>

                        )
                        : (
                            <React.Fragment>
                                <FormattedMessage id="general.monthlyPrice.title"/>
                                <DescriptionList.Price
                                    background
                                    component={<FormattedPrice
                                        suffixId={"general.monthlyPremium.suffix"}
                                        value={Math.round(data?.cover?.monthlyPremium!)}
                                    />}
                                />
                            </React.Fragment>


                        )}
                </>,
            }
        ];

        return list.filter(value => value) as IRowDefinition[];

    }, [data]);

    if (isEmpty(data)) {
        return <></>;
    }

    return (
        <FormContext.Provider value={{data} as IFormContext}>
            <ComponentBackgroundWrapper theme={2} noPaddingBottom={true}>
                <SuccessHeaderLayout
                    bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                    journeyId={Constants.Journey.IncomeAddon.Id}
                    logOutUrl={Constants.Journey.IncomeAddon.backUrl}
                    backUrl={Constants.Journey.IncomeAddon.backUrl}
                    navButton={CloseLink}
                    formData={{contact: data?.contact}}
                    translations={{
                        header: CmsHelper.withPrefix(cmsContext, "success.banner.heading"),
                        subheader: CmsHelper.withPrefix(cmsContext, "success.banner.subheading"),
                        headerText: <FormattedMessage id={`${CmsHelper.withPrefix(cmsContext, "pageTitle.headerText")}`} />
                    }}
                    doScroll={true}
                >
                    <SuccessHeaderTable email={data?.contact?.emailAddress} date={data?.startDate}/>
                </SuccessHeaderLayout>
                <MaxWidthWrapper compact={true}>
                    <Step.SectionBody>
                        <Spacing type={"padding"} y={"md"}>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <Spacing type="padding" y="md">
                                            <SummaryCard
                                                theme={Constants.Themes.LighterBlue as number}
                                            >
                                                <SummaryCard.Header>
                                                    <SummaryCard.HeaderIcon iconLeft component={<DateCalendarIcon
                                                        style={{width: 30, paddingRight: '1rem'}}/>}/>
                                                    <SummaryCard.HeaderText>
                                                        <FormattedMessage
                                                            id={data?.product === Constants.IncomeInsuranceType.Time
                                                                ? "incomeInsurance.incomeAddon.time.reviewAndSubmit.summary.title"
                                                                : "incomeInsurance.incomeAddon.salaryCap.reviewAndSubmit.summary.title"}
                                                        />
                                                    </SummaryCard.HeaderText>
                                                </SummaryCard.Header>
                                                <SummaryCard.Body>
                                                    <DescriptionListResponsive breakpoint={12}>
                                                        <SummaryCardRowList list={summaryCardList}/>
                                                    </DescriptionListResponsive>
                                                </SummaryCard.Body>
                                            </SummaryCard>
                                            <Spacing type={"padding"} top={"3"}>
                                                <P style={{
                                                    color: theme.colors.senary1,
                                                    fontFamily: theme.fonts.mono,
                                                    fontSize: theme.textSizes.sm,
                                                    paddingBottom: theme.padding.md
                                                }}>
                                                    <FormattedMarkdown
                                                        messageKey="general.form.priceDisclaimer.helpText"/>
                                                </P>
                                            </Spacing>
                                        </Spacing>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Spacing>
                    </Step.SectionBody>
                </MaxWidthWrapper>
                {/*Footer*/}
                <ComponentBackgroundWrapper theme={1}>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col md={12}>
                                        <ProductContactUsSection
                                            altPhoneNumberMessageKey={"incomeInsurance.incomeAddon.success.contact.phone"}/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>

                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </ComponentBackgroundWrapper>

        </FormContext.Provider>
    )
};

const IncomeAddonJourneySuccess = flowRight(
    withCmsProvider("incomeAddon"),
    withDataAnalytics(getAnalytics(AnalyticsType.Product)),
    withHistoryBackConstraint
)(IncomeAddOnSuccessView);

export default IncomeAddonJourneySuccess;

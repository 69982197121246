import * as React from "react";
import { IDispatchAccordionStatusParams } from "../../../Routers/RouterBase";
import {ScrollTypes} from "./output/helpers/NavigationHelperConstant";
import {IInitParams} from "../../../services";

export interface IUpdateUploadedFiles {
    newFiles: any[];
    uploadedFiles: any[];
}

export interface IFormContext<TData = any> {
    data: TData;
    currentStepIndex: number;
    currentStepName: string;
    disableButton?: boolean;
    proceededToStepIndex: number;
    dataChanged: boolean;
    executeScroll: boolean;
    filesInUploadQueue: number;
    journeyId?: string;
    skipSteps: {[key: string]: boolean};
    setCurrentStepByIndex: (value: number, slug: string) => void;
    triggerDataChanged: () => void;
    getContactBySsn: (ssn: string) => Promise<any>;
    updatePremium: (triggeredBy: any, eventName: string, formData: any) => void;
    onError: () => void;
    onScrollExecuted: () => void;
    enableExecuteScroll: (scrollType: ScrollTypes) => void;
    pushToValidationScrollQueue: (id: number, callback: () => void) => void;
    removeFromValidationScrollQueue: (id: number) => void;
    fieldValidation: (fieldIdSchema: {$id: string}, uiSchema: any, fieldValue: any) => void;
    onStepResize: (size: any) => void;
    setMessageCorrelationId: (id: string) => void;
    addToReset: (onChangeValues?: any) => void;
    addToRemove: (onChangeValues?: any) => void;
    onChangeFormData: (modelPath: string, value?: any) => void;
    getAllFormFields: (fieldIdSchema: string) => any,
    dispatchAccordionStatus: (params: IDispatchAccordionStatusParams) => void;
    updateSessionStorage: (path: string, value: any) => void;
    updateUploadStatus: (queuedFileCount: number) => void;
    filesSizeTooBig: (additionalSize: number, maxFilesTotalSizeInBytes: number) => boolean;
    appendFiles: (files: any[]) => any;
    removeFiles: (files: any[]) => void;
    updateUploadedFiles: (data: IUpdateUploadedFiles) => void;
    restartJourneyWithNewData: (params: IInitParams, url: string) => void;
}

export const FormContext = React.createContext<IFormContext>({
    data: {},
    skipSteps: {},
    currentStepIndex: -1,
    currentStepName: "",
    proceededToStepIndex: -1,
    dataChanged: false,
    executeScroll: true,
    filesInUploadQueue: 0,
    journeyId: undefined,
    triggerDataChanged: () => {},
    setCurrentStepByIndex: (value: number, slug: string) => {},
    getContactBySsn: (ssn: string) => Promise.resolve(""),
    updatePremium: (triggeredBy: any, eventName: string, formData: any) => {},
    onError: () => {},
    onScrollExecuted: () => {},
    enableExecuteScroll : (scrollType: ScrollTypes) => {},
    pushToValidationScrollQueue: (id: number, callback: () => void) => {},
    removeFromValidationScrollQueue: (id: number) => {},
    fieldValidation: (fieldIdSchema: { $id: string }, uiSchema: any, fieldValue: any) => {},
    onStepResize: (size: any) => {},
    setMessageCorrelationId: (id: string) => {},
    addToReset: (onChangeValues?: any) => {},
    addToRemove: (onChangeValues?: any) => {},
    onChangeFormData: (modelPath?: any, value?: any) => {},
    dispatchAccordionStatus: (params: IDispatchAccordionStatusParams) => {},
    getAllFormFields: (fieldIdSchema: string) => {},
    updateSessionStorage: (path: string, value: any) => {},
    updateUploadStatus: (queuedFileCount: number) => {},
    filesSizeTooBig: (additionalSize: number, maxFilesTotalSizeInBytes: number) => true,
    appendFiles: (files: any[]) => {},
    removeFiles: (files: any[]) => {},
    updateUploadedFiles: (data: IUpdateUploadedFiles) => {},
    restartJourneyWithNewData: (params: IInitParams, url: string) => {},
});

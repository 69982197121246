import * as React from "react";
import clone from "lodash/clone";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {FormComponentBase, IFormComponentProps} from "../../../journey/form/FormComponentBase";
import {Contact, PolicySearchResult} from "@folksam-digital/model";
import {P, defaultTheme, Grid, Spacing} from "@folksam-digital/ui";
import {withFormContext} from "../../../journey/form/withFormContext";
import {ProductSelectionCard} from "../input/ProductSelectionCard";
import {ProductCardWidgetTitle} from "../output";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import {injectIntl} from "react-intl";
import {FormContext} from "../../../journey/form/FormContext";
import {IBreakPoint} from "../../../journey/layout/helpers";

interface IProductSelectionWidgetProps extends IFormComponentProps<any, IMetadata> {
    index?: number;
}

export interface IMetadata {
    noOfferMessage: string;
    displayTitle?: boolean;
    offersTitleMessage?: string;
    personDataPath?: string;
    person?: {
        firstName?: string,
        lastName?: string,
    };
    offersSubtitleMessage?: string;
    priceBaseAmountsInfoText?: string;
    breakpoints?: IBreakPoint;
}

interface IProductSelectionWidgetState {}

class ProductSelectionWidget extends FormComponentBase<any, IMetadata, IProductSelectionWidgetState, IProductSelectionWidgetProps> {
    public static contextType = FormContext;

    constructor(props: IFormComponentProps<any, IMetadata>) {
        super(props);

        this.onCardChange = this.onCardChange.bind(this);
    }

    public render() {
        const { displayTitle, offersTitleMessage, offersSubtitleMessage } = this.metadata;
        const person = this.getPerson();

        return !isEmpty(this.props.formData)
            ?
            <>
                {!!(displayTitle && offersTitleMessage) && (<ProductCardWidgetTitle
                    firstName={person?.firstName || ""}
                    lastName={person?.lastName || ""}
                    titleMessage={offersTitleMessage!}
                    subtitleMessage={offersSubtitleMessage}
                />)}
                {this.renderProductList()}
            </>
            :
            this.renderError();
    }

    private getPerson(): Contact {
        let person;
        if (this.metadata.person) {
            person = this.metadata.person;
        } else if (this.metadata.personDataPath) {
            person = get(this.props.formContext!.data, this.metadata.personDataPath);
        }
        return person;
    }

    private renderError() {
        return (<FormattedMarkdown disallowedTypes={[]} messageKey={this.metadata.noOfferMessage}/>);
    }

    private renderProductList(): any {

        const { breakpoints } = this.metadata;

        return (
            <Grid>
                <Grid.Col {...breakpoints}>
                    <Spacing type={"margin"} top={"md"} bottom={"sm"}>
                        {this.props.formData.map((offer: PolicySearchResult, index: number) => {
                            let uniqueIndex = `${offer.productId}-${this.props.name}`;
                            if (this.props.index) {
                                uniqueIndex = `${uniqueIndex}-${this.props.index}`;
                            }

                            return (
                                <div key={offer.productId} style={{paddingBottom: defaultTheme.padding.md}}>
                                    <ProductSelectionCard
                                        offer={offer}
                                        index={index}
                                        childIndex={this.props?.index}
                                        intl={this.props.intl}
                                        selectedCoverPlanId={offer?.selectedCoverPlanId}
                                        key={`${offer.productId}-${this.props.name}-${this.props.index}`}
                                        uniqueIndex={uniqueIndex}
                                        onChange={this.onCardChange}
                                    />
                                </div>
                            )
                        })}
                        {this.metadata.priceBaseAmountsInfoText &&
                        <P style={{color: defaultTheme.colors.senary2}}>
                            <FormattedMarkdown messageKey={CmsHelper.withGroupCommonPrefix("priceBaseAmountsInfoText")} />
                        </P>}
                    </Spacing>
                </Grid.Col>
            </Grid>
        );
    }

    private async onCardChange(offer: PolicySearchResult, index: number, childIndex?: number) {
        const newOfferList = clone(this.props.formData);
        newOfferList[index] = offer;

        let path = this.uiSchema.model;
        if (this.uiSchema.model === "partner") {
            path = `${path}.offers`;
        } else if (childIndex !== undefined) {
            path = `${path}.${childIndex}.offers`;
        }

        const response = await this.context.updatePremium(undefined, undefined, {path, data: newOfferList});

        this.props.onChange(response);
    }
}

export default injectIntl(withFormContext(ProductSelectionWidget));

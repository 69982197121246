import * as React from "react";
import {FormattedMessage} from "react-intl";
import merge from "lodash/merge";
import isEmpty from "lodash/isEmpty";
import {DescriptionListResponsive, P, Spacing, SummaryCard} from "@folksam-digital/ui";
import {GroupInsurance, OfferBase, ShoppingCartContact} from "@folksam-digital/model/lib";
import SelectedProductListTotals, {TotalTypes} from "./SelectedProductListTotals";
import {PersonProductList} from "../output/productList/PersonProductList";
import {CmsContext} from "../../../../cms";
import {CmsHelper} from "../../../../Helpers/cms/CmsHelper";
import FormattedMarkdown from "../../../FormattedMarkdown";
import {PolicySearchResult} from "@folksam-digital/model";

interface IPurchasedProductListProps {
    data: GroupInsurance;
    memberHasSomeSelectedPolicy: boolean;
}

class PurchasedProductList extends React.Component<IPurchasedProductListProps, {}> {
    public static contextType = CmsContext;

    public render() {
        const {data, memberHasSomeSelectedPolicy} = this.props;

        if (!this.displayPurchasedProductsSection(data)) {
            return null;
        }

        const shouldShowHeader = !memberHasSomeSelectedPolicy;

        return (
            <Spacing type={"padding"} y={"md"}>
                <SummaryCard theme={3} headerText={<FormattedMessage
                    id={CmsHelper.withPrefix(this.context, "success.purchased.startDate")}
                    values={{startDate: this.extractStartDate()}}/>}>
                    <SummaryCard.Body>
                        <Spacing bottom={"md"} top={"sm"}>
                            <DescriptionListResponsive.Row>
                                <FormattedMessage
                                    id={CmsHelper.withPrefix(this.context, "success.purchased.startDate.description")}
                                    values={{startDate: this.extractStartDate()}}/>
                            </DescriptionListResponsive.Row>
                        </Spacing>
                        {this.renderPersonPurchasedProducts(data.member, shouldShowHeader)}
                        {this.renderPersonPurchasedProducts(data.partner)}
                        {
                            data.children && data.children.map((childContact: ShoppingCartContact) => (
                                this.renderPersonPurchasedProducts(childContact)
                            ))
                        }
                        <SelectedProductListTotals {...merge(this.state, {type: TotalTypes.Policy}) as any}/>
                        <Spacing top={"3"} bottom={"md"}>
                            <P><FormattedMarkdown
                                messageKey={CmsHelper.withGeneralPrefix("form.priceDisclaimer.helpText")}/></P>
                        </Spacing>
                    </SummaryCard.Body>
                </SummaryCard>
            </Spacing>

        );
    }

    private displayPurchasedProductsSection(data: GroupInsurance): boolean | undefined {
        return this.hasPurchasedPolicies(data.member.offerList) || (data.partner && this.hasPurchasedPolicies(data.partner.offerList))
            || (data.children && data.children.length > 0 && data.children.some((child: ShoppingCartContact) => {
                return this.hasPurchasedPolicies(child.offerList)
            }));
    }

    private extractStartDate(): Date {
        const {data} = this.props;
        let startDate: Date | undefined;
        data.member.offerList!.some((offer: OfferBase) => {
            if (offer.policy && offer.policy.startDate) {
                startDate = new Date(offer.policy.startDate);
                return true;
            }
            return false;
        });

        if (!startDate && data.partner) {
            data.partner.offerList && data.partner.offerList.some((offer: OfferBase) => {
                if (offer.policy && offer.policy.startDate) {
                    startDate = new Date(offer.policy.startDate);
                    return true;
                }
                return false;
            });
        }

        if (!startDate && data.children && data.children.length > 0) {
            data.children.some((child: ShoppingCartContact) => {
                if (child.offerList) {
                    return child.offerList.some((offer: OfferBase) => {
                        if (offer.policy && offer.policy.startDate) {
                            startDate = new Date(offer.policy.startDate);
                            return true;
                        }
                        return false;
                    })
                }
                return false;
            })
        }
        if (!startDate) {
            startDate = new Date();
        }
        return startDate;
    }

    private filterSelectedAndValidOffers(offers: OfferBase[]) {
        return offers.filter((offer: OfferBase) => {
            return offer.selected && offer.policy && isEmpty(offer.policy.errors) && offer.policy.policyNumber;
        });
    }

    private hasPurchasedPolicies(offerList: OfferBase[] | undefined): boolean {
        if (!offerList || isEmpty(offerList)) {
            return false;
        }

        return offerList.some(offer => {
            return offer.policy && isEmpty(offer.policy.errors) && offer.policy.policyNumber;
        });
    }

    private renderPersonPurchasedProducts(person: ShoppingCartContact | undefined, showHeader = false) {
        if (!person || !person.contact?.ssn) {
            return null;
        }

        if (!person.offerList) {
            return null;
        }

        const filteredOfferList = this.filterSelectedAndValidOffers(person.offerList);
        return <PersonProductList filteredOfferList={filteredOfferList as PolicySearchResult[]} contact={person.contact}
                                  showHeader={showHeader}/>;
    }
}

export default PurchasedProductList;

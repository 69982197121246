import * as React from "react";
import {Button, ContentHeader as ContentHeaderFUI, IconEditV2, Modal} from "@folksam-digital/ui";
import {FormContext} from "../../form/FormContext";
import memoize from "lodash/memoize";
import {ContentHeaderFieldDelegator} from "./ContentHeaderFieldDelegator";
import {FormattedMessage} from "react-intl";

interface IContentHeaderProps {
    content: { [key: string]: string };
}

interface IContentHeaderState {
    isOpen: boolean;
}

const modalDefaults = {
    role: 'dialog',
    shouldCloseOnEsc: true,
    shouldFocusAfterRender: true,
    shouldReturnFocusAfterClose: true,
    shouldCloseOnOverlayClick: true,
    htmlOpenClassName: 'ReactModal__Html--open'
};

export class ContentHeader extends React.PureComponent<IContentHeaderProps, IContentHeaderState> {
    public static contextType = FormContext;

    public constructor(props: IContentHeaderProps) {
        super(props);

        this.closeModal = this.closeModal.bind(this);
        this.state = {
            isOpen: false
        };
    }

    public render() {
        const {content} = this.props;
        const {journeyId} = this.context;

        const ContentHeaderFieldComponent = ContentHeaderFieldDelegator.getJourneyContentHeaderFieldsComponent(journeyId);

        return (
            <ContentHeaderFUI compact={true}>
                {ContentHeaderFieldComponent ?
                    <>
                        <ContentHeaderFUI.Content> {this.renderContent(content)} </ContentHeaderFUI.Content>
                        <ContentHeaderFUI.Icon
                            component={<>
                                <Button editButton
                                    fontSize={"small"}
                                    style={{boxShadow: `0px 0px 0px 0px`}}
                                    onClick={() => this.setState({isOpen: !this.state.isOpen})}
                                >
                                    <IconEditV2 style={{paddingRight: "4px", width: 18, height: 18}} />
                                    <FormattedMessage id={"general.accordionPanel.button.edit"} />
                                </Button>
                                <Modal {...modalDefaults} onRequestClose={() => this.setState({isOpen: false})}
                                       isOpen={this.state.isOpen}>
                                    <Modal.Header onModalClose={() => this.setState({isOpen: false})}/>
                                    <Modal.Body>
                                        <ContentHeaderFieldComponent closeModal={this.closeModal}/>
                                    </Modal.Body>
                                </Modal>
                            </>}
                        />
                    </>
                    :
                    <ContentHeaderFUI.Content> {content && Object.values(content).join(", ")} </ContentHeaderFUI.Content>}
            </ContentHeaderFUI>
        );
    }

    private closeModal(): void {
        this.setState({isOpen: false});
    }

    private renderContent = memoize((content: { [key: string]: string }): string | undefined => {
        const {mark, regNr} = content;
        const {address, city, postalCode} = content;
        // for vehicles
        if (regNr) {
            return `${mark} - ${regNr}`;
        }

        return [address, city, postalCode].filter(value => !!value).join(", ");
    });
}

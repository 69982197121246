import * as React from "react";
import {IntlProvider} from "react-intl";
import {apm} from "@elastic/apm-rum";
import {GeneralError, ProgressBarInfinite} from "@folksam-digital/ui";
import {CmsCatalog, ICmsClient, UrlHelper} from "@folksam-digital/services";
import {CmsContext} from "./CmsContext";
import {CmsCatalogDetails} from "./CmsCatalogs";
import {container} from "../inversify.config";
import {Types} from "../Types";
import {RouteComponentProps, withRouter} from "react-router";
import isObject from "lodash/isObject";

interface IProps {
    locale: string,
    catalog: CmsCatalogDetails
}

interface IState {
    catalog?: CmsCatalog,
    serviceError: boolean
}

class LocaleUnawareCmsProviderInternal extends React.Component<IProps & RouteComponentProps<any>, IState> {
    private cmsClient: ICmsClient;

    constructor(props: Readonly<IProps & RouteComponentProps<any>>) {
        super(props);

        this.cmsClient = container.get<ICmsClient>(Types.CmsClient);

        this.state = {
            catalog: undefined,
            serviceError: false
        }
    };

    public async componentDidMount() {
        return this.loadCatalog();
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
        if (prevProps.locale !== this.props.locale) {
            this.loadCatalog();
        }
    }

    /**
     * Load catalog
     */
    private async loadCatalog() {
        this.setState({
            catalog: undefined
        }, async () => {

            try {
                const catalog = await this.cmsClient.getCatalog(this.props.catalog.catalogName, this.props.catalog.folderName, this.props.locale);

                if (isObject(catalog) && catalog?.images && catalog?.messages) {
                    this.setState({
                        catalog
                    });
                } else {
                    this.props.history.push(UrlHelper.getUrl(["journey", "error"]));
                }
            } catch (err) {
                this.setState({
                    catalog: undefined,
                    serviceError: true
                });
            }

        });
    }

    private onError(error: any) {
        console.log(error);
        apm.captureError(error);
    }

    /**
     * Render context provider with data from CMS
     */
    public render(): any {
        if (this.state.catalog) {
            return (
                <CmsContext.Provider value={{
                    catalogName: this.props.catalog.catalogName,
                    folderName: this.props.catalog.folderName,
                    catalog: this.state.catalog
                }}>
                    <IntlProvider locale={this.props.locale}
                                  messages={this.state.catalog.messages}
                                  onError={this.onError}
                    >
                        {this.props.children}
                    </IntlProvider>
                </CmsContext.Provider>
            );
        } else {
            if (!this.state.serviceError) {
                return <ProgressBarInfinite duration={20}/>;
            } else {
                return <GeneralError/>;
            }
        }
    }
}

const LocaleUnawareCmsProvider = withRouter<IProps & RouteComponentProps<any>, React.ComponentType<IProps & RouteComponentProps<any>>>(LocaleUnawareCmsProviderInternal);

export {LocaleUnawareCmsProvider};

import * as React from "react";
import {Constants} from "@folksam-digital/model";
import ClaimSuccessBase, {IClaimSuccessBaseProps} from "./ClaimSuccessBase";
import {DelayedTravelerJourney} from "@folksam-digital/model/lib/journey/claim/DelayedTravelerJourney";
import {IOnTriggerDataLayerEvent} from "../../../../analytics/new/BaseAnalytics";
import {CmsContext, ICmsContext} from "../../../../cms";
import isEmpty from "lodash/isEmpty";
import flowRight from "lodash/flowRight";
import withCmsProvider from "../../../../components/general/withCmsProvider";
import withDataAnalytics from "../../../../components/general/withDataAnalytics";
import withHistoryBackConstraint from "../../../../components/general/withHistoryBackConstraint";
import {withRouter} from "react-router";
import {Grid, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import {FormContext, IFormContext} from "../../../../components/journey/form/FormContext";
import {CloseModalButton} from "../../../../components/journey/navigation/loggedIn/CloseModalButton";
import {ComponentBackgroundWrapper} from "../../../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../../../components/journey/layout/Success/SuccessHeaderLayout";
import {SuccessHeaderMessageBox} from "../../../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {SuccessHeaderTable} from "../../../../components/journey/layout/Success/SuccessHeaderTable";
import {FormattedMessage} from "react-intl";
import {getAnalytics, AnalyticsType} from "../../../../analytics/AnalyticsSwitch";

interface DelayedTravelerClaimSuccessProps extends IClaimSuccessBaseProps, IOnTriggerDataLayerEvent {

}

class DelayedTravelerClaimSuccessInternal extends ClaimSuccessBase<DelayedTravelerJourney, DelayedTravelerClaimSuccessProps> {
    public static contextType = CmsContext;
    protected prefix: string = "claim.delayedTraveler";

    constructor(props: DelayedTravelerClaimSuccessProps, context?: ICmsContext) {
        super(props, Constants.Journey.DelayedTraveler.Id, context);
    }

    public componentDidMount(): void {
        this.checkData(Constants.Journey.DelayedTraveler.Id);
        this.triggerDataLayerEvent(Constants.Journey.DelayedTraveler.Id);
    }

    public render() {
        const data = this.data;

        if (isEmpty(data)) {
            return <></>;
        }

        return (
            <FormContext.Provider value={{data: data} as IFormContext}>
                <React.Fragment>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Claim}
                        journeyId={Constants.Journey.DelayedTraveler.Id}
                        formData={{contact: data.informant}}
                        logOutUrl={Constants.Journey.DelayedTraveler.backUrl}
                        navButton={CloseModalButton}
                        translations={{
                            header: "general.success.banner.heading",
                            subheader: super.getBannerSubheaderText(`${this.prefix}.success.banner.subheading`),
                            headerText: <FormattedMessage id={`${this.prefix}.pageTitle.headerText`} />
                        }}
                        doScroll={true}
                    >
                        <SuccessHeaderMessageBox message={super.getSuccessMessageText(`${this.prefix}.success.client.claim.information`)}/>
                        <SuccessHeaderTable
                            claimNumber={!this.isInterimEmailSolutionEnabled ? data.claimNumber : undefined}
                            email={this.getContact()?.emailAddress}/>
                        <Spacing type={"padding"} bottom={'4'}/>
                        {this.renderMyPagesButton()}
                    </SuccessHeaderLayout>
                    {/*Footer*/}
                    <ComponentBackgroundWrapper>
                        <MaxWidthWrapper compact={true}>
                            <Step.Footer>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Col md={12}>
                                            {this.renderContactUsSection()}
                                        </Grid.Col>
                                    </Grid.Row>
                                </Grid>
                            </Step.Footer>
                        </MaxWidthWrapper>
                    </ComponentBackgroundWrapper>
                </React.Fragment>
            </FormContext.Provider>
        );
    }
}

const DelayedTravelerClaimSuccess = flowRight(
    withCmsProvider("delayedTraveler"),
    withDataAnalytics(getAnalytics(AnalyticsType.Claim)),
    withRouter,
    withHistoryBackConstraint
)(DelayedTravelerClaimSuccessInternal);

export default DelayedTravelerClaimSuccess;

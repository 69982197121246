import * as React from "react";
import {Grid, defaultTheme} from "@folksam-digital/ui";
import {PanelComponentBase} from "./PanelComponentBase";
import {IBreakPoint} from "../../layout/helpers";

interface IMetadata {
    breakpoints?: IBreakPoint;
    classNames?: string;
    childrenMaxWidth?: number;
    positionItemsInRow?: boolean;
    rowGap?: string;
}

export class MultipleInputGrid extends PanelComponentBase<string, IMetadata, {}> {
    public render() {
        const {
            breakpoints,
            classNames,
            childrenMaxWidth,
            positionItemsInRow,
            rowGap
        } = this.metadata;

        const paddings = positionItemsInRow && {
            paddingLeft: defaultTheme.padding['1'],
            paddingRight: defaultTheme.padding['1']
        }

        const columnStyle = childrenMaxWidth && {
            maxWidth: childrenMaxWidth
        };

        const rowStyle = rowGap && {
            rowGap: defaultTheme.margin[rowGap]
        }

        return (
            <div className={classNames}>
                 <Grid>
                    <Grid.Row style={{...rowStyle}}>
                        {this.props.properties?.map((p, key) =>
                            <Grid.Col key={key} style={{...columnStyle, ...paddings}} {...breakpoints}>
                                {p.content}
                            </Grid.Col>)
                        }
                    </Grid.Row>
                 </Grid>
            </div>
        );
    }
}

import * as React from "react";
import {PanelComponentBase} from "./PanelComponentBase";
import {
    defaultTheme,
    DescriptionListResponsive,
    IconHomeV2,
    Spacing,
    SummaryCard,
    withViewContext
} from "@folksam-digital/ui";
import {FormattedMessage} from "react-intl";
import {RemoveButton} from "../input/actionInput/RemoveButton";
import {FormContext} from "../FormContext";
import {Template} from "../output/helpers";

interface IAdditionalBuildingPanelMetaData {
    showRemoveButton?: boolean;
    pathFilter: string;
    index: number;
    spacingBottom?: string;
    spacingTop?: string;
    titleAsTemplate?: boolean;
}

class AdditionalBuildingPanelInternal extends PanelComponentBase<any, IAdditionalBuildingPanelMetaData, {}> {

    public static contextType = FormContext;

    public render() {
        const title = this.schema.title;
        const {
            spacingTop,
            spacingBottom,
            showRemoveButton,
            titleAsTemplate
        } = this.metadata;
        const headerValues: { [key: string]: string } = {};

        const titleResult = titleAsTemplate ? <Template template={title} data={this.context.data}/> : <FormattedMessage id={title} values={headerValues}/>;

        return (
            <Spacing type={"margin"} top={spacingTop} bottom={spacingBottom}>
                <DescriptionListResponsive>
                    <SummaryCard theme={2}>
                        {title !== undefined && title !== "" &&
                            <SummaryCard.Header noPaddingSide={false}>
                                <SummaryCard.HeaderIcon large component={<IconHomeV2 style={{
                                    color: defaultTheme.colors.primary1,
                                    paddingRight: defaultTheme.padding[3]
                                }}/>}/>
                                <SummaryCard.HeaderText>{titleResult} </SummaryCard.HeaderText>
                                {showRemoveButton && <RemoveButton id={this.props.name + "_Btn"}
                                                                   onChange={this.removeBuilding}/>}
                            </SummaryCard.Header>}
                        <SummaryCard.Body>
                            <Spacing type={"padding"} top={"4"} bottom={"4"}>
                                {this.props.properties?.map(p => p.content)}
                            </Spacing>
                        </SummaryCard.Body>
                    </SummaryCard>
                </DescriptionListResponsive>
            </Spacing>
        );
    }

    private removeBuilding = () => {
        this.context.addToRemove({pathFilter: this.metadata.pathFilter, index: this.metadata.index});
    };
}

export const AdditionalBuildingPanel = withViewContext(AdditionalBuildingPanelInternal);

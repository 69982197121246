import React, {RefObject} from "react";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import get from "lodash/get";
import ImageMapper, {IArea, IAreaMapping} from 'react-image-mapper';
import Icons from "../../../icons";
import {defaultTheme, withViewContext} from "@folksam-digital/ui";
import {IFormComponentProps} from "../FormComponentBase";
import {IToothStatus} from "@folksam-digital/model/lib";
import {IInputComponentProps, InputComponentBase} from "./InputComponentBase";
import {FormFieldLayout} from "../../../FormFieldLayout";
import {renderToString} from "react-dom/server";
import {scroller} from "react-scroll/modules";
import {Modal} from "../controls/remove/Modal";
import {FormContext, IFormContext} from "../FormContext";
import {Actions} from "../panel/AccordionPanelConstants";

interface ITeethSelectionComponentState {
    teethLayoutMap: IAreaMapping;
    originalImgViewBox: ISvgViewBox;
    toothToRemove?: any;
    modalOpen?: boolean;
}

interface ISvgViewBox {
    minX: number;
    minY: number;
    width: number;
    height: number;
}

export interface ITeethSelectionComponentMetadata {
    cardBaseName: string;
    modelPath: string;
    modalHeaderMessage?: string;
}

class TeethSelectionComponentInternal extends InputComponentBase<IToothStatus[], ITeethSelectionComponentMetadata, ITeethSelectionComponentState> {
    ref: RefObject<any>;
    public static contextType = FormContext;
    // Number of vertices for each tooth polygon
    private readonly NUM_POINTS: number = 50;
    private parser: DOMParser;
    private readonly path: string;

    constructor(props: IInputComponentProps<IToothStatus[], ITeethSelectionComponentMetadata>, context: IFormContext) {
        super(props, context);
        this.parser = new DOMParser();
        this.path = `expanded_${this.uiSchema.name}`;

        const viewBox: ISvgViewBox = this.extractImgViewBox();
        this.state = {
            originalImgViewBox: viewBox,
            teethLayoutMap: this.extractLayoutMap(viewBox),
            modalOpen: false,
        };

        this.ref = React.createRef();
    }

    public componentDidMount(): void {
        scroller.register(this.props.name, this.ref.current);
        // Trigger initial dispatch if there are no teeth added yet to block user
        this.context.dispatchAccordionStatus(this.path, Actions.Open);
    }

    public componentWillUnmount(): void {
        scroller.unregister(this.props.name);
    }

    public render() {
        const { viewContext } = this.props;
        const { modalHeaderMessage } = this.metadata;

        return (
            <div ref={this.ref} id={this.props.name}>
                <FormFieldLayout {...this.getLayoutProps()}>
                    <div className={"imgMapContainer"}>
                        <ImageMapper map={this.state.teethLayoutMap}
                                     onClick={area => this.onToothClick(area)}
                                     fillColor={defaultTheme.colors.tertiary3}
                                     src={viewContext.isMobile ? Icons.TeethLayoutMobileUrl : Icons.TeethLayoutDesktopUrl}
                                     width={Number(defaultTheme.width.contentWrapperCompact.replace("px", ""))}
                                     imgWidth={this.state.originalImgViewBox.width}/>
                    </div>
                    <Modal onClose={this.onClose} open={this.state.modalOpen} onSubmit={this.onRemoveSubmit} headerMessageId={modalHeaderMessage} />
                </FormFieldLayout>
            </div>);
    }

    public onClose = () => {
        this.setState({modalOpen: false});
    };

    public onRemoveSubmit = () => {
        let dataToUpdate = {} as any;
        const toothId = this.state.toothToRemove.id;
        const formData = this.props.formData || [];

        if (!isEmpty(formData)) {
            const toothIndex = formData.findIndex(tooth => tooth.id === toothId);
            dataToUpdate = this.updateDamagedArea(toothIndex);

            this.setState({
                modalOpen: false,
                teethLayoutMap: {
                    name: this.state.teethLayoutMap.name,
                    areas: dataToUpdate.areas
                }
            });
            this.context.dispatchAccordionStatus(`expanded_${this.metadata.cardBaseName}_${toothIndex}`, Actions.DeletePath);
            this.props.onChange(dataToUpdate.newData);

            return;
        }

        this.setState({
            modalOpen: false
        });
    };

    public componentDidUpdate(prevProps: Readonly<IFormComponentProps<IToothStatus[], {}>>, prevState: Readonly<ITeethSelectionComponentState>): void {
        const { viewContext } = this.props;
        if (prevProps.viewContext.isMobile !== viewContext.isMobile) {
            const viewBox: ISvgViewBox = this.extractImgViewBox();
            this.setState({
                teethLayoutMap: this.extractLayoutMap(viewBox),
                originalImgViewBox: viewBox,
            });
        }
    }

    private appendTeethArray(data: IToothStatus[], id: string) {
        data.push({
            id,
            isDamaged: true,
        });
    }

    setDamagedArea(areas: IArea[], name: string) {
        areas.find((toothArea: IArea) => toothArea.name === name)!.preFillColor = defaultTheme.colors.tertiary2;
    }

    private onToothClick(area: IArea): void {
        const { name } = this.state.teethLayoutMap;
        const newData: IToothStatus[] = this.props.formData || [];
        let dataToUpdate;

        const toothIndex = newData.findIndex((toothStatus: IToothStatus) => {
            return area.name === toothStatus.id;
        });

        if (toothIndex === -1) {
            dataToUpdate = this.addDamagedArea(area);
        } else if (newData[toothIndex].isDamaged && this.isToothInfoChanged(toothIndex)) {
            this.setState({toothToRemove: newData[toothIndex], modalOpen: true});

            return;
        } else {
            dataToUpdate = this.updateDamagedArea(toothIndex);
            if (!dataToUpdate.newData[toothIndex].isDamaged) {
                this.context.dispatchAccordionStatus(`expanded_${this.metadata.cardBaseName}_${toothIndex}`, Actions.DeletePath);
            }
        }

        this.onChangeWithValidation(dataToUpdate.newData);

        this.setState({
            teethLayoutMap: {
                name,
                areas: dataToUpdate.areas
            }
        });
    }

    private isToothInfoChanged(toothIndex: number) {
        const path = this.metadata.modelPath;
        let data = [] as any;
        let filteredTempTooth;

        if (path) {
            data = this.getNewestFormData();
        }

        if (!isEmpty(data) && data.hasOwnProperty(toothIndex)) {
            const tempTooth = data[toothIndex];
            delete tempTooth.id;
            delete tempTooth.isDamaged;

            filteredTempTooth = filter(tempTooth, (value) => {
                return !isNil(value) && value !== 0;
            });
        }

        return !isEmpty(filteredTempTooth);
    }

    private getNewestFormData(): IToothStatus[] {
        return get(this.context.data, this.metadata.modelPath, []);
    }

    private addDamagedArea(area: IArea) {
        const newData: IToothStatus[] = this.props.formData || [];
        const { areas } = this.state.teethLayoutMap;

        this.appendTeethArray(newData, area.name);
        this.setDamagedArea(areas, area.name);

        return {newData, areas};
    }

    private updateDamagedArea(index: number) {
        const newData: IToothStatus[] = this.props.formData || [];
        const { areas } = this.state.teethLayoutMap;

        const toothArea: IArea = areas.find((tArea: IArea) => tArea.name === newData[index].id)!;
        newData[index].isDamaged = !newData[index].isDamaged;
        toothArea.preFillColor = newData[index].isDamaged ? defaultTheme.colors.tertiary2 : defaultTheme.colors.transparent;

        return { newData, areas };
    }

    private extractLayoutMap(viewBox: ISvgViewBox): IAreaMapping {
        const { formData = [] } = this.props;
        const svgElement: SVGElement = this.getSvgElement();

        const teethLayoutMap: IAreaMapping = {
            name: svgElement.id,
            areas: []
        };

        const pathElements: SVGPathElement[] = Array.from(svgElement.children) as SVGPathElement[];
        pathElements.filter((pathEl: SVGPathElement) => pathEl.getAttribute("class") === "st1")
            .forEach((pathEl:SVGPathElement) => {
                const coords: number[] = [];
                for (let i: number = 0; i < this.NUM_POINTS; i++) {
                    const pointCoordinates: SVGPoint = pathEl.getPointAtLength(i * pathEl.getTotalLength() / (this.NUM_POINTS - 1));
                    coords.push(pointCoordinates.x - viewBox.minX, pointCoordinates.y - viewBox.minY)
                }

                const prefillPolygon: boolean = formData.some((toothStatus: IToothStatus) => toothStatus.id === pathEl.id && toothStatus.isDamaged);

                teethLayoutMap.areas.push({
                    name: pathEl.id,
                    shape: "poly",
                    coords: coords,
                    preFillColor: prefillPolygon ? defaultTheme.colors.tertiary2 : defaultTheme.colors.transparent,
                })
            });

        return teethLayoutMap;
    }

    private extractImgViewBox(): ISvgViewBox {
        const svgElement: SVGElement = this.getSvgElement();
        const svgViewBox: number[] = svgElement.getAttribute("viewBox")!.split(" ").map(x=>+x);

        return {
            minX: svgViewBox[0],
            minY: svgViewBox[1],
            width: svgViewBox[2],
            height: svgViewBox[3]
        } as ISvgViewBox;
    }

    private getSvgElement(): SVGElement {
        const { viewContext } = this.props;
        const svgString: string = viewContext.isMobile ? renderToString(<Icons.TeethLayoutMobile/>) : renderToString(<Icons.TeethLayoutDesktop/>);
        return this.parser.parseFromString(svgString, "image/svg+xml").firstElementChild! as SVGElement;
    }
}

const TeethSelectionComponent = withViewContext(TeethSelectionComponentInternal);
export { TeethSelectionComponent };

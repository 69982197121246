import * as React from "react";
import {FormattedMessage} from "react-intl";
import {useCallback, useMemo} from "react";
import {NavigationModalButton} from "../common/modalButton";
import {logoHref} from "../common/params";
import {clearSessionStorage} from "../common/clearSessionStorage";
import {UrlHelper} from "@folksam-digital/services";

interface IProps {
    backUrl?: string;
    isAuthenticated?: boolean;
    logOutUrl?: string;
    returnUrl?: string;
    stepBack?: boolean;
}

const CancelButtonInternal = (
    {
        backUrl,
        returnUrl,
        isAuthenticated,
        ...props
    }: IProps) => {
    const onReturn = useCallback(() => {
        let url = returnUrl || backUrl || logoHref;
        if (isAuthenticated) {
            url = UrlHelper.getLogoutUrl(url);
        }
        clearSessionStorage();
        window.open(url, "_self");
    }, [isAuthenticated, returnUrl, backUrl]);

    const messages = useMemo(() => ({
        modalHeaderMessage: isAuthenticated ? "general.loggedUser.cancel.confirmation.heading" : "general.cancel.confirmation.heading",
        modalContentMessage: isAuthenticated ? "general.loggedUser.cancel.confirmation.body" : "general.cancel.confirmation.body"
    }), [isAuthenticated]);

    return (
        <NavigationModalButton
            onSubmit={onReturn}
            linkMessageId="general.navigate.cancel"
            modalHeaderMessage={
                <FormattedMessage
                    id={messages.modalHeaderMessage}
                />
            }
            modalContentMessage={
                <FormattedMessage
                    id={messages.modalContentMessage}
                />
            }
            {...props}
        />
    );
};

export const CancelModalButton = React.memo(CancelButtonInternal);

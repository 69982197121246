import * as React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import flowRight from "lodash/flowRight";
import {Constants, DirectDebit} from "@folksam-digital/model";
import {CmsContext, ICmsContext} from "../../cms";
import {Grid, MaxWidthWrapper, Spacing, Step} from "@folksam-digital/ui";
import {IOnTriggerDataLayerEvent, SatelliteEvents, SatelliteEventTransactionTypes} from "../../analytics/new/BaseAnalytics";
import withDataAnalytics from "../../components/general/withDataAnalytics";
import withCmsProvider from "../../components/general/withCmsProvider";
import withHistoryBackConstraint from "../../components/general/withHistoryBackConstraint";
import {IDraftService, StorageKeys} from "../../services";
import {container} from "../../inversify.config";
import {Types} from "../../Types";
import {CmsHelper} from "../../Helpers/cms/CmsHelper";
import {MyPagesButton, ProductContactUsSection} from "../../components/journey/layout/Success";
import {ComponentBackgroundWrapper} from "../../components/ComponentBackgroundWrapper";
import {SuccessHeaderLayout} from "../../components/journey/layout/Success/SuccessHeaderLayout";
import {UrlHelper} from "@folksam-digital/services";
import {SuccessHeaderTable} from "../../components/journey/layout/Success/SuccessHeaderTable";
import {SuccessHeaderMessageBox} from "../../components/journey/layout/Success/SuccessHeaderMessageBox";
import {CloseModalButton} from "../../components/journey/navigation/loggedIn/CloseModalButton";
import {FormattedMessage} from "react-intl";
import {getAnalytics, AnalyticsType} from "../../analytics/AnalyticsSwitch";

interface IDirectDebitSuccessProps extends RouteComponentProps<any, any>, IOnTriggerDataLayerEvent {
}

class SuccessPageInternal extends React.Component<IDirectDebitSuccessProps> {
    public static contextType = CmsContext;
    private draftService: IDraftService;

    constructor(props: IDirectDebitSuccessProps, context: ICmsContext) {
        super(props, context);

        this.draftService = container.get<IDraftService>(Types.DraftService);

        this.handleRedirect = this.handleRedirect.bind(this);
        this.redirectToErrorPage = this.redirectToErrorPage.bind(this);
    }

    private get data(): DirectDebit {
        return this.draftService.getDraft<DirectDebit>(StorageKeys.DIRECT_DEBIT_FORM_DATA_KEY);
    }

    private handleRedirect(url: string) {
        window.open(url, "_blank");
    }

    public async componentDidMount() {
        if (!this.data) {
            this.redirectToErrorPage();
            return;
        }

        // Trigger Analytics Service Complete
        this.props.onTriggerDataLayerEvent!({
            messages: this.context.catalog.messages,
            journeyId: "directDebit",
            currentStep: "success",
            transactionType: SatelliteEventTransactionTypes.Service,
            event: SatelliteEvents.Complete
        });
    }

    private redirectToErrorPage() {
        const {history} = this.props;
        history.push(UrlHelper.getUrl(["direct-debit", "error"]));
    }

    public render(): React.ReactNode {
        if (!this.data) {
            this.redirectToErrorPage();
            return;
        }

        const data: DirectDebit = this.data;

        return (
            <>
                <div>
                    <SuccessHeaderLayout
                        bannerIconTheme={Constants.SuccessPageHeaderProperties.ImageTheme.Buy}
                        journeyId={"directDebit"}
                        navButton={CloseModalButton}
                        formData={{
                            contact: {
                                firstName: data.firstName
                            }
                        }}
                        translations={{
                            header: `general.success.banner.heading`,
                            subheader: CmsHelper.withPrefix(this.context, "success.banner.subheading"),
                            headerText: <FormattedMessage id={`${CmsHelper.withPrefix(this.context, "pageTitle.headerText")}`} />
                        }}
                        doScroll={true}
                    >
                        <SuccessHeaderMessageBox message={CmsHelper.withPrefix(this.context, "success.description.explanation")} />
                        <SuccessHeaderTable
                            clearingNumber={data.clearingNumber}
                            bankAccountNumber={data.accountNumber}
                            email={data.emailAddress}
                        />
                        <Spacing type={"padding"} bottom={'4'}/>
                        <MyPagesButton/>
                    </SuccessHeaderLayout>
                </div>
                {/*Footer*/}
                <ComponentBackgroundWrapper>
                    <MaxWidthWrapper compact={true}>
                        <Step.Footer>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Col xl>
                                        <ProductContactUsSection/>
                                    </Grid.Col>
                                </Grid.Row>
                            </Grid>
                        </Step.Footer>
                    </MaxWidthWrapper>
                </ComponentBackgroundWrapper>
            </>
        )
    }
}

const SuccessPage = flowRight(
    withCmsProvider("directDebit"),
    withDataAnalytics(getAnalytics(AnalyticsType.DirectDebit)),
    withRouter,
    withHistoryBackConstraint
)(SuccessPageInternal);

export default SuccessPage;

import {Constants} from "@folksam-digital/model";
import {Button, MaxWidthWrapper, P, Spacing, Step,} from "@folksam-digital/ui";
import * as React from "react";
import {FormattedMessage} from "react-intl";
import {RouteComponentProps, withRouter} from "react-router";
import {CmsCatalogs, CmsProvider} from "../../../cms";
import {HeaderLayout} from "../../../components";
import {container} from "../../../inversify.config";
import {IStorage} from "../../../services";
import {Types} from "../../../Types";
import {CancelModalButton} from "../../../components/journey/navigation/loggedIn/CancelModalButton";
import {CmsHelper} from "../../../Helpers/cms/CmsHelper";
import {UrlHelper} from "@folksam-digital/services";

interface IPregnancyJourneyMaleSSNProps extends RouteComponentProps<any> {
}

interface IPregnancyJourneyMaleSSNState {
    data: any;
}

class PregnancyJourneyMaleSSN extends React.Component<IPregnancyJourneyMaleSSNProps, IPregnancyJourneyMaleSSNState> {
    private sessionStorage: IStorage;

    constructor(props: Readonly<IPregnancyJourneyMaleSSNProps>) {
        super(props);

        this.sessionStorage = container.get<IStorage>(Types.SessionStorage);
    }

    private onClickContinue = () => {
        const {history} = this.props;
        this.sessionStorage.set("maleSsnApproved", true);

        history.push(UrlHelper.getPurchaseJourneyUrl(Constants.ObfuscatedJourneyId.Pregnancy, Constants.Journey.Pregnancy.Steps.sizeOfInsurance));
    };

    public componentDidMount() {
        const locationState: any = this.props.history.location.state;
        this.setState({data: locationState?.data});
    }

    private onClickBack = () => {
        window.open(Constants.Links.pregnancyProductPage, "_self");
    };

    public render(): React.ReactNode {
        return (
            <CmsProvider catalog={CmsCatalogs.pregnancy}>
                <React.Fragment>
                    <HeaderLayout
                        bannerImageName={CmsHelper.withPrefix(CmsCatalogs.pregnancy, "bannerImage")}
                        journeyId={"pregnancy"}
                        formData={this.state?.data}
                        backUrl={Constants.Journey.Pregnancy.backUrl}
                        navButton={CancelModalButton}
                        translations={{
                            header: "product.pregnancy.maleSsn.banner.heading",
                            subheader: "product.pregnancy.maleSsn.banner.subheading"
                        }}
                    />
                    <MaxWidthWrapper>
                        <Step.SectionBody>
                            <Spacing type="padding" y={"8"}>
                                <P><FormattedMessage id="product.pregnancy.maleSsn.description" /></P>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    <Spacing type={"margin"} right={3} bottom={3}>
                                        <Button onClick={this.onClickContinue} primary={true}>
                                            <FormattedMessage id="product.pregnancy.maleSsn.button.continue" />
                                        </Button>
                                    </Spacing>
                                    <Spacing>
                                        <Button onClick={this.onClickBack}>
                                            <FormattedMessage id="product.pregnancy.maleSsn.button.back" />
                                        </Button>
                                    </Spacing>
                                </div>
                            </Spacing>
                        </Step.SectionBody>
                    </MaxWidthWrapper>
                </React.Fragment>
            </CmsProvider>
        );
    }
}

export default withRouter(PregnancyJourneyMaleSSN);
